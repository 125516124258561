<template>
  <div class="Login">
    <div class="login-box">
      <div class="signin_box">
        <h2 class="main_title">
          林地資訊管理
          <!-- <div class="sub_title">
            <span class="bg-info sub_title_text">使用者介面</span>
          </div> -->
        </h2>
        <b-form-group label="帳號">
          <b-form-input
            placeholder="帳號"
            @keyup.enter="
              loginData.Type = 'login';
              userLogin();
            "
            v-model="loginData.Id"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="密碼">
          <b-form-input
            placeholder="請輸入密碼"
            @keyup.enter="
              loginData.Type = 'login';
              userLogin();
            "
            v-model="loginData.DecryptionPass"
            type="password"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="驗證碼">
          <b-row>
            <b-col md="6">
              <b-form-input
                placeholder="請輸入驗證碼"
                @keyup.enter="
                  loginData.Type = 'login';
                  userLogin();
                "
                v-model="loginData.CodePass"
              ></b-form-input
            ></b-col>
            <b-col md="6"
              ><img :src="imageCode" class="img-fluid" alt="captchaImage"
            /></b-col>
          </b-row>
        </b-form-group>
        <p>
          <b-link v-b-modal.ForgetPasswordModal>忘記密碼</b-link>
        </p>
        <button
          @click="
            loginData.Type = 'login';
            userLogin();
          "
          class="btn btn-info btn-block"
        >
          登入
        </button>
        <div class="social-media-icon">
          <b-row class="bv-example-row">
            <b-col sm="12" md="6" lg="6">
              <!-- <a :href="lineLoginUrl" class="line-login">
                <b-img :src="require('@/assets/imgs/btn_login.png')"></b-img
                ><span class="line-login-title">登入</span>
              </a> -->
              <b-button
                class="btn btn-success btn-block"
                style="
                  width: 100%;
                  background-color: #06c755;
                  height: 40px;
                  border: none;
                "
                @click="
                  loginData.Type = 'Line';
                  userLogin();
                "
              >
                <b-img
                  :src="require('@/assets/imgs/btn_login.png')"
                  style="
                    position: absolute;
                    left: 25px;
                    top: 5px;
                    width: 30px;
                    height: 30px;
                  "
                ></b-img
                ><span class="line-login-title">使用Line帳戶登入</span>
              </b-button>
            </b-col>
            <b-col sm="12" md="6" lg="6">
              <b-button
                class="btn btn-light btn-block"
                style=""
                @click="
                  loginData.Type = 'Google';
                  userLogin();
                "
              >
                <b-img
                  :src="require('@/assets/imgs/google_logo.png')"
                  style="
                    position: absolute;
                    left: 25px;
                    top: 5px;
                    width: 25px;
                    height: 25px;
                  "
                />
                <span>使用Google帳戶登入</span>
              </b-button>
            </b-col>
            <b-col sm="12" md="6" lg="6" v-show="false"
              ><GoogleLogin
                id="GoogleLoginButton"
                :params="googleParams"
                :renderParams="googleLoginCss"
                :onSuccess="googleLoginSuccess"
              ></GoogleLogin
            ></b-col>
          </b-row>
        </div>
      </div>
    </div>

    <div class="logo-box">
      <div class="main-logo">
        <b-img
          :src="require('@/assets/imgs/FANCA_MOA_(Taiwan)_logo.svg.png')"
          fluid
          alt="林務局logo"
        ></b-img>
        <h2>林業及自然保育署臺東分署</h2>
      </div>
    </div>

    <AlertModal :alertModalObj="alertModalObj" />
    <ForgetPasswordModal></ForgetPasswordModal>
  </div>
</template>

<script>
import GoogleLogin from "vue-google-login";
import AlertModal from "../components/AlertModal.vue";
import ForgetPasswordModal from "../components/ForgetPasswordModal.vue";
export default {
  name: "login",
  components: {
    GoogleLogin,
    AlertModal,
    ForgetPasswordModal,
  },
  data() {
    return {
      lineLoginUrl:
        "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657117832&redirect_uri=https://www.tfda.tw/lineLogin.html&state=login&scope=openid%20profile",
      googleLoginCss: {
        width: "standard",
        height: 40,
        longtitle: true,
      },
      forget: { Account: null, Email: null },
      imageCode: "",
      loginData: {
        Id: "",
        DecryptionPass: "",
        CodePass: "",
        DecryptionCodePass: "",
        Type: "login",
        Token: "",
        TokenID: "",
      },
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
      },
      apiGetImageCode: (data) =>
        this.userRequest.post("API/Default/GetImageCode", data),
      apiLogin: (data) => this.userRequest.post("API/Default/Login", data),
      apiForgetPassword: (data) =>
        this.userRequest.post("API/Default/ForgetPassword", data),
      apiLoginByGoogle: (data) =>
        this.userRequest.post("API/Default/LoginByGoogle", data),
      apiExpiredPassword: (data) =>
        this.userRequest.post("API/Default/ExpiredPassword", data),
      apiLoginCheck: (data) =>
        this.userRequest.post("API/Default/LoginCheck", data),
    };
  },
  mounted() {
    this.getImageCode();
    this.checkLogin();
  },
  methods: {
    googleLoginSuccess(googleUser) {
      let Gmail = googleUser.getBasicProfile().getId();
      this.FunctionToken(this.LoginByGoogle, { Id: Gmail });
    },
    LoginByGoogle(data) {
      this.apiLoginByGoogle(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            sessionStorage.setItem("TokenID", json.Data);
            sessionStorage.removeItem("LoginError");
            this.$router.push({ path: "/Map2D" });
          } else {
            this.alertModalObj.content = "尚未註冊或未連接Google帳號";
            this.alertModalObj.show = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    forget_Click() {
      let json = {};
      json.AC_USERID = this.forget.Account;
      json.AC_EMAIL = this.forget.Email;
      this.FunctionToken(this.ForgetPassword, json);
    },
    checkLogin() {
      let TokenID = sessionStorage.getItem("TokenID");
      let LoginError = sessionStorage.getItem("LoginError");
      if (TokenID) {
        this.$router.push({ path: "Map2D" });
      }
      if (LoginError) {
        this.alertModalObj.content = "尚未註冊或未連接Line帳號";
        this.alertModalObj.show = true;
      }
    },
    getImageCode() {
      this.apiGetImageCode()
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonImage = JSON.parse(json.Data);
            this.imageCode = jsonImage.Image;
            this.loginData.DecryptionCodePass = jsonImage.Code;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    userLogin() {
      this.FunctionToken(this.LoginCheck, this.loginData);
    },
    Login(data) {
      this.apiLogin(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonToken = json.Data;
            sessionStorage.setItem("TokenID", jsonToken);
            sessionStorage.removeItem("LoginError");
            this.$router.push({ path: "/Map2D" });
          } else {
            if (json.Data === "錯誤：您的密碼已過期，請立即更新") {
              this.$bvModal
                .msgBoxOk("您的密碼已過期，請立即更新", {
                  title: "警告",
                  okVariant: "danger",
                  okTitle: "確認",
                })
                .then(() => {
                  this.FunctionToken(this.ExpiredPassword, {
                    Id: data.Id,
                  });
                });
            } else {
              this.alertModalObj.content = json.Data;
              this.alertModalObj.show = true;
              this.getImageCode();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ForgetPassword(data) {
      this.apiForgetPassword(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            // console.log(json.Data);
            this.alertModalObj.content = "已寄送重置密碼郵件至信箱";
            this.alertModalObj.show = true;
          } else {
            this.alertModalObj.content = "帳號或電子信箱錯誤";
            this.alertModalObj.show = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ExpiredPassword(data) {
      this.apiExpiredPassword(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            this.$bvModal.msgBoxOk("更新密碼郵件已寄送至您的信箱，請查收");
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    LoginCheck(data) {
      this.apiLoginCheck(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            if (data.Type === "Line") window.location.href = this.lineLoginUrl;
            else if (data.Type === "Google")
              document.getElementById("GoogleLoginButton").click();
            else this.FunctionToken(this.Login, this.loginData);
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
