<template>
  <div class="wrapper">
    <div id="headerBar" class="header mt1">
      <b-navbar toggleable="lg" type="dark">
        <b-navbar-brand>林地資訊管理</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              @click="changePage('Map2D')"
              :class="{ headerActive: nacActive[0] }"
              v-show="permission.Map2D"
              >空間圖台</b-nav-item
            >

            <b-nav-item-dropdown
              text="資料管理"
              right
              :class="{ headerActive: nacActive[1] }"
              v-show="permission.DataManager"
            >
              <b-dropdown-item
                @click="changePage('RevisionResult')"
                v-show="permission.RevisionResult"
                >保安林檢訂成果</b-dropdown-item
              >
              <!-- <b-dropdown-item to="/PlanResult" v-show="permission.PlanResult"
                >國土綠網計畫成果</b-dropdown-item
              > -->
              <b-dropdown-item
                @click="changePage('WLManage')"
                v-show="permission.WLManage"
                >林地管理</b-dropdown-item
              >
              <!-- <b-dropdown-item to="/WFManage" v-show="permission.WFManage"
                >水資源及設施管理</b-dropdown-item
              > -->
              <b-dropdown-item
                @click="changePage('WLDEvaluate')"
                v-show="permission.WLDEvaluate"
                >林地災害潛勢評估</b-dropdown-item
              >
            </b-nav-item-dropdown>

            <b-nav-item
              @click="changePage('Dashboard')"
              :class="{ headerActive: nacActive[2] }"
              v-show="permission.Dashboard"
              >空間分析</b-nav-item
            >
            <b-nav-item
              @click="changePage('MapManager')"
              :class="{ headerActive: nacActive[3] }"
              v-show="permission.MapManager"
              >圖台管理</b-nav-item
            >
            <b-nav-item
              @click="changePage('Account')"
              :class="{ headerActive: nacActive[4] }"
              v-show="permission.Account"
              >帳號管理</b-nav-item
            >
            <b-nav-item
              @click="changePage('MyAccount')"
              :class="{ headerActive: nacActive[5] }"
              >個人資料維護</b-nav-item
            >
            <b-nav-item to="/Logout">登出</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      nacActive: [],
      permission: {},
      apiGetMyGroupPermission: (data) =>
        this.userRequest.post("API/Default/GetMyGroupPermission", data),
    };
  },
  created() {
    this.getPage();
  },
  mounted() {
    this.FunctionToken(this.GetMyGroupPermission, {});
  },
  watch: {
    $route() {
      this.getPage();
    },
  },
  destroyed() {},
  methods: {
    getPage() {
      this.nacActive = [false, false, false, false, false, false];
      let page = this.$route.name;
      switch (page) {
        case "Map2D":
          this.nacActive[0] = true;
          break;
        case "RevisionResult":
          this.nacActive[1] = true;
          break;
        case "RevisionResultDetail":
          this.nacActive[1] = true;
          break;
        case "PlanResult":
          this.nacActive[1] = true;
          break;
        case "WLManage":
          this.nacActive[1] = true;
          break;
        case "WFManage":
          this.nacActive[1] = true;
          break;
        case "WLDEvaluate":
          this.nacActive[1] = true;
          break;
        case "Dashboard":
          this.nacActive[2] = true;
          break;
        case "MapManager":
          this.nacActive[3] = true;
          break;
        case "Account":
          this.nacActive[4] = true;
          break;
        case "MyAccount":
          this.nacActive[5] = true;
          break;
      }
    },
    changePage(target) {
      const DrawAndTextMode = sessionStorage.getItem("DrawAndTextMode");
      if (DrawAndTextMode) {
        this.$bvModal
          .msgBoxConfirm(
            "繪製圖層中，更換頁面將會遺失目前繪製內容，是否確定要更換頁面。",
            {
              okTitle: "確定",
              cancelTitle: "取消",
            }
          )
          .then((value) => {
            if (value) {
              sessionStorage.removeItem("DrawAndTextMode")
              this.$router.push(target);
            }
          })
          .catch(() => {
            console.log("fail");
          });
      } else {
        sessionStorage.removeItem("DrawAndTextMode")
        this.$router.push(target);
      }
    },
    GetMyGroupPermission(data) {
      this.apiGetMyGroupPermission(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.permission = jsonData;
            console.log("GetMyGroupPermission", this.permission)
          } else {
            console.log(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
