<template>
  <div class="page">
    <header>
      <b-row no-gutters align-h="between" align-v="center">
        <b-col cols="auto"> </b-col>
        <b-col cols="auto">
          <b-button
            class="mr-3"
            variant="light"
            v-b-modal.authority-modal
            @click="
              authority.Title = '新增權限群組';
              authority.OKTitle = '新增';
            "
          >
            <b-icon icon="plus"></b-icon> 新增</b-button
          >
        </b-col>
      </b-row>
    </header>

    <div class="page-content">
      <b-container>
        <b-row>
          <b-col md="6" offset-md="3">
            <div class="p-3 content-box">
              <b-table
                hover
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                show-empty
                empty-text="查無資料"
                tbody-tr-class="text-nowrap"
              >
                <template #cell(operate)="data">
                  <b-button-group>
                    <b-button
                      variant="light"
                      v-b-modal.authority-modal
                      @click="
                        authority.Title = '編輯權限群組';
                        authority.OKTitle = '確定';
                        authority.AG_ID = data.item.AG_ID;
                        authority.Name = data.item.AG_NAME;
                        authority.selected = JSON.parse(data.item.AG_PJID);
                      "
                    >
                      <b-icon icon="pencil"></b-icon> 編輯</b-button
                    >
                    <b-button
                      variant="light"
                      v-b-modal.delete-info
                      @click="deleteModalObj.AG_ID = data.item.AG_ID"
                    >
                      <b-icon icon="trash"></b-icon> 刪除</b-button
                    >
                  </b-button-group>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="overflow-auto">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        class="pt-3"
        aria-controls="my-table"
        align="center"
        first-number
        last-number
        limit="10"
      ></b-pagination>
    </div>

    <b-modal
      content-class="shadow"
      size="sm"
      id="authority-modal"
      :title="authority.Title"
      ok-only
      :ok-title="authority.OKTitle"
      ok-variant="primary"
      no-close-on-backdrop
      hide-backdrop
      no-stacking
      scrollable
      centered
      @ok="FunctionAccount_Group"
      @close="FunctionCloseModel"
    >
      <b-form-group label="群組名稱">
        <b-form-input type="text" v-model="authority.Name"></b-form-input>
      </b-form-group>
      <b-form-group label="權限指定">
        <b-form-checkbox-group
          v-model="authority.selected"
          :options="authority.options"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </b-modal>
    <AlertModal :alertModalObj="alertModalObj" @getData="init" />
    <DeleteModal :deleteModalObj="deleteModalObj" @getData="init" />
  </div>
</template>
<script>
import AlertModal from "../components/AlertModal.vue";
import DeleteModal from "../components/DeleteModal.vue";
export default {
  components: {
    AlertModal,
    DeleteModal,
  },
  data() {
    return {
      perPage: 8,
      currentPage: 1,
      items: [],
      fields: [
        {
          key: "AG_NAME",
          label: "群組名稱",
        },
        {
          key: "operate",
          label: "操作",
          thClass: "append",
          tdClass: "append",
        },
      ],
      authority: {
        AG_ID: null,
        Title: "",
        OKTitle: "",
        Name: null,
        selected: [],
        options: [],
      },
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
        refresh: false,
      },
      deleteModalObj: {
        AG_ID: null,
        content: "確定要刪除此筆權限群組嗎?",
      },
      apiGetProjectList: (data) =>
        this.userRequest.post("API/Default/GetProjectList", data),
      apiGetAccount_GroupList: (data) =>
        this.userRequest.post("API/Default/GetAccount_GroupList", data),
      apiInsertAccount_Group: (data) =>
        this.userRequest.post("API/Default/InsertAccount_Group", data),
      apiUpdateACCOUNT_GROUP: (data) =>
        this.userRequest.post("API/Default/UpdateACCOUNT_GROUP", data),
    };
  },
  created() {
    this.FunctionToken(this.GetProjectList, {});
    this.FunctionToken(this.GetAccount_GroupList, {});
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    init() {
      this.FunctionToken(this.GetAccount_GroupList, {});
      this.FunctionCloseModel();
    },
    FunctionCloseModel() {
      this.authority.AG_ID = null;
      this.authority.Name = null;
      this.authority.selected = [];
    },
    FunctionAccount_Group() {
      if (this.authority.AG_ID == null) {
        if (this.IsNull(this.authority.Name)) {
          let json = {};
          json.AG_NAME = this.authority.Name;
          this.authority.selected.forEach((x) => {
            if (
              x == "PJ202111030003" ||
              x == "PJ202111030001" ||
              x == "PJ202111030009" ||
              x == "PJ202111030011" ||
              x == "PJ202111030012"
            ) {
              this.authority.selected.push("PJ202111030010");
            }
          });
          json.AG_PJID = JSON.stringify(this.authority.selected);
          // console.log(json)
          this.FunctionToken(this.InsertAccount_Group, json);
        } else {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "請輸入群組名稱";
          this.alertModalObj.refresh = false;
        }
      } else {
        let json = {};
        json.AG_ID = this.authority.AG_ID;
        json.AG_NAME = this.authority.Name;
        this.authority.selected.forEach((x) => {
          if (
            x == "PJ202111030003" ||
            x == "PJ202111030001" ||
            x == "PJ202111030009" ||
            x == "PJ202111030011" ||
            x == "PJ202111030012"
          ) {
            this.authority.selected.push("PJ202111030010");
          }
        });
        json.AG_PJID = JSON.stringify(this.authority.selected);
        // console.log(json);
        this.FunctionToken(this.UpdateACCOUNT_GROUP, json);
      }
    },
    //======================api===========================
    //取得權限清單
    GetProjectList(data) {
      this.apiGetProjectList(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let DataArr = JSON.parse(json.Data);
            DataArr.sort(function (a, b) {
              return a.PJ_KIND - b.PJ_KIND;
            });
            // console.log(DataArr)
            let item = [];
            for (let i = 0; i < DataArr.length; i++) {
              //權限子項目顯示條件
              if (DataArr[i].PJ_ID != "PJ202111030010") {
                let Data = {
                  text: DataArr[i].PJ_NAME,
                  value: DataArr[i].PJ_ID,
                };
                item.push(Data);
              }
            }
            this.authority.options = item;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //取得群組權限
    GetAccount_GroupList(data) {
      this.apiGetAccount_GroupList(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            // console.log(JSON.parse(json.Data));
            this.items = JSON.parse(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //新增權限清單
    InsertAccount_Group(data) {
      this.apiInsertAccount_Group(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "新增成功";
            this.alertModalObj.refresh = true;
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //更新群組權限
    UpdateACCOUNT_GROUP(data) {
      this.apiUpdateACCOUNT_GROUP(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          // console.log(json);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新群組權限成功";
            this.alertModalObj.refresh = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
