<template>
  <b-modal
    content-class="shadow"
    id="alert-model"
    :title="alertModalObj.title"
    ok-only
    ok-title="確定"
    ok-variant="danger"
    cancel-title="取消"
    no-close-on-backdrop
    hide-backdrop
    centered
    @ok="FunctionOK"
    v-model="alertModalObj.show"
  >
    <p style="white-space: break-spaces;">
      {{ alertModalObj.content }}
    </p>
    <!-- <div v-for="(text, index) in alertModalObj.content" :key="index">
      {{ text }}
    </div> -->
  </b-modal>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["alertModalObj"],
  methods: {
    FunctionOK() {
      if (this.alertModalObj.refresh == true) {
        this.$emit("getData");
      }
      if (this.alertModalObj.url != undefined && this.alertModalObj.url != null && this.alertModalObj.url != '') {
        this.$router.push({ path: this.alertModalObj.url });
      }
    },
    // FunctionAccountGetData() {
    //   this.$emit("getData");
    // },
  },
};
</script>