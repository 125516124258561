<template>
  <div class="page">
    <div class="page-content">
      <b-row no-gutters align-h="between" align-v="center" class="py-4">
        <b-col cols="auto">
          <div class="px-3">
            <b-form inline>
              <label class="mr-sm-2" for="inline-form-custom-select-pref"
                >圖層分類</label
              >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="search_sort"
                :options="map.sortOptions"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- 請選擇 --</b-form-select-option
                  >
                </template>
              </b-form-select>
              <label class="mr-sm-2" for="inline-form-custom-select-pref"
                >圖層種類</label
              >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="search_type"
                :options="map.typeSearchOptions"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- 請選擇 --</b-form-select-option
                  >
                </template>
              </b-form-select>
              <label class="mr-sm-2" for="inline-form-custom-select-pref"
                >關鍵字查詢</label
              >
              <b-form-input
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="search_keyword"
                placeholder="請輸入關鍵字"
                @keyup.enter="FunctionSearch"
              ></b-form-input>
              <b-button variant="dark" @click="FunctionSearch">查詢</b-button
              >&nbsp;
              <b-button
                variant="danger"
                @click="
                  search_sort = null;
                  search_type = null;
                  search_keyword = null;
                  FunctionSearch();
                "
                >清除</b-button
              >
            </b-form>
          </div>
        </b-col>
      </b-row>
      <div>
        <b-row no-gutters align-h="between" align-v="center">
          <b-col cols="auto">
            <b-breadcrumb class="mb-0" :items="breadcrumb"></b-breadcrumb
          ></b-col>
          <b-col cols="auto">
            <b-button
              class="mr-3 mt-1"
              variant="outline-dark"
              v-if="pagePermission.MapManager_Editor"
              v-b-modal.insert-map
            >
              <b-icon icon="plus"></b-icon> 新增</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive content-box container mt-3">
        <b-table
          hover
          class="table table-bordered table-striped"
          :items="items"
          :fields="dataFields"
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
          empty-text="查無資料"
          tbody-tr-class="text-nowrap"
        >
          <template #cell(operate)="data">
            <b-button-group>
              <b-button
                variant="success"
                v-if="pagePermission.MapManager_Editor"
                v-b-modal.edit-map
                class="ml-3"
                @click="
                  map.key = data.item.ML_ID;
                  map.sortSelected = data.item.ML_TYPE;
                  map.name = data.item.ML_NAME;
                  map.source = data.item.ML_SOURCE;
                  map.remark = data.item.ML_REMARK;
                "
              >
                <b-icon icon="pencil"></b-icon> 編輯</b-button
              >
              <b-button
                variant="info"
                v-if="
                  pagePermission.MapManager_Editor &&
                  data.item.ML_CATEGORY != 'WMS' &&
                  data.item.ML_CATEGORY != 'WFS' &&
                  data.item.ML_CATEGORY != 'API'
                "
                v-b-modal.upload-map
                class="ml-3"
                @click="
                  map.key = data.item.ML_ID;
                  map.typeSelected = data.item.ML_CATEGORY;
                  map.name = data.item.ML_NAME;
                "
              >
                <b-icon icon="map-fill"></b-icon> 更新圖層</b-button
              >
              <b-button
                variant="secondary"
                class="ml-3"
                v-if="
                  pagePermission.MapManager_Editor &&
                  data.item.ML_CATEGORY != 'WMS' &&
                  data.item.ML_CATEGORY != 'API'
                "
                @click="FunctionUpdateProperty(data.item)"
                ><b-icon icon="images"></b-icon> 更新屬性</b-button
              >
              <b-button
                variant="danger"
                v-if="pagePermission.MapManager_Editor"
                v-b-modal.delete-info
                class="ml-3"
                @click="deleteModalObj.ML_ID = data.item.ML_ID"
              >
                <b-icon icon="trash"></b-icon> 刪除</b-button
              >
              <b-button
                variant="primary"
                class="ml-3"
                v-if="
                  data.item.ML_CATEGORY != 'WMS' &&
                  data.item.ML_CATEGORY != 'WFS' &&
                  data.item.ML_CATEGORY != 'API'
                "
                @click="FunctionDownloadFile(data.item)"
              >
                <b-icon icon="download"></b-icon> 下載</b-button
              >
            </b-button-group>
          </template>
        </b-table>
      </div>
      <div class="overflow-auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          class="pt-3"
          aria-controls="my-table"
          align="center"
          first-number
          last-number
          limit="10"
        ></b-pagination>
      </div>
      <AlertModal :alertModalObj="alertModalObj" @getData="init" />
      <DeleteModal :deleteModalObj="deleteModalObj" @getData="init" />
    </div>
    <b-modal
      content-class="shadow"
      size="sm"
      id="insert-map"
      title="新增圖層"
      ok-only
      ok-title="新增"
      ok-variant="primary"
      no-close-on-backdrop
      hide-backdrop
      no-stacking
      scrollable
      centered
      @ok="InsertMap"
      @close="FunctionCloseModel"
    >
      <b-form-group label="圖層分類">
        <b-form-select
          v-model="map.sortSelected"
          :options="map.sortOptions"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="圖層種類">
        <b-form-select
          v-model="map.typeSelected"
          :options="map.typeOptions"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="自定義圖層名稱">
        <b-form-input
          type="text"
          v-model="map.name"
          placeholder="請輸入圖層名稱"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="WMS URL"
        v-if="
          map.typeSelected == 'WMS' ||
          map.typeSelected == 'WFS' ||
          map.typeSelected == 'WMTS'
        "
      >
        <b-form-input
          type="text"
          v-model="map.wmsUrl"
          placeholder="https://"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="檔案 (座標系統TWD97)"
        v-if="map.typeSelected == 'SHP'"
      >
        <b-form-file
          v-model="map.shpFile"
          :state="Boolean(map.shpFile)"
          placeholder="請上傳zip檔,應包含.shp、.shx、.dbf"
          accept=".zip"
        ></b-form-file>
      </b-form-group>
      <b-form-group label="檔案" v-if="map.typeSelected == 'KML'">
        <b-form-file
          v-model="map.kmlFile"
          :state="Boolean(map.kmlFile)"
          placeholder="請上傳kml檔"
          accept=".kml"
        ></b-form-file>
      </b-form-group>
      <b-form-group label="檔案" v-if="map.typeSelected == 'GPX'">
        <b-form-file
          v-model="map.gpxFile"
          :state="Boolean(map.gpxFile)"
          placeholder="請上傳gpx檔"
          accept=".gpx"
        ></b-form-file>
      </b-form-group>
      <b-form-group label="檔案" v-if="map.typeSelected == 'GEOJSON'">
        <b-form-file
          v-model="map.geojsonFile"
          :state="Boolean(map.geojsonFile)"
          placeholder="請上傳geoJson檔案"
          accept=".geojson"
        ></b-form-file>
      </b-form-group>
      <b-form-group label="API Method" v-if="map.typeSelected == 'API'">
        <b-form-input
          type="text"
          v-model="map.apiFunction"
          placeholder="Function Name"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="圖資來源">
        <b-form-select
          v-model="map.source"
          :options="['內部圖資', '外部圖資']"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="備註">
        <b-form-textarea
          v-model="map.remark"
          placeholder="請輸入備註..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
    </b-modal>
    <b-modal
      content-class="shadow"
      size="sm"
      id="edit-map"
      title="編輯圖層"
      ok-only
      ok-title="編輯"
      ok-variant="primary"
      no-close-on-backdrop
      hide-backdrop
      no-stacking
      scrollable
      centered
      @ok="EditMap"
      @close="FunctionCloseModel"
    >
      <b-form-group label="圖層分類">
        <b-form-select
          v-model="map.sortSelected"
          :options="map.sortOptions"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="自定義圖層名稱">
        <b-form-input
          type="text"
          v-model="map.name"
          placeholder="請輸入圖層名稱"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="圖資來源">
        <b-form-select
          v-model="map.source"
          :options="['內部圖資', '外部圖資']"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="備註">
        <b-form-textarea
          v-model="map.remark"
          placeholder="請輸入備註..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
    </b-modal>
    <b-modal
      content-class="shadow"
      size="sm"
      id="upload-map"
      title="更新圖層"
      ok-only
      ok-title="更新"
      ok-variant="primary"
      no-close-on-backdrop
      hide-backdrop
      no-stacking
      scrollable
      centered
      @ok="UpdateMap"
      @close="FunctionCloseModel"
    >
      <b-form-group label="圖層種類">
        <b-form-select
          v-model="map.typeSelected"
          :options="map.typeSearchOptions"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        label="WMS URL"
        v-if="
          map.typeSelected == 'WMS' ||
          map.typeSelected == 'WFS' ||
          map.typeSelected == 'WMTS'
        "
      >
        <b-form-input
          type="text"
          v-model="map.wmsUrl"
          placeholder="https://"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="檔案 (座標系統TWD97)"
        v-if="map.typeSelected == 'SHP'"
      >
        <b-form-file
          v-model="map.shpFile"
          :state="Boolean(map.shpFile)"
          placeholder="請上傳zip檔,應包含.shp、.shx、.dbf"
          accept=".zip"
        ></b-form-file>
      </b-form-group>
      <b-form-group label="檔案" v-if="map.typeSelected == 'KML'">
        <b-form-file
          v-model="map.kmlFile"
          :state="Boolean(map.kmlFile)"
          placeholder="請上傳kml檔"
          accept=".kml"
        ></b-form-file>
      </b-form-group>
      <b-form-group label="檔案" v-if="map.typeSelected == 'GPX'">
        <b-form-file
          v-model="map.gpxFile"
          :state="Boolean(map.gpxFile)"
          placeholder="請上傳gpx檔"
          accept=".gpx"
        ></b-form-file>
      </b-form-group>
      <b-form-group label="檔案" v-if="map.typeSelected == 'GEOJSON'">
        <b-form-file
          v-model="map.geojsonFile"
          :state="Boolean(map.geojsonFile)"
          placeholder="請上傳geoJson檔案"
          accept=".geojson"
        ></b-form-file>
      </b-form-group>
      <b-form-group label="API Method" v-if="map.typeSelected == 'API'">
        <b-form-input
          type="text"
          v-model="map.apiFunction"
          placeholder="Function Name"
        ></b-form-input>
      </b-form-group>
    </b-modal>
    <b-modal
      content-class="shadow"
      size="lg"
      id="update-property"
      title="更新圖層屬性"
      ok-only
      ok-title="更新"
      ok-variant="primary"
      no-close-on-backdrop
      hide-backdrop
      no-stacking
      scrollable
      centered
      @ok="UpdateMapProperty"
    >
      <b-row v-for="(item, index) in PropertyList" :key="index">
        <b-col md="4">
          {{ item.oriProperty }}
        </b-col>
        <b-col md="8">
          <b-form-group>
            <b-form-input v-model="item.newProperty"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <div
      class="text-center"
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
      "
      align-v="center"
      v-if="isLoad"
    >
      <b-row style="position: relative; top: 45vh">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner
          ><span
            style="
              font-size: 20px;
              color: #ffffff;
              font-weight: bold;
              padding-left: 20px;
            "
            >載入中，請稍後...</span
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import AlertModal from "../components/AlertModal.vue";
import DeleteModal from "../components/DeleteModal.vue";
export default {
  components: {
    AlertModal,
    DeleteModal,
  },
  data() {
    return {
      isLoad: false,
      pagePermission: null,
      rows: 0,
      breadcrumb: [
        {
          text: "圖台管理",
          active: true,
        },
      ],
      perPage: 8,
      currentPage: 1,
      items: [],
      dataFields: [
        {
          key: "ML_TYPE",
          label: "圖層分類",
        },
        {
          key: "ML_CATEGORY",
          label: "圖層種類",
        },
        {
          key: "ML_NAME",
          label: "圖層名稱",
        },
        {
          key: "ML_SOURCE",
          label: "圖資來源",
        },
        {
          key: "ML_REMARK",
          label: "備註",
        },
        {
          key: "operate",
          label: "功能",
          thClass: "append",
          tdClass: "append",
        },
      ],
      map: {
        key: null,
        title: null,
        oKTitle: null,
        sortSelected: "國土綠網計畫成果",
        sortOptions: [
          "國土綠網計畫成果",
          "國土綠網計畫成果_外來種",
          "國土綠網計畫成果_全國國土綠網",
          "林地管理",
          "水資源及設施管理",
          "林地災害潛勢評估",
          "林地災害潛勢評估_淹水潛勢圖",
          "林地災害潛勢評估_臺東縣海岸侵蝕風險",
          "社區巡視路線",
          "林政管理類",
          "森林經營類",
          "森林育樂類",
          "造林生產類",
          "保育類",
          "集水區治理類",
          "影像類",
          "行政區與地籍",
          "外單位管理區域",
          "交通網路",
          "河川水文",
          "河川水文_縣管河川",
          "河川水文_縣管區域排水",
          "土地分區與調查",
          "氣象類",
          "其他參考圖",
          //"FGIS待確認",
        ],
        typeSelected: "WMS",
        typeOptions: ["WMS", "WFS", "WMTS", "SHP", "KML", "GPX", "GEOJSON"],
        typeSearchOptions: [
          "WMS",
          "WFS",
          "WMTS",
          "SHP",
          "KML",
          "GPX",
          "GEOJSON",
          "API",
        ],
        name: null,
        wmsUrl: null,
        shpFile: null,
        kmlFile: null,
        gpxFile: null,
        geojsonFile: null,
        apiFunction: null,
      },
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
        refresh: false,
      },
      deleteModalObj: {
        ML_ID: null,
        content: "確定要刪除此筆圖資嗎?",
      },
      search_sort: null,
      search_type: null,
      search_keyword: null,
      formData: new FormData(),
      PropertyList: [],
      editMLID: "",
      Permission: null,
      apiGetMapLayerList: (data) =>
        this.userRequest.post("API/Default/GetMapLayerList", data),
      apiInsertMapLayer: (data) =>
        this.userRequest.post("API/Default/InsertMapLayer", data),
      apiUpdateMapLayer: (data) =>
        this.userRequest.post("API/Default/UpdateMapLayer", data),
      apiUpdateMapMedia: (data) =>
        this.userRequest.post("API/Default/UpdateMapMedia", data),
      apiDownloadLayerFile: (data) =>
        this.userRequest.post("API/Default/DownloadLayerFile", data),
      apiGetMyGroupPermission: (data) =>
        this.userRequest.post("API/Default/GetMyGroupPermission", data),
      apiGetMapProperty: (data) =>
        this.userRequest.post("API/Default/GetMapProperty", data),
      apiSaveMapProperty: (data) =>
        this.userRequest.post("API/Default/SaveMapProperty", data),
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.FunctionToken(this.GetMyGroupPermission, {});
      this.FunctionToken(this.GetMapLayerList, { ML_LAYERMODE: 1 });
      this.FunctionCloseModel();
    },
    FunctionCloseModel() {
      this.formData = new FormData();
      this.map = {
        key: null,
        title: null,
        oKTitle: null,
        sortSelected: "國土綠網計畫成果",
        sortOptions: [
          "國土綠網計畫成果",
          "國土綠網計畫成果_外來種",
          "國土綠網計畫成果_全國國土綠網",
          "林地管理",
          "水資源及設施管理",
          "林地災害潛勢評估",
          "林地災害潛勢評估_淹水潛勢圖",
          "林地災害潛勢評估_臺東縣海岸侵蝕風險",
          "社區巡視路線",
          "林政管理類",
          "森林經營類",
          "森林育樂類",
          "造林生產類",
          "保育類",
          "集水區治理類",
          "影像類",
          "行政區與地籍",
          "外單位管理區域",
          "交通網路",
          "河川水文",
          "河川水文_縣管河川",
          "河川水文_縣管區域排水",
          "土地分區與調查",
          "氣象類",
          "其他參考圖",
          //"FGIS待確認",
        ],
        typeSelected: "WMS",
        typeOptions: ["WMS", "WFS", "WMTS", "SHP", "KML", "GPX", "GEOJSON"],
        typeSearchOptions: [
          "WMS",
          "WFS",
          "WMTS",
          "SHP",
          "KML",
          "GPX",
          "GEOJSON",
          "API",
        ],
        name: null,
        wmsUrl: null,
        shpFile: null,
        kmlFile: null,
        gpxFile: null,
        geojsonFile: null,
        apiFunction: null,
      };
      this.isLoad = false;
    },
    InsertMap() {
      if (this.map.name != null) {
        this.formData.append("ML_TYPE", this.map.sortSelected);
        this.formData.append("ML_CATEGORY", this.map.typeSelected);
        this.formData.append("ML_NAME", this.map.name);
        this.formData.append("ML_SOURCE", this.map.source);
        this.formData.append("ML_REMARK", this.map.remark);
        this.formData.append("ML_LAYERMODE", 1);

        if (
          this.map.typeSelected == "WMS" ||
          this.map.typeSelected == "WFS" ||
          this.map.typeSelected == "WMTS"
        ) {
          if (this.map.wmsUrl != null) {
            this.formData.append("ML_URL", this.map.wmsUrl);
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "請填寫URL";
            this.alertModalObj.refresh = false;
          }
        } else if (this.map.typeSelected == "SHP") {
          console.log(this.map.shpFile);
          if (this.map.shpFile != null) {
            this.formData.append(this.map.shpFile.name, this.map.shpFile);
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "請上傳SHP檔案";
            this.alertModalObj.refresh = false;
          }
        } else if (this.map.typeSelected == "KML") {
          if (this.map.kmlFile != null) {
            this.formData.append(this.map.kmlFile.name, this.map.kmlFile);
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "請上傳KML檔案";
            this.alertModalObj.refresh = false;
          }
        } else if (this.map.typeSelected == "GPX") {
          if (this.map.gpxFile != null) {
            this.formData.append(this.map.gpxFile.name, this.map.gpxFile);
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "請上傳GPX檔案";
            this.alertModalObj.refresh = false;
          }
        } else if (this.map.typeSelected == "GEOJSON") {
          if (this.map.geojsonFile != null) {
            this.formData.append(
              this.map.geojsonFile.name,
              this.map.geojsonFile
            );
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "請上傳GEOJSON檔案";
            this.alertModalObj.refresh = false;
          }
        } else if (this.map.typeSelected == "API") {
          if (this.map.apiFunction != null) {
            this.formData.append("ML_URL", this.map.apiFunction);
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "請填寫Function";
            this.alertModalObj.refresh = false;
          }
        }
      } else {
        this.alertModalObj.show = true;
        this.alertModalObj.content = "請填寫圖層名稱";
        this.alertModalObj.refresh = false;
      }
    },
    //編輯圖層資訊
    EditMap() {
      if (this.map.name != null) {
        let json = {
          ML_ID: this.map.key,
          ML_NAME: this.map.name,
          ML_TYPE: this.map.sortSelected,
          ML_SOURCE: this.map.source,
          ML_REMARK: this.map.remark,
        };
        this.FunctionToken(this.UpdateMapLayer, json);
      } else {
        this.alertModalObj.show = true;
        this.alertModalObj.content = "請填寫圖層名稱";
        this.alertModalObj.refresh = false;
      }
    },
    //更新圖層檔案
    UpdateMap() {
      this.formData.append("ML_ID", this.map.key);
      this.formData.append("ML_NAME", this.map.name);
      this.formData.append("ML_CATEGORY", this.map.typeSelected);
      if (
        this.map.typeSelected == "WMS" ||
        this.map.typeSelected == "WFS" ||
        this.map.typeSelected == "WMTS"
      ) {
        if (this.IsNull(this.map.wmsUrl)) {
          this.formData.append("ML_URL", this.map.wmsUrl);
          this.FunctionToken(this.UpdateMapMedia, {});
        } else {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "請填寫URL";
          this.alertModalObj.refresh = false;
        }
      } else if (this.map.typeSelected == "SHP") {
        if (this.IsNull(this.map.shpFile)) {
          this.formData.append(this.map.shpFile.name, this.map.shpFile);
          this.FunctionToken(this.UpdateMapMedia, {});
        } else {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "請上傳SHP檔案";
          this.alertModalObj.refresh = false;
        }
      } else if (this.map.typeSelected == "KML") {
        if (this.IsNull(this.map.kmlFile)) {
          this.formData.append(this.map.kmlFile.name, this.map.kmlFile);
          this.FunctionToken(this.UpdateMapMedia, {});
        } else {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "請上傳KML檔案";
          this.alertModalObj.refresh = false;
        }
      } else if (this.map.typeSelected == "GPX") {
        if (this.IsNull(this.map.gpxFile)) {
          this.formData.append(this.map.gpxFile.name, this.map.gpxFile);
          this.FunctionToken(this.UpdateMapMedia, {});
        } else {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "請上傳GPX檔案";
          this.alertModalObj.refresh = false;
        }
      } else if (this.map.typeSelected == "GEOJSON") {
        if (this.map.geojsonFile != null) {
          this.formData.append(this.map.geojsonFile.name, this.map.geojsonFile);
          this.FunctionToken(this.InsertMapLayer, {});
        } else {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "請上傳GEOJSON檔案";
          this.alertModalObj.refresh = false;
        }
      } else if (this.map.typeSelected == "API") {
        if (this.map.apiFunction != null) {
          this.formData.append("ML_URL", this.map.apiFunction);
          this.FunctionToken(this.UpdateMapMedia, {});
        } else {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "請填寫Function";
          this.alertModalObj.refresh = false;
        }
      }
    },
    FunctionDownloadFile(item) {
      this.FunctionToken(this.DownloadLayerFile, {
        ML_ID: item.ML_ID,
      });
    },
    FunctionUpdateProperty(item) {
      this.editMLID = item.ML_ID;
      this.FunctionToken(this.GetMapProperty, { ML_ID: item.ML_ID });
    },
    UpdateMapProperty() {
      this.FunctionToken(this.SaveMapProperty, {
        ML_ID: this.editMLID,
        PropertyList: this.PropertyList,
      });
    },
    //======================api===========================
    //新增圖資
    InsertMapLayer(data) {
      this.isLoad = true;
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      this.apiInsertMapLayer(this.formData)
        .then((res) => {
          let json = JSON.parse(res.data);
          console.log(json);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "新增成功";
            this.alertModalObj.refresh = false;
            this.init();
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
            this.FunctionCloseModel();
          }
          this.isLoad = false;
        })
        .catch((err) => {
          console.log(err);
          this.FunctionCloseModel();
        });
    },
    //編輯圖層
    UpdateMapLayer(data) {
      this.apiUpdateMapLayer(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          console.log(json);
          if (json.Status) {
            // this.items = JSON.parse(json.Data);
            // console.log(this.items);
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新成功";
            this.alertModalObj.refresh = false;
            this.init();
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
            this.FunctionCloseModel();
          }
        })
        .catch((err) => {
          console.log(err);
          this.FunctionCloseModel();
        });
    },
    // 更新圖層(檔案)
    UpdateMapMedia(data) {
      this.isLoad = true;
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      this.apiUpdateMapMedia(this.formData)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            alert(json.Data);
            this.FunctionCloseModel();
          } else {
            alert(json.Message + "：" + json.Data);
            this.FunctionCloseModel();
          }
        })
        .catch((err) => {
          console.log(err);
          this.FunctionCloseModel();
        });
    },
    //取得圖層列表
    GetMapLayerList(data) {
      this.apiGetMapLayerList(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          console.log(json);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.items = jsonData;
            this.rows = jsonData.length;
            console.log(this.items);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    FunctionSearch() {
      const data = {
        keyword: this.search_keyword,
        sort: this.search_sort,
        type: this.search_type,
        ML_LAYERMODE: 1,
      };
      this.FunctionToken(this.GetMapLayerList, data);
    },
    DownloadLayerFile(data) {
      this.apiDownloadLayerFile(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            // window.open(json.Data);
            const jsonData = JSON.parse(json.Data);
            this.$axios({
              url: jsonData.url,
              method: "GET",
              responseType: "blob",
            }).then((response) => {
              var fileURL = window.URL.createObjectURL(
                new Blob([response.data])
              );
              var fURL = document.createElement("a");

              fURL.href = fileURL;
              fURL.setAttribute("download", jsonData.fileName);
              document.body.appendChild(fURL);

              fURL.click();
            });
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetMyGroupPermission(data) {
      this.apiGetMyGroupPermission(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.pagePermission = jsonData;
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetMapProperty(data) {
      this.apiGetMapProperty(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.PropertyList = jsonData;
            this.$bvModal.show("update-property");
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SaveMapProperty(data) {
      this.apiSaveMapProperty(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            alert(json.Data);
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
