<template>
  <div class="page">
    <div class="page-content">
      <b-breadcrumb class="mb-0" :items="breadcrumb"></b-breadcrumb>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="占用未結案件" active @click="ChangeTab('占用未結案件')">
            <b-row no-gutters align-h="between" align-v="center" class="py-4">
              <b-col cols="auto">
                <div class="px-3">
                  <b-form inline>
                    <b-form-select
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="OOCTownSelected"
                      :options="OOCTownOptions"
                    ></b-form-select>
                    <b-form-input
                      id="input-1"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="search"
                      placeholder="請輸入關鍵字"
                      required
                      @keyup.enter="FunctionSearch"
                    ></b-form-input>
                    <b-button variant="dark" @click="FunctionSearch"
                      >查詢</b-button
                    >
                  </b-form>
                </div>
              </b-col>
              <b-col cols="auto">
                <b-button
                  class="mr-3 mt-1"
                  variant="outline-dark"
                  v-if="pagePermission.WLManage_Editor"
                  @click="FunctionOpenImport"
                  ref="btnShow"
                >
                  <b-icon icon="plus"></b-icon> 匯入</b-button
                >
              </b-col>
            </b-row>
            <div class="tableArea" v-if="OOCTableItems.length > 0">
              <b-table
                :fields="OOCTableFields"
                :items="OOCTableItems"
                :per-page="OOCPerPage"
                :current-page="OOCCurrentPage"
                bordered
                hover
                responsive="sm"
                show-empty
                empty-text="查無資料"
                tbody-tr-class="text-nowrap"
              >
                <template #thead-top>
                  <b-tr>
                    <b-th colspan="2" class="text-center">編號</b-th>
                    <b-th colspan="9" class="text-center">國有土地標示</b-th>
                    <b-th colspan="5" class="text-center">國有建物標示</b-th>
                    <b-th colspan="3" class="text-center">占用者</b-th>
                    <b-th colspan="3" class="text-center"
                      ><span class="sr-only"></span
                    ></b-th>
                    <b-th colspan="2" class="text-center">TWD97座標</b-th>
                    <b-th colspan="5" class="text-center"
                      ><span class="sr-only"></span
                    ></b-th>
                  </b-tr>
                </template>
                <template #cell(OOC_CREATE_TIME)="data">
                  {{ FunctionDateFormat(data.item.OOC_CREATE_TIME) }}
                </template>
                <template #cell(OOC_UPDATE_TIME)="data">
                  {{ FunctionDateFormat(data.item.OOC_UPDATE_TIME) }}
                </template>
              </b-table>
            </div>
            <b-pagination
              v-model="OOCCurrentPage"
              :total-rows="OOCRows"
              :per-page="OOCPerPage"
              class="pt-3"
              aria-controls="my-table"
              align="center"
              first-number
              last-number
              limit="10"
            >
            </b-pagination>
          </b-tab>
          <b-tab
            title="原住民保留地清冊"
            @click="ChangeTab('原住民保留地清冊')"
          >
            <b-row no-gutters align-h="between" align-v="center" class="py-4">
              <b-col cols="auto">
                <div class="px-3">
                  <b-form inline>
                    <b-form-select
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="ARTownSelected"
                      :options="townOptions"
                    ></b-form-select>
                    <b-form-input
                      id="input-1"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="search"
                      placeholder="請輸入關鍵字"
                      required
                      @keyup.enter="FunctionSearch"
                    ></b-form-input>
                    <b-button variant="dark" @click="FunctionSearch"
                      >查詢</b-button
                    >
                  </b-form>
                </div>
              </b-col>
              <b-col cols="auto">
                <b-button
                  class="mr-3 mt-1"
                  variant="outline-dark"
                  v-if="pagePermission.WLManage_Editor"
                  @click="FunctionOpenImport"
                  ref="btnShow"
                >
                  <b-icon icon="plus"></b-icon> 匯入</b-button
                >
              </b-col>
            </b-row>
            <div class="tableArea" v-if="ARTableItems.length > 0">
              <b-table
                :fields="ARTableFields"
                :items="ARTableItems"
                :per-page="ARPerPage"
                :current-page="ARCurrentPage"
                bordered
                hover
                responsive="sm"
                show-empty
                empty-text="查無資料"
                tbody-tr-class="text-nowrap"
              >
                <template #cell(AR_CREATETIME)="data">
                  {{ FunctionDateFormat(data.item.AR_CREATETIME) }}
                </template>
                <template #cell(AR_UPDATETIME)="data">
                  {{ FunctionDateFormat(data.item.AR_UPDATETIME) }}
                </template>
                <template #cell(show_details)="row">
                  <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowing ? "隱藏" : "顯示" }} 詳細資料
                  </b-button>
                </template>
                <template #row-details="row">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"
                        ><b>所有權人:</b></b-col
                      >
                      <b-col>{{ row.item.AR_OWNER }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"
                        ><b>他項權利人:</b></b-col
                      >
                      <b-col>{{ row.item.AR_RIGHTHOLDER }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>租用人:</b></b-col>
                      <b-col>{{ row.item.AR_HIRER }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"
                        ><b>租用面積:</b></b-col
                      >
                      <b-col>{{ row.item.AR_RENTEDAREA }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"
                        ><b>租用類別:</b></b-col
                      >
                      <b-col>{{ row.item.AR_RENTALCATEGORY }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"
                        ><b>開始日-到期日:</b></b-col
                      >
                      <b-col>{{ row.item.AR_DATERANGE }}</b-col>
                    </b-row>

                    <b-button size="sm" @click="row.toggleDetails"
                      >隱藏 詳細資料</b-button
                    >
                  </b-card>
                </template>
              </b-table>
            </div>
            <b-pagination
              v-model="ARCurrentPage"
              :total-rows="ARRows"
              :per-page="ARPerPage"
              class="pt-3"
              aria-controls="my-table"
              align="center"
              first-number
              last-number
              limit="10"
            ></b-pagination>
          </b-tab>
          <b-tab
            title="海岸一定限度內不得為私人土地"
            @click="ChangeTab('海岸一定限度內不得為私人土地')"
          >
            <b-row no-gutters align-h="between" align-v="center" class="py-4">
              <b-col cols="auto">
                <div class="px-3">
                  <b-form inline>
                    <b-form-select
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="CPLTownSelected"
                      :options="CPLtownOptions"
                      @change="CPLtownChange"
                    ></b-form-select>
                    <b-form-select
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="CPLLotSelected"
                      :options="CPLLotOptions"
                    ></b-form-select>
                    <b-form-input
                      id="input-1"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="CPLPlaceNumberSelected"
                      placeholder="請輸入地號"
                      required
                      @keyup.enter="FunctionSearch"
                    ></b-form-input>
                    <b-button variant="dark" @click="FunctionSearch"
                      >查詢</b-button
                    >
                  </b-form>
                </div>
              </b-col>
              <b-col cols="auto">
                <b-button
                  class="mr-3 mt-1"
                  variant="outline-dark"
                  v-if="pagePermission.WLManage_Editor"
                  @click="FunctionOpenImport"
                  ref="btnShow"
                >
                  <b-icon icon="plus"></b-icon> 匯入</b-button
                >
              </b-col>
            </b-row>
            <div class="tableArea" v-if="CPLTableItems.length > 0">
              <b-table
                :fields="CPLTableFields"
                :items="CPLTableItems"
                :per-page="CPLPerPage"
                :current-page="CPLCurrentPage"
                bordered
                hover
                responsive="sm"
                show-empty
                empty-text="查無資料"
                tbody-tr-class="text-nowrap"
              >
                <template #cell(CPL_CREATE_TIME)="data">
                  {{ FunctionDateFormat(data.item.CPL_CREATE_TIME) }}
                </template>
                <template #cell(CPL_UPDATE_TIME)="data">
                  {{ FunctionDateFormat(data.item.CPL_UPDATE_TIME) }}
                </template>
              </b-table>
            </div>
            <b-pagination
              v-model="CPLCurrentPage"
              :total-rows="CPLRows"
              :per-page="CPLPerPage"
              class="pt-3"
              aria-controls="my-table"
              align="center"
              first-number
              last-number
              limit="10"
            ></b-pagination>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-modal
        id="OOC-modal"
        content-class="shadow"
        size="md"
        title="資料上傳"
        ok-only
        ok-title="上傳"
        ok-variant="primary"
        no-stacking
        scrollable
        centered
        @ok="FunctionUpload"
        @close="FunctionCloseModel"
      >
        <b-form-group label="鄉鎮" v-if="tabItem === '原住民保留地清冊'">
          <b-form-select
            v-model="townSelected"
            :options="townOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="檔案">
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="請上傳檔案"
            accept=".xlsx"
          ></b-form-file>
        </b-form-group>
        <b-button block variant="success" @click="FunctionDemoFileDownload"
          >範例檔案下載</b-button
        >
      </b-modal>
      <!-- <AlertModal :alertModalObj="alertModalObj" @getData="init" />
      <DeleteModal :deleteModalObj="deleteModalObj" @getData="init" /> -->
    </div>
    <div
      class="text-center"
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
      "
      align-v="center"
      v-if="isLoad"
    >
      <b-row style="position: relative; top: 45vh">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner
          ><span
            style="
              font-size: 20px;
              color: #ffffff;
              font-weight: bold;
              padding-left: 20px;
            "
            >載入中，請稍後...</span
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
// import AlertModal from "../../components/AlertModal.vue";
// import DeleteModal from "../../components/DeleteModal.vue";
export default {
  components: {
    // AlertModal,
    // DeleteModal,
  },
  data() {
    return {
      isLoad: false,
      tabItem: "占用未結案件",
      search: null,
      page: "TestResult",
      breadcrumb: [
        {
          text: "資料管理",
          active: true,
        },
        {
          text: "林地管理",
        },
      ],
      OOCTownOptions: [
        "卑南",
        "延平",
        "太麻里",
        "台東市",
        "鹿野",
        "長濱",
        "金峰",
        "東河",
        "達仁",
        "關山",
        "大武",
        "綠島鄉",
        "成功",
      ],
      ARTownSelected: "大武鄉",
      OOCTownSelected: "卑南",
      OOCTableFields: [
        {
          key: "OOC_SERIAL_NUMBER",
          label: "序號",
          class: "text-center",
        },
        {
          key: "OOC_YEAR_NUMBER",
          label: "年度編號",
          class: "text-center",
        },
        {
          key: "OOC_COUNTY",
          label: "縣市",
          class: "text-center",
        },
        {
          key: "OOC_TOWN",
          label: "鄉鎮市區",
          class: "text-center",
        },
        {
          key: "OOC_PASSAGE",
          label: "段",
          class: "text-center",
        },
        {
          key: "OOC_SMALL_PARAGRAPH",
          label: "小段",
          class: "text-center",
        },
        {
          key: "OOC_PLACE_NUMBER",
          label: "地號",
          class: "text-center",
        },
        {
          key: "OOC_FULL_AREA",
          label: "全筆面積(平方公尺)",
          class: "text-center",
        },
        {
          key: "OOC_OCCUPIED_AREA",
          label: "被占用面積(平方公尺)",
          class: "text-center",
        },
        {
          key: "OOC_ABOVE_GROUND_BUILDING",
          label: "地上建物門牌",
          class: "text-center",
        },
        {
          key: "OOC_OCCUPIED_VALUE",
          label: "被占用價值(元)",
          class: "text-center",
        },
        {
          key: "OOC_SOBS_BUILD_NUMBER",
          label: "建號",
          class: "text-center",
        },
        {
          key: "OOC_SOBS_HOUSE_NUMBER",
          label: "門牌",
          class: "text-center",
        },
        {
          key: "OOC_SOBS_FULL_AREA",
          label: "全筆面積(平方公尺)",
          class: "text-center",
        },
        {
          key: "OOC_SOBS_OCCUPIED_AREA",
          label: "被占用面積(平方公尺)",
          class: "text-center",
        },
        {
          key: "OOC_SOBS_OCCUPIED_VALUE",
          label: "被占用價值(元)",
          class: "text-center",
        },
        {
          key: "OOC_OCCUPIER_CATEGORY",
          label: "類別",
          class: "text-center",
        },
        {
          key: "OOC_OCCUPIER_APPEARANCE",
          label: "態樣",
          class: "text-center",
        },
        {
          key: "OOC_OCCUPIER_NAME",
          label: "姓名/名稱",
          class: "text-center",
        },
        {
          key: "OOC_CASES_NOT_YET_CLOSED",
          label: "尚未結案處理情形(填代號)",
          class: "text-center",
        },
        {
          key: "OOC_CIRCUMSTANCES_OF_USING",
          label: "使用補償金額收取情形(填代號)",
          class: "text-center",
        },
        {
          key: "OOC_CASE_CLOSED",
          label: "已結案情形(填代號)",
          class: "text-center",
        },
        {
          key: "OOC_TWD97_X",
          label: "X",
          class: "text-center",
        },
        {
          key: "OOC_TWD97_Y",
          label: "Y",
          class: "text-center",
        },
        {
          key: "OOC_WOODLAND_ZONING",
          label: "林地分區",
          class: "text-center",
        },
        {
          key: "OOC_OCCUPY_TIME_POINT",
          label: "占用時間點",
          class: "text-center",
        },
        {
          key: "OOC_REMARK",
          label: "備註",
          class: "text-center",
        },
        {
          key: "OOC_CREATE_TIME",
          label: "資料建立時間",
          class: "text-center",
        },
        {
          key: "OOC_UPDATE_TIME",
          label: "資料最後更新時間",
          class: "text-center",
        },
      ],
      OOCTableItems: [],
      OOCPerPage: 10,
      OOCCurrentPage: 1,
      OOCRows: 0,
      ARTableFields: [
        {
          key: "AR_TOWN",
          label: "鄉鎮",
        },
        {
          key: "AR_LANDMARKING",
          label: "土地標示",
        },
        {
          key: "AR_AREA",
          label: "面積(平方公尺)",
        },
        {
          key: "AR_USEPARTITION",
          label: "使用分區/使用類別",
        },
        // {
        //   key: "AR_OWNER",
        //   label: "所有權人",
        // },
        {
          key: "AR_LANDPRICE",
          label: "公告地價/公告現值(元/平方公尺)",
        },
        {
          key: "AR_REGISTRATIONREASON",
          label: "土地登記原因",
        },
        // {
        //   key: "AR_RIGHTHOLDER",
        //   label: "他項權利人",
        // },
        {
          key: "AR_RIGHTAREA",
          label: "權力面積",
        },
        {
          key: "AR_REGISTRATIONDATE",
          label: "登記日期",
        },
        // {
        //   key: "AR_HIRER",
        //   label: "租用人",
        // },
        // {
        //   key: "AR_RENTEDAREA",
        //   label: "租用面積",
        // },
        // {
        //   key: "AR_RENTALCATEGORY",
        //   label: "租用類別",
        // },
        // {
        //   key: "AR_DATERANGE",
        //   label: "開始日-到期日",
        // },
        {
          key: "AR_LANDCATEGORY",
          label: "土地類型",
        },
        {
          key: "show_details",
          label: "詳細資料",
        },
        {
          key: "AR_CREATETIME",
          label: "資料上傳日期",
        },
        {
          key: "AR_UPDATETIME",
          label: "資料最後更新日期",
        },
      ],
      ARTableItems: [],
      ARPerPage: 10,
      ARCurrentPage: 1,
      ARRows: 0,
      townSelected: "大武鄉",
      townOptions: [
        "大武鄉",
        "太麻里鄉",
        "台東市",
        "成功鎮",
        "卑南鄉",
        "東河鄉",
        "長濱鄉",
        "達仁鄉",
      ],
      CPLTownSelected: "",
      CPLtownOptions: [],
      CPLLotSelected: "",
      CPLLotOptions: [],
      CPLPlaceNumberSelected: "",
      CPLTableFields: [
        {
          key: "CPL_TOWN",
          label: "鄉鎮",
          class: "text-center",
        },
        {
          key: "CPL_LOT",
          label: "段",
          class: "text-center",
        },
        {
          key: "CPL_SMALL_SECTION",
          label: "小段",
          class: "text-center",
        },
        {
          key: "CPL_PLACE_NUMBER",
          label: "地號",
          class: "text-center",
        },
        {
          key: "CPL_AREA",
          label: "面積(平方公尺)",
          class: "text-center",
        },
        {
          key: "CPL_RANGE",
          label: "範圍",
          class: "text-center",
        },
        {
          key: "CPL_REMARK",
          label: "備註",
          class: "text-center",
        },
        {
          key: "CPL_CREATE_TIME",
          label: "資料上傳日期",
          class: "text-center",
        },
        {
          key: "CPL_UPDATE_TIME",
          label: "資料最後更新日期",
          class: "text-center",
        },
      ],
      CPLTableItems: [],
      CPLPerPage: 8,
      CPLCurrentPage: 1,
      CPLRows: 0,
      file: null,
      formData: new FormData(),
      pagePermission: null,
      apiImportOOCData: (data) =>
        this.userRequest.post("API/Default/ImportOOCData", data),
      apiGetOOCDataList: (data) =>
        this.userRequest.post("API/Default/GetOOCDataList", data),
      apiImportARData: (data) =>
        this.userRequest.post("API/Default/ImportARData", data),
      apiGetARDataList: (data) =>
        this.userRequest.post("API/Default/GetARDataList", data),
      apiImportCPLData: (data) =>
        this.userRequest.post("API/Default/ImportCPLData", data),
      apiGetCPLDataList: (data) =>
        this.userRequest.post("API/Default/GetCPLDataList", data),
      apiGetCPLDataInit: (data) =>
        this.userRequest.post("API/Default/GetCPLDataInit", data),
      apiGetMyGroupPermission: (data) =>
        this.userRequest.post("API/Default/GetMyGroupPermission", data),
    };
  },
  created() {
    this.isLoad = true;
    this.FunctionToken(this.GetMyGroupPermission, {});
    this.FunctionToken(this.GetOOCDataList, { town: this.OOCTownSelected });
  },
  methods: {
    ChangeTab(value) {
      this.tabItem = value;
      if (value === "原住民保留地清冊") {
        if (this.ARTableItems.length === 0) {
          this.isLoad = true;
          this.FunctionToken(this.GetARDataList, { town: this.ARTownSelected });
        }
      } else if (value === "海岸一定限度內不得為私人土地") {
        if (this.CPLTableItems.length === 0) {
          this.isLoad = true;
          this.FunctionToken(this.GetCPLDataInit, {});
        }
      }
    },
    FunctionSearch() {
      this.isLoad = true;
      if (this.tabItem === "占用未結案件") {
        this.FunctionToken(this.GetOOCDataList, {
          keyword: this.search,
          town: this.OOCTownSelected,
        });
      } else if (this.tabItem === "原住民保留地清冊") {
        this.FunctionToken(this.GetARDataList, {
          keyword: this.search,
          town: this.ARTownSelected,
        });
      } else if (this.tabItem === "海岸一定限度內不得為私人土地") {
        this.FunctionToken(this.GetCPLDataSearch, {
          town: this.CPLTownSelected,
          lot: this.CPLLotSelected,
          keyword: this.CPLPlaceNumberSelected,
        });
      }
    },
    FunctionUpload() {
      if (this.file !== null) {
        this.isLoad = true;
        this.formData.append(this.file.name, this.file);
        if (this.tabItem === "占用未結案件") {
          this.FunctionToken(this.ImportOOCData, {});
        } else if (this.tabItem === "原住民保留地清冊") {
          this.formData.append("Town", this.townSelected);
          this.FunctionToken(this.ImportARData, {});
        } else if (this.tabItem === "海岸一定限度內不得為私人土地") {
          this.FunctionToken(this.ImportCPLData, {});
        }
      } else {
        alert("請先選擇檔案");
      }
    },
    FunctionCloseModel() {
      this.file = null;
      this.formData = new FormData();
      this.isLoad = false;
    },
    FunctionDateFormat(value) {
      if (value !== undefined && value !== null && value != "") {
        const date = new Date(value);
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1).toString() +
          "-" +
          date.getDate()
        );
      } else {
        return "";
      }
    },
    FunctionOpenImport() {
      this.FunctionCloseModel();
      this.$root.$emit("bv::show::modal", "OOC-modal", "#btnShow");
    },
    FunctionDemoFileDownload() {
      if (this.tabItem === "占用未結案件") {
        this.$axios({
          url: "Upload/台東-占用未結案件.xlsx",
          method: "GET",
          responseType: "blob",
        }).then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          docUrl.setAttribute("download", "台東-占用未結案件.xlsx");
          document.body.appendChild(docUrl);
          docUrl.click();
        });
      } else if (this.tabItem === "原住民保留地清冊") {
        this.$axios({
          url: "Upload/原住民保留地清冊.xlsx",
          method: "GET",
          responseType: "blob",
        }).then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          docUrl.setAttribute("download", "原住民保留地清冊.xlsx");
          document.body.appendChild(docUrl);
          docUrl.click();
        });
      }
    },
    CPLtownChange() {
      this.isLoad = true;
      this.FunctionToken(this.GetCPLDataChange, { town: this.CPLTownSelected });
    },
    OOCPrevPage() {
      console.log("上一頁");
      if (this.OOCCurrentPage > 10) {
        this.OOCCurrentPage -= 10;
      }
    },
    OOCNextPage() {
      console.log("下一頁");
      let OOCTotalPage = Math.ceil(this.OOCRows / this.OOCPerPage);
      if (this.OOCCurrentPage + 10 < OOCTotalPage) {
        this.OOCCurrentPage += 10;
      }
    },
    // ============================== api ==============================
    ImportOOCData(data) {
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      this.apiImportOOCData(this.formData)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.OOCTableItems = jsonData;
            this.OOCRows = this.OOCTableItems.length;
            this.FunctionCloseModel();
          } else {
            alert(json.Message + "：" + json.Data);
            this.FunctionCloseModel();
          }
        })
        .catch((err) => {
          console.log(err);
          this.FunctionCloseModel();
        });
    },
    GetOOCDataList(data) {
      this.apiGetOOCDataList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.OOCTableItems = jsonData;
            this.OOCRows = this.OOCTableItems.length;
            this.isLoad = false;
          } else {
            alert(json.Message + "：" + json.Data);
            this.isLoad = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoad = false;
        });
    },
    ImportARData(data) {
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      this.apiImportARData(this.formData)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.ARTableItems = jsonData;
            this.ARRows = this.ARTableItems.length;
            this.FunctionCloseModel();
          } else {
            alert(json.Message + "：" + json.Data);
            this.FunctionCloseModel();
          }
        })
        .catch((err) => {
          console.log(err);
          this.FunctionCloseModel();
        });
    },
    GetARDataList(data) {
      this.apiGetARDataList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.ARTableItems = jsonData;
            this.ARRows = this.ARTableItems.length;
            this.isLoad = false;
          } else {
            alert(json.Message + "：" + json.Data);
            this.isLoad = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoad = false;
        });
    },
    ImportCPLData(data) {
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      this.apiImportCPLData(this.formData)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            //const jsonData = JSON.parse(json.Data);
            //console.log(jsonData);
            this.FunctionToken(this.GetCPLDataInit, {});
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetCPLDataInit(data) {
      this.apiGetCPLDataInit(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            console.log(jsonData);
            this.CPLtownOptions = jsonData.TownOptions;
            this.CPLtownOptions.unshift({ value: "", text: "請選擇" });
            this.CPLLotOptions = [{ value: "", text: "請選擇" }];
            this.CPLTableItems = jsonData.TableList;
            this.CPLRows = jsonData.TableList.length;
            this.isLoad = false;
          } else {
            this.isLoad = false;
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          this.isLoad = false;
          console.log(err);
        });
    },
    GetCPLDataChange(data) {
      this.apiGetCPLDataInit(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            console.log(jsonData);
            this.CPLLotOptions = jsonData.LotOptions;
            this.CPLLotOptions.unshift({ value: "", text: "請選擇" });
            this.CPLTableItems = jsonData.TableList;
            this.CPLRows = jsonData.TableList.length;
            this.isLoad = false;
          } else {
            this.isLoad = false;
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          this.isLoad = false;
          console.log(err);
        });
    },
    GetCPLDataSearch(data) {
      this.apiGetCPLDataInit(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            console.log(jsonData);
            this.CPLTableItems = jsonData.TableList;
            this.CPLRows = jsonData.TableList.length;
            this.isLoad = false;
          } else {
            this.isLoad = false;
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          this.isLoad = false;
          console.log(err);
        });
    },
    GetCPLDataList(data) {
      this.apiGetCPLDataList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            // const jsonData = JSON.parse(json.Data);
            this.isLoad = true;
            this.FunctionToken(this.GetCPLDataInit, {});
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetMyGroupPermission(data) {
      this.apiGetMyGroupPermission(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.pagePermission = jsonData;
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
