<template>
  <div>
    <b-form-input
      type="color"
      style="width: 40px"
      v-model="myColor"
      @change="updateColor"
    />
    <div class="form-inline mt-2">
      <div
        style="width: 12px; height: 12px; background-color: rgb(192, 0, 0)"
        @click="updateColor('#c00000')"
      ></div>
      <div
        style="width: 12px; height: 12px; background-color: rgb(255, 0, 0)"
        @click="updateColor('#ff0000')"
      ></div>
      <div
        style="width: 12px; height: 12px; background-color: rgb(255, 192, 0)"
        @click="updateColor('#ffc000')"
      ></div>
      <div
        style="width: 12px; height: 12px; background-color: rgb(255, 255, 0)"
        @click="updateColor('#ffff00')"
      ></div>
      <div
        style="width: 12px; height: 12px; background-color: rgb(146, 208, 80)"
        @click="updateColor('#92d050')"
      ></div>
      <div
        style="width: 12px; height: 12px; background-color: rgb(0, 176, 80)"
        @click="updateColor('#00b050')"
      ></div>
      <div
        style="width: 12px; height: 12px; background-color: rgb(0, 176, 240)"
        @click="updateColor('#00b0f0')"
      ></div>
      <div
        style="width: 12px; height: 12px; background-color: rgb(0, 112, 192)"
        @click="updateColor('#0070c0')"
      ></div>
      <div
        style="width: 12px; height: 12px; background-color: rgb(0, 32, 96)"
        @click="updateColor('#002060')"
      ></div>
      <div
        style="width: 12px; height: 12px; background-color: rgb(112, 48, 160)"
        @click="updateColor('#7030a0')"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["myColor", "arrIndex", "returnMethod"],
  data() {
    return {
      title: "我的自製 Component",
      color: "",
    };
  },
  methods: {
    updateColor(value) {
      this.$emit("ChildUpdateColor", value, this.arrIndex);
    },
  },
};
</script>
