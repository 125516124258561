<template>
  <div id="pdfDOM" class="mainMap" style="height: 100%">
    <div id="map" class="map"></div>
    <div v-if="img != null">
      <b-img
        :src="img"
        alt="Responsive image"
        style="position: absolute; top: 0; left: 0"
      ></b-img>
    </div>
    <div class="MainsideBar">
      <b-button-group vertical class="MainsideBarBtn">
        <b-button v-b-tooltip.hover.left title="圖層列表">
          <b-icon icon="brush"></b-icon>
        </b-button>
      </b-button-group>
      <div class="MainsideBarContent show">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="匯出功能" active>
              <b-button @click="setPosition">定位</b-button>
              <b-row>
                <b-col>
                  <b-form-group label="format">
                    <b-form-select
                      :options="formatOption"
                      v-model="formatSelected"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="resolution">
                    <b-form-select
                      :options="resolutionOption"
                      v-model="resolutionSelected"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="scale">
                    <b-form-select
                      :options="scaleOption"
                      v-model="scaleSelected"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-3 text-right">
                <b-col>
                  <b-button variant="primary" @click="FunctionPrintPdf"
                    >Export PDF</b-button
                  >
                </b-col>
              </b-row>

              <b-form-checkbox
                v-model="iswfs"
                switch
                @change="FunctionImageLayer"
              ></b-form-checkbox>
            </b-tab>
            <b-tab title="圖層">
              <b-button variant="dark" @click="FunctionAdd">加入</b-button>
              <div v-for="(item, i) in MapLayerSelect" :key="i">
                <div class="layer-item-group">
                  <div class="layer-item-list-collapse">
                    <div>
                      <div v-if="item.collapseExpend">
                        <div class="layer-item-list-collapse-title">
                          <div>{{ item.title }}</div>
                          <b-icon
                            icon="caret-down-fill"
                            aria-hidden="true"
                            @click="changeCollapseExpend(item)"
                          ></b-icon>
                        </div>
                        <div class="layer-item-list-collapse-main">
                          <div class="d-flex justify-content-between">
                            <b-button
                              v-if="item.layerVisible"
                              @click="item.layerVisible = false"
                            >
                              <b-icon
                                icon="eye-fill"
                                aria-hidden="true"
                              ></b-icon>
                            </b-button>
                            <b-button
                              v-else
                              @click="item.layerVisible = true"
                              class=""
                            >
                              <b-icon
                                icon="eye-slash-fill"
                                aria-hidden="true"
                              ></b-icon>
                            </b-button>
                            <b-button class="btn-group-border">
                              <div
                                class="color-setting"
                                :style="{
                                  'background-color': item.layerColor,
                                }"
                              ></div>
                              <!-- <b-icon
                                  icon="brush-fill"
                                  aria-hidden="true"
                                ></b-icon> -->
                            </b-button>
                            <b-button
                              v-if="item.layerType != 'WMS'"
                              @click="FunctionLayerSetPosition(item)"
                            >
                              <b-icon
                                icon="geo-alt"
                                aria-hidden="true"
                              ></b-icon>
                            </b-button>
                            <b-button
                              ><b-icon
                                stacked
                                icon="x-circle-fill"
                                @click="deleteLayerList(item)"
                              ></b-icon
                            ></b-button>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="layer-item-list-collapse-title">
                          <div>{{ item.title }}</div>
                          <b-icon
                            icon="caret-up-fill"
                            aria-hidden="true"
                            @click="changeCollapseExpend(item)"
                          ></b-icon>
                        </div>

                        <div class="layer-item-list-collapse-main">
                          <b-row>
                            <b-col cols="10" class="text-left">
                              <b-form-checkbox
                                v-model="item.layerVisible"
                                switch
                                @change="mapSetVisible(item)"
                                >是否顯示
                              </b-form-checkbox>
                            </b-col>
                            <b-col cols="2" class="text-right">
                              <b-icon
                                stacked
                                icon="x-circle-fill"
                                @click="deleteLayerList(item)"
                              ></b-icon>
                            </b-col>
                          </b-row>
                          <b-row class="mt-3">
                            <b-col cols="4" class="text-right">透明度</b-col>
                            <b-col cols="8"
                              ><input
                                type="range"
                                min="0"
                                max="100"
                                step="1"
                                @change="mapSetOpacity(item)"
                                v-model="item.rangeValue"
                              />
                            </b-col>
                          </b-row>
                          <b-row
                            class="mt-3"
                            v-if="
                              item.styleType.toLowerCase() !== 'image' ||
                              item.layerType != 'WMS'
                            "
                          >
                            <b-col cols="4" class="text-right">顏色設定</b-col>
                            <b-col cols="8"
                              ><div>
                                <ColorPicker
                                  :myColor="item.layerColor"
                                  :arrIndex="i"
                                  @ChildUpdateColor="FunctionColorPick"
                                />
                              </div>
                            </b-col>
                          </b-row>
                          <b-row
                            class="mt-3"
                            align-v="center"
                            v-if="item.styleType.toLowerCase() == 'point'"
                          >
                            <b-col cols="4">點位大小</b-col>
                            <b-col cols="8"
                              ><div>
                                <b-form-spinbutton
                                  min="1"
                                  max="100"
                                  v-model="item.pointRadius"
                                  @change="mapSetStyle(item)"
                                ></b-form-spinbutton>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row class="mt-3" align-v="center">
                            <b-col>
                              <b-button
                                variant="primary"
                                block
                                v-if="item.layerType != 'WMS'"
                                @click="FunctionLayerSetPosition(item)"
                                ><b-icon
                                  icon="geo-alt"
                                  aria-hidden="true"
                                ></b-icon>
                                <span>定位</span></b-button
                              >
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <b-modal
      id="modal-featureInfo"
      :title="'圖層屬性'"
      ref="modal-featureInfo"
      ok-only
    >
      <b-row
        v-for="(item, index) in featureInfoItems"
        :key="index"
        class="p-1 border-bottom"
      >
        <b-col class="text-center" cols="4">{{ item.title }}</b-col>
        <b-col cols="8">{{ item.text }}</b-col>
      </b-row>
      <!-- <div v-html="WMShtml"></div> -->
    </b-modal>
  </div>
</template>
<script src="https://unpkg.com/elm-pep@1.0.6/dist/elm-pep.js"></script>
<script>
import ColorPicker from "../components/colorPicker.vue";

import "ol/ol.css";
import Map from "ol/Map";
import { OSM, TileWMS, XYZ, TileWMTS } from "ol/source";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import { ScaleLine, defaults as defaultControls } from "ol/control";
import html2Canvas from "html2canvas";
// import $ from "jquery";
// import { bbox as bboxStrategy } from "ol/loadingstrategy";
import GeoJSON from "ol/format/GeoJSON";
import KML from "ol/format/KML";
// import WFS from "ol/format/WFS";
// import GML from "ol/format/GML";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import GeoTIFF from "ol/source/GeoTIFF";
//import Draw from "ol/interaction/Draw";
import fromLonLat from "ol/proj";
import Circle from "ol/geom";
import Feature from "ol/Feature";
import { Style, Fill, Stroke } from "ol/style";
import Tiff from "tiff.js/tiff";

import TileGrid from "ol/tilegrid/TileGrid";
import createXYZ from "ol/tilegrid";
import { get as getProjection, getPointResolution } from "ol/proj";

export default {
  name: "MapTest",
  components: {
    ColorPicker,
  },
  data() {
    return {
      view: null,
      map: null,
      MapLayers: {
        layersIndex: 0,
        layersMane: ["OSM"],
        layers: [
          new TileLayer({
            visible: true,
            source: new OSM({ wrapX: false }),
          }),
        ],
        vectorSource: null,
      },
      dims: {
        a0: [1189, 841],
        a1: [841, 594],
        a2: [594, 420],
        a3: [420, 297],
        a4: [297, 210],
        a5: [210, 148],
      },
      formatOption: [
        {
          value: "a0",
          text: "A0 (slow)",
        },
        {
          value: "a1",
          text: "A1",
        },
        {
          value: "a2",
          text: "A2",
        },
        {
          value: "a3",
          text: "A3",
        },
        {
          value: "a4",
          text: "A4",
        },
        {
          value: "a5",
          text: "A5 (fast)",
        },
      ],
      formatSelected: "a4",
      resolutionOption: [
        {
          value: "72",
          text: "72 dpi (fast)",
        },
        {
          value: "150",
          text: "150 dpi",
        },
        {
          value: "200",
          text: "200 dpi",
        },
        {
          value: "300",
          text: "300 dpi (slow)",
        },
      ],
      resolutionSelected: "200",
      scaleOption: [
        {
          value: "500",
          text: "1:500000",
        },
        {
          value: "250",
          text: "1:250000",
        },
        {
          value: "100",
          text: "1:100000",
        },
        {
          value: "50",
          text: "1:50000",
        },
        {
          value: "25",
          text: "1:25000",
        },
        {
          value: "10",
          text: "1:10000",
        },
      ],
      scaleSelected: "250",
      exportOptions: {
        useCORS: true,
        allowTaint: true,
        foreignObjectRendering: true,
        ignoreElements: function (element) {
          const className = element.className || "";
          return (
            className.toString().includes("ol-control") &&
            !className.toString().includes("ol-scale") &&
            (!className.toString().includes("ol-attribution") ||
              !className.toString().includes("ol-uncollapsible"))
          );
        },
      },
      scaleLine: new ScaleLine({ bar: true, text: true, minWidth: 125 }),
      img: null,
      iswfs: true,
      wfsLayer: null,
      featureInfoItems: [],
      WMShtml: "",
      MapLayerSelect: [
        {
          ML_ID: "ML000001",
          checkAll: false,
          collapseExpend: false,
          collapseExpendTitle: "展開",
          data: null,
          expend: false,
          layerColor: "#83BF8D",
          layerType: "GEOJSON",
          layerVisible: true,
          params: null,
          parentTitle: "2501",
          pointRadius: 10,
          propertyList: null,
          rangeValue: 50,
          styleType: "polygon",
          title: "952501",
          url: "update/2501/952501.geojson",
          x: null,
          y: null,
        },
      ],
      wmtsLayer: null,
    };
  },
  mounted() {
    this.initMap();
    // this.FunctionWFSLayer();
    // this.FunctionKMLLayer();
  },
  methods: {
    initMap() {
      this.view = new View({
        // center: [120.7899816353624, 22.9110480137275],
        center: [120.7899816353624, 22.9110480137275],
        zoom: 12,
        projection: "EPSG:4326",
      });

      this.map = new Map({
        layers: [
          new TileLayer({
            visible: true,
            source: new XYZ({
              url: "https://wmts.nlsc.gov.tw/wmts/EMAP5/default/EPSG:3857/{z}/{y}/{x}",
              wrapX: false,
            }),
          }),
        ],
        target: "map",
        view: this.view,
        controls: defaultControls({
          attributionOptions: { collapsible: false },
        }),
      });

      this.map.on("singleclick", this.MapLayerClick);

      this.map.on("loadend", function () {
        console.log("Mloadend");
      });

      this.map.addControl(this.scaleLine);

      this.FunctionWMTSLayer();
    },
    FunctionPrintPdf() {
      const map = this.map;
      const format = this.formatSelected;
      const resolution = this.resolutionSelected;
      const dim = this.dims[format];
      const width = Math.round((dim[0] * resolution) / 25.4);
      const height = Math.round((dim[1] * resolution) / 25.4);
      const target = this;
      target.exportOptions.width = width;
      target.exportOptions.height = height;
      html2Canvas(map.getViewport(), target.exportOptions).then(function (
        canvas
      ) {
        target.img = canvas.toDataURL("image/jpeg");
      });
    },
    FunctionWMTSLayer() {
      this.wmtsLayer = new TileLayer({
        visible: true,
        source: new XYZ({
          url: "https://landmaps.nlsc.gov.tw/S_Maps/wmts/DMAPS/default/EPSG:3857/{z}/{y}/{x}",
          wrapX: false,
          crossOrigin: "anonymous",
        }),
      });

      this.map.addLayer(this.wmtsLayer);
    },
    FunctionWFSLayer() {
      if (this.iswfs) {
        //"https://gis.forest.gov.tw/arcgis/services/RE/RE/MapServer/WFSServer?request=GetCapabilities&service=WFS&token=sZ61I2o6raXObsh27Yp_IIG0yae_ymuEzsnLaQhIZ8XrwtyFHaY9u0BKoZlq-HMw&outputFormat=GEOJSON",
        // const vSource = new VectorSource({
        //   format: new GeoJSON(),
        //   url: "Upload\\臺東縣地籍110.10.geojson",
        //   //strategy: bboxStrategy,
        // });
        // vSource.on("featuresloadend", function () {
        //   console.log("featuresloadend");
        // });

        // const vector = new VectorLayer({
        //   source: vSource,
        // });
        // this.map.addLayer(vector);
        // this.wfsLayer = vector;

        // const WMSsource = new TileWMS({
        //   url: "geoserver/geoserver/forest/wms",
        //   params: {
        //     format: "image/png",
        //     version: "1.1.1",
        //     STYLES: "",
        //     LAYERS: "forest:臺東縣地籍圖",
        //     exceptions: "application/vnd.ogc.se_inimage",
        //     // bbox: "119.99001907,20.40707513,122.11192622,26.71960256",
        //     // srs:"EPSG:4326",
        //   },
        //   serverType: "geoserver",
        //   overlaps: true,
        //   crossOrigin: "anonymous",
        // });
        console.log("init wms layer");
        const WMSlayer = new TileLayer({
          title: "臺東縣地籍圖",
          visible: true,
          opacity: 1,
          source: new TileWMS({
            url: "https://landmaps.nlsc.gov.tw/landmaps/wms",
            params: {
              SERVICE: "WMS",
              VERSION: "1.1.1",
              REQUEST: "GetMap",
              BBOX: "120.64369482202913275,24.06525451933858051,120.69891877452353413,24.09601623808858051",
              SRS: "EPSG:4326",
              WIDTH: "1202",
              HEIGHT: "669",
              LAYERS: "DMAPS",
              FORMAT: "image/png",
              DPI: "96",
              MAP_RESOLUTION: "96",
              FORMAT_OPTIONS: "dpi:96",
              TRANSPARENT: "TRUE",
            },
            serverType: "geoserver",
            overlaps: true,
            crossOrigin: "anonymous",
          }),
        });
        this.map.addLayer(WMSlayer);
        // this.wfsLayer = WMSlayer;

        // const target = this;
        // this.wfsLayer = new VectorLayer({
        //   source: new VectorSource({
        //     loader: function () {
        //       $.ajax(
        //         "https://gis.forest.gov.tw/arcgis/services/RE/RE/MapServer/WFSServer",
        //         {
        //           type: "GET",
        //           data: {
        //             request: "GetCapabilities",
        //             service: "WFS",
        //             token:
        //               "sZ61I2o6raXObsh27Yp_IIG0yae_ymuEzsnLaQhIZ8XrwtyFHaY9u0BKoZlq-HMw",
        //           },
        //         }
        //       ).done(function (response) {
        //         target.wfsLayer
        //           .getSource()
        //           .addFeatures(new WFS().readFeatures(response));
        //       });
        //     },
        //     strategy: bboxStrategy,
        //     projection: "EPSG:4326",
        //   }),
        // });
        // this.wfsLayer = new TileLayer({
        //   source: new XYZ({
        //     url: "https://gis.forest.gov.tw/arcgis/services/FH/地籍圖_cache/MapServer/WFSServer?request=GetCapabilities&service=WFS&token=sZ61I2o6raXObsh27Yp_IIG0yae_ymuEzsnLaQhIZ8XrwtyFHaY9u0BKoZlq-HMw",
        //     crossOrigin: "*",
        //   }),
        //   visible: true,
        // });
        // var formatWFS = new WFS();

        // var formatGML = new GML({
        //   featureNS: "http://www.opengis.net/wfs/2.0",
        //   srsName: "EPSG:3826",
        // });
        // var s = new XMLSerializer();
        // var sourceWFS = new VectorSource({
        //   loader: function (extent) {
        //     $.ajax(
        //       "https://gis.forest.gov.tw/arcgis/services/RE/RE/MapServer/WFSServer",
        //       {
        //         type: "GET",
        //         data: {
        //           service: "WFS",
        //           version: "2.0.0",
        //           request: "GetCapabilities",
        //           srsname: "EPSG:3826",
        //           bbox: extent.join(",") + ",EPSG:3857",
        //           token:
        //             "sZ61I2o6raXObsh27Yp_IIG0yae_ymuEzsnLaQhIZ8XrwtyFHaY9u0BKoZlq-HMw",
        //         },
        //       }
        //     ).done(function (response) {
        //       sourceWFS.addFeatures(formatWFS.readFeatures(response));
        //     });
        //   },
        //   crossOrigin: "*",
        //   strategy: bboxStrategy,
        //   projection: "EPSG:4326",
        // });
        // this.wfsLayer = new VectorLayer({
        //   source: sourceWFS,
        // });

        // this.map.addLayer(this.wfsLayer);

        // var interaction = new Draw({
        //   type: "Polygon",
        //   source: this.wfsLayer.getSource(),
        // });

        // this.map.addInteraction(interaction);
        // interaction.on("drawend", function (e) {
        //   $.ajax(
        //     "https://gis.forest.gov.tw/arcgis/services/RE/RE/MapServer/WFSServer?request=GetCapabilities&service=WFS&token=sZ61I2o6raXObsh27Yp_IIG0yae_ymuEzsnLaQhIZ8XrwtyFHaY9u0BKoZlq-HMw",
        //     {
        //       type: "POST",
        //       dataType: "xml",
        //       contentType: "text/xml",
        //       data: s.serializeToString(
        //         formatWFS.writeTransaction([e.feature], null, null, formatGML)
        //       ),
        //     }
        //   ).done();
        // });
      } else {
        this.map.removeLayer(this.wfsLayer);
        this.wfsLayer = null;
      }
    },
    FunctionKMLLayer() {
      var newLayer = new VectorLayer({
        source: new VectorSource({
          url: "Upload/TTDEM/Haiduan/Haiduan.kml",
          format: new KML(),
        }),
      });
      this.map.addLayer(newLayer);
    },
    FunctionImageLayer() {
      var newLayer = new ImageLayer({
        source: new Static({
          url: "Upload/TTDEM/DEM.png",
          projection: "EPSG:4326",
          imageExtent: [
            120.7899816353624, 22.9110480137275, 121.2515202958719,
            23.33623469014329,
          ],
        }),
        visible: true,
      });
      this.map.addLayer(newLayer);
    },
    FunctionTIFFLayer() {
      // var newLayer = new ImageLayer({
      //   source: new Static({
      //     url: 'Upload/fwi_2023-11-16.tif',
      //     imageExtent: [119.2, 21.68, 122.3, 25.7],
      //     imageLoadFunction: function (image, src) {
      //       var xhr = new XMLHttpRequest();
      //       xhr.responseType = 'arraybuffer';
      //       xhr.open('GET', src);
      //       xhr.onload = function (e) {
      //         var tiff = new Tiff({buffer: xhr.response});
      //         var canvas = tiff.toCanvas();
      //         image.getImage().src = canvas.toDataURL();
      //       };
      //       xhr.send();
      //     },
      //   }),
      // });
      var newLayer = new ImageLayer({
        source: new Static({
          url: "Upload/fwi_2023-11-16.tif",
          imageExtent: [119.2, 21.68, 122.3, 25.7],
          imageLoadFunction: function (image, src) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "arraybuffer";
            xhr.open("GET", src);
            xhr.onload = function (e) {
              var tiff = new Tiff({ buffer: xhr.response });
              var canvas = tiff.toCanvas();
              var context = canvas.getContext("2d");

              // Remove white background
              var imageData = context.getImageData(
                0,
                0,
                canvas.width,
                canvas.height
              );
              var data = imageData.data;
              for (var i = 0; i < data.length; i += 4) {
                // Check if the pixel is white (you may need to adjust the threshold)
                if (
                  data[i] === 255 &&
                  data[i + 1] === 255 &&
                  data[i + 2] === 255
                ) {
                  // Set the alpha channel to 0 to make it transparent
                  data[i + 3] = 0;
                }
              }

              // Put the modified image data back onto the canvas
              context.putImageData(imageData, 0, 0);

              image.getImage().src = canvas.toDataURL();
            };
            xhr.send();
          },
        }),
      });

      newLayer.setMinResolution(0.00000104858);
      newLayer.setMaxResolution(0.00000524293);
      this.map.addLayer(newLayer);
    },
    FunctionTileLayer() {
      var newLayer = new TileLayer({
        source: new XYZ({
          url: "https://storage.geodac.tw/Tile/v2/RSImage/Satellite/Sentinel2/20231121_121004092_023449029_07_000_RSImage_Satellite_Sentinel2/{z}/{y}/{x}.jpg",
          projection: "EPSG:3857",
        }),
      });
      this.map.addLayer(newLayer);
    },
    MapLayerClick(evt) {
      this.featureInfoItems = [];
      if (this.wfsLayer) {
        const url = this.wfsLayer
          .getSource()
          .getFeatureInfoUrl(
            evt.coordinate,
            this.view.getResolution(),
            this.view.getProjection(),
            { INFO_FORMAT: "application/json", FEATURE_COUNT: 50 }
          );
        const target = this;
        if (url) {
          fetch(url)
            .then((response) => response.json())
            .then((json) => {
              if (json.features.length > 0) {
                Object.keys(json.features[0].properties).forEach((k) => {
                  target.featureInfoItems.push({
                    title: k,
                    newTitle: k,
                    text: json.features[0].properties[k],
                  });
                });
                target.$refs["modal-featureInfo"].show();
              }
            });
        }
      }
    },
    createCircle() {
      // 创建一个圆心坐标
      var center = fromLonLat([121.15270265845061, 22.751375694439577]);
      var circle = new Circle(center, 10000); // 使用圆心和半径创建圆，这里的半径单位是米
      var circleFeature = new Feature(circle);
      var vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [circleFeature], // 将要素添加到图层
        }),
        style: new Style({
          stroke: new Stroke({
            color: "blue", // 边框颜色
            width: 2, // 边框宽度
          }),
          fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)", // 填充颜色，这里设置为半透明的蓝色
          }),
        }),
      });
      this.map.addLayer(vectorLayer);
    },
    changeCollapseExpend(value) {
      value.collapseExpend = !value.collapseExpend;
      value.collapseExpendTitle = value.collapseExpend ? "收合" : "展開";
      this.$root.$emit("bv::toggle::collapse", value.ML_ID);
    },
    mapSetVisible(value) {
      console.log(value);
    },
    deleteLayerList(value) {
      console.log(value);
    },
    mapSetOpacity(value) {
      console.log(value);
    },
    mapSetStyle(value) {
      console.log(value);
    },
    FunctionLayerSetPosition(value) {
      console.log(value);
    },
    setPosition() {
      console.log(this.wmtsLayer.getExtent());
    },
    FunctionAdd() {
      const mlid = "ML00000" + (this.MapLayerSelect.length + 1).toString();
      this.MapLayerSelect.unshift({
        ML_ID: mlid,
        checkAll: false,
        collapseExpend: false,
        collapseExpendTitle: "展開",
        data: null,
        expend: false,
        layerColor: "#eeeeee",
        layerType: "GEOJSON",
        layerVisible: true,
        params: null,
        parentTitle: "2501",
        pointRadius: 10,
        propertyList: null,
        rangeValue: 50,
        styleType: "polygon",
        title: "952502",
        url: "update/2501/952501.geojson",
        x: null,
        y: null,
      });
      console.log(this.MapLayerSelect);
    },
    FunctionColorPick(value, arrIndex) {
      this.MapLayerSelect[arrIndex].layerColor = value;
    },
  },
  watch: {
    img() {
      if (this.img != null) {
        const format = this.formatSelected;
        const dim = this.dims[format];
        this.getPdf("截圖", this.exportOptions, dim);
        this.img = null;
      }
    },
  },
};
</script>
<style scoped>
#OLmap,
.map {
  height: 100%;
  width: 100%;
}
</style>
