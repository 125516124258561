<template>
  <b-modal
    id="ForgetPasswordModal"
    no-close-on-backdrop
    title="忘記密碼"
    ok-title="確定"
    ok-only
    @ok="onSendData"
  >
    <p class="my-3">請輸入您的電子郵件來重設密碼。</p>
    <b-form>
      <b-form-group id="input-group-2">
        <b-form-input type="text" required placeholder="帳號" v-model="sendData.AC_USERID"> </b-form-input>
      </b-form-group>
      <b-form-group id="input-group-2">
        <b-form-input type="email" required placeholder="電子郵件" v-model="sendData.AC_EMAIL"> </b-form-input>
      </b-form-group>
    </b-form>
  </b-modal>
</template>
<script>
export default {
  name: "ForgetPasswordModal",
  data() {
    return {
      sendData: {
        AC_USERID: "",
        AC_EMAIL: "",
      },
      apiForgetPassword: (data) => this.userRequest.post("API/Default/ForgetPassword", data),
    };
  },
  mounted() {

  },
  methods: {
    onSendData() {
        this.FunctionToken(this.SendMail, this.sendData);
    },
    SendMail(data) {
      this.apiForgetPassword(data)
        .then((res) => {
          console.log(res);
          const json = JSON.parse(res.data);
          if (json.Status) {
            alert("信件已送出，請至信箱設定新密碼！");
          } else {
            alert(json.Message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
