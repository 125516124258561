import html2Canvas from 'html2canvas';
import JsPDF from 'jspdf';
export default {
    install(Vue, options) {
        Vue.prototype.getPdf = function (temp, exportOptions, dim, compass) {
            exportOptions.width = document.getElementById('pdfDOM').offsetWidth;
            exportOptions.height = document.getElementById('pdfDOM').offsetWidth * dim[1] / dim[0]; //document.getElementById('pdfDOM').offsetHeight;
            html2Canvas(document.getElementById('pdfDOM'), exportOptions).then(function (canvas) {
                // let contentWidth = canvas.width;
                // let contentHeight = canvas.height;
                // let pageHeight = contentWidth / 592.28 * 841.89;
                // let leftHeight = contentHeight;
                // let position = 0;
                // let imgWidth = 595.28;
                // let imgHeight = 592.28 / contentWidth * contentHeight;
                let pageData = canvas.toDataURL('image/jpeg');
                let PDF = new JsPDF('landscape', undefined, 'a4');
                PDF.addImage(pageData, "JPEG", 0, 0, dim[0], dim[1]);

                if (compass) {
                    var img = new Image();
                    img.src = "openlayer/image-removebg-preview.png";
                    PDF.addImage(img, 'png', 5, 90, 20, 20)
                }
                // if (leftHeight < pageHeight) {
                //     PDF.addImage(pageData, 'jpeg', 0, 0, imgWidth, imgHeight);
                // } else {
                //     while (leftHeight > 0) {
                //         PDF.addImage(pageData, 'jpeg', position, imgWidth.imgHeight);
                //         leftHeight -= pageHeight;
                //         position -= 841.89;
                //         if (leftHeight > 0) {
                //             PDF.addPage();
                //         }
                //     }
                // }

                PDF.save(temp + '.pdf');
                console.log(options);
                console.log(dim);
                console.log(exportOptions);
            });
        }

        Vue.prototype.getPdf2 = function (temp, dim, img, img2) {
            let PDF = new JsPDF('landscape', undefined, 'a4');
            PDF.addImage(img, "JPEG", 0, 0, dim[0], dim[1]);
            PDF.addImage(img2, "JPEG", 0, 0, dim[0], dim[1]);
            PDF.save(temp + '.pdf');
        }
    }
}