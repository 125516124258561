import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue';
import ForgetPassword from '../views/ForgetPassword.vue';
import DashboardLayout from '../components/Header.vue';
import Account from '../views/Account.vue';
import MyAccount from '../views/MyAccount.vue';
import AccountGroup from '../views/AccountGroup.vue';
import RevisionResult from '../views/DataManager/RevisionResult.vue';
import RevisionResultDetail from '../views/DataManager/RevisionResultDetail.vue';
import PlanResult from '../views/DataManager/PlanResult.vue';
import WLManage from '../views/DataManager/WLManage.vue';
import WFManage from '../views/DataManager/WFManage.vue';
import WLDEvaluate from '../views/DataManager/WLDEvaluate.vue';
import MapManager from '../views/MapManager.vue';
import Dashboard from '../views/Dashboard.vue';
import Map2D from '../views/Map2D.vue';
import MapTest from '../views/MapTest.vue';
import Logout from '../views/Logout.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    component: ForgetPassword
    ,
  },
  {
    path: '/',
    redirect: 'Login',
    component: DashboardLayout,
    children: [
      {
        path: 'Map2D',
        name: 'Map2D',
        component: Map2D,
      },
      {
        path: 'MapTest',
        name: 'MapTest',
        component: MapTest,
      },
      {
        path: 'RevisionResult',
        name: 'RevisionResult',
        component: RevisionResult,
      },
      {
        path: 'RevisionResultDetail',
        name: 'RevisionResultDetail',
        component: RevisionResultDetail,
      },
      {
        path: 'PlanResult',
        name: 'PlanResult',
        component: PlanResult,
      },
      {
        path: 'WLManage',
        name: 'WLManage',
        component: WLManage,
      },
      {
        path: 'WFManage',
        name: 'WFManage',
        component: WFManage,
      },
      {
        path: 'WLDEvaluate',
        name: 'WLDEvaluate',
        component: WLDEvaluate,
      },
      {
        path: 'MapManager',
        name: 'MapManager',
        component: MapManager,
      },

      {
        path: 'Account',
        name: 'Account',
        component: Account,
      },
      {
        path: 'MyAccount',
        name: 'MyAccount',
        component: MyAccount,
      },
      {
        path: 'AccountGroup',
        name: 'AccountGroup',
        component: AccountGroup,
      },
      {
        path: 'Dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'Logout',
        name: 'Logout',
        component: Logout,
      },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
