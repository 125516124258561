<template>
  <div class="page">
    <div class="page-content">
      <b-breadcrumb class="mb-0" :items="breadcrumb"></b-breadcrumb>
      <b-row no-gutters align-h="between" align-v="center" class="py-4">
        <b-col cols="auto">
          <div class="px-3">
            <b-form inline>
              <label class="mr-sm-2" for="inline-form-custom-select-pref"
                >關鍵字查詢</label
              >
              <b-form-input
                id="input-1"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="search"
                placeholder="請輸入關鍵字"
                required
                @keyup.enter="FunctionSearch"
              ></b-form-input>
              <b-button variant="dark" @click="FunctionSearch">查詢</b-button>
            </b-form>
          </div>
        </b-col>
      </b-row>
      <b-col>
        <div class="table-responsive content-box">
          <b-table
            class="table table-bordered"
            hover
            :items="items"
            :fields="Fields"
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
            empty-text="查無資料"
            tbody-tr-class="text-nowrap"
          >
            <template #cell(PF_LOCATION)="data">
              <p style="white-space: pre">{{ data.item.PF_LOCATION }}</p>
            </template>
            <template #cell(Detail)="data">
              <b-button
                variant="info"
                :to="'/RevisionResultDetail?PF_ID=' + data.item.PF_ID"
              >
                <b-icon icon="file-earmark-text"></b-icon> 查閱</b-button
              >
            </template>
            <template #cell(operate)="data">
              <b-button-group>
                <b-button
                  variant="success"
                  v-b-modal.forest-modal
                  @click="
                    forest.PF_ID = data.item.PF_ID;
                    forest.Name = data.item.PF_NAME;
                    forest.Type = data.item.PF_TYPE;
                    forest.Location = data.item.PF_LOCATION;
                    forest.Owner = data.item.PF_OWNER;
                    forest.Area = data.item.VD_CHECKEDAREA;
                    forest.Management = data.item.PF_MANAGEMENT;
                    forest.Note = data.item.PF_NOTE;
                  "
                >
                  <b-icon icon="pencil"></b-icon> 編輯</b-button
                >
              </b-button-group>
            </template>
          </b-table>
        </div>
        <div class="overflow-auto">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            class="pt-3"
            aria-controls="my-table"
            align="center"
            first-number
            last-number
            limit="10"
          ></b-pagination>
        </div>
      </b-col>

      <b-modal
        content-class="shadow"
        size="lg"
        id="forest-modal"
        title="編輯保安林檢訂成果"
        ok-only
        ok-title="更新"
        ok-variant="primary"
        no-stacking
        scrollable
        centered
        @ok="FunctionUpdate"
        @close="FunctionResetModal"
      >
        <b-form-group label="保安林編號">
          <b-form-input
            disabled
            type="text"
            v-model="forest.Name"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="*保安林種類">
          <b-form-input type="text" v-model="forest.Type"></b-form-input>
        </b-form-group>
        <b-form-group label="*所在地">
          <b-form-input type="text" v-model="forest.Location"></b-form-input>
        </b-form-group>
        <b-form-group label="*所有者">
          <b-form-input type="text" v-model="forest.Owner"></b-form-input>
        </b-form-group>
        <b-form-group label="面積(公頃)">
          <b-form-input
            disabled
            type="text"
            v-model="forest.Area"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="*管理機關">
          <b-form-input type="text" v-model="forest.Management"></b-form-input>
        </b-form-group>
        <b-form-group label="備註">
          <b-form-input type="text" v-model="forest.Note"></b-form-input>
        </b-form-group>
      </b-modal>
      <AlertModal :alertModalObj="alertModalObj" @getData="init" />
    </div>
  </div>
</template>
<script>
import AlertModal from "../../components/AlertModal.vue";
export default {
  components: {
    AlertModal,
  },
  data() {
    return {
      rows: 0,
      search: null,
      breadcrumb: [
        {
          text: "資料管理",
          active: true,
        },
        {
          text: "保安林檢訂成果",
        },
      ],
      perPage: 8,
      currentPage: 1,
      items: [],
      Fields: [
        {
          key: "PF_NAME",
          label: "保安林編號",
        },
        {
          key: "PF_TYPE",
          label: "保安林種類",
        },
        {
          key: "PF_LOCATION",
          label: "所在地",
        },
        {
          key: "PF_OWNER",
          label: "所有者",
        },
        {
          key: "VD_CHECKEDAREA",
          label: "面積(公頃)",
        },
        {
          key: "PF_MANAGEMENT",
          label: "管理機關",
        },
        // {
        //   key: "PF_NOTE",
        //   label: "備註",
        // },
        {
          key: "VD_YEAR",
          label: "檢訂年度",
        },
        {
          key: "PF_LOCATION",
          label: "瀏覽圖台(最終檢訂範圍)",
        },
        {
          key: "Detail",
          label: "詳細內容",
        },
      ],
      forest: {},
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
        refresh: false,
      },
      Permission: null,
      apiGetRevisionResultList: (data) =>
        this.userRequest.post("API/Default/GetRevisionResultList", data),
      apiUpdateRevisionResult: (data) =>
        this.userRequest.post("API/Default/UpdateRevisionResult", data),
      apiGetMyGroupPermission: (data) =>
        this.userRequest.post("API/Default/GetMyGroupPermission", data),
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.FunctionToken(this.GetRevisionResultList, {});
      this.FunctionResetModal();
    },
    FunctionResetModal() {
      this.forest = {
        PF_ID: null,
        Name: null,
        Type: null,
        Location: null,
        Owner: null,
        Area: null,
        Management: null,
        Note: null,
      };
    },
    FunctionUpdate() {
      if (
        this.IsNull(this.forest.Type) &&
        this.IsNull(this.forest.Location) &&
        this.IsNull(this.forest.Owner) &&
        this.IsNull(this.forest.Management)
      ) {
        let json = {};
        json.PF_ID = this.forest.PF_ID;
        json.PF_TYPE = this.forest.Type;
        json.PF_NAME = this.forest.Name;
        json.PF_LOCATION = this.forest.Location;
        json.PF_OWNER = this.forest.Owner;
        json.PF_MANAGEMENT = this.forest.Management;
        json.PF_NOTE = this.forest.Note;
        console.log(json);
        this.FunctionToken(this.UpdateRevisionResult, json);
      } else {
        this.alertModalObj.show = true;
        this.alertModalObj.content = "請填寫必填內容";
        this.alertModalObj.refresh = false;
      }
    },
    FunctionSearch() {
      this.FunctionToken(this.GetRevisionResultList, {
        keyword: this.search,
      });
    },
    //======================api==========================
    GetRevisionResultList(data) {
      this.apiGetRevisionResultList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.items = jsonData;
            this.items.forEach((element) => {
              element.PF_LOCATION = element.PF_LOCATION.trim();
            });
            this.rows = jsonData.length;
            let EditPermission = this.Permission.RevisionResult_Editor;
            if (EditPermission) {
              this.Fields.push({
                key: "operate",
                label: "功能",
                thClass: "append",
                tdClass: "append",
              });
            }
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //編輯保安林_檢訂成果
    UpdateRevisionResult(data) {
      this.apiUpdateRevisionResult(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新成功";
            this.alertModalObj.refresh = true;
          } else {
            console.log(json);
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetMyGroupPermission(data) {
      this.apiGetMyGroupPermission(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.Permission = jsonData;
            console.log(jsonData);
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
