<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
    this.Logout();
  },
  methods: {
    Logout() {
      sessionStorage.removeItem("TokenID");
      sessionStorage.removeItem("username");
      this.$router.push({
        path: "/",
      });
    },
  }
};
</script>