<template>
  <div class="mainMap" style="height: 100%">
    <div
      id="wmsTipDiv"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        z-index: 10;
      "
      v-if="showWmsTip"
      @click="showWmsTip = false"
    >
      <p
        style="
          color: rgba(255, 255, 255, 0.7);
          font-size: 1.6rem;
          font-family: Microsoft JhengHei;
          font-weight: bold;
          text-align: center;
        "
      >
        {{ wmsTip }}
      </p>
    </div>
    <div
      id="layerAlert"
      style="position: fixed; top: 65px; left: 45px; z-index: 10"
      v-if="alertLayers.length > 0"
    >
      <b-button variant="dark" @click="CancelAlertLayer">取消選取圖層</b-button>
    </div>
    <div id="capture" style="height: 100%">
      <Openlayers
        ref="map"
        :all="map"
        :formatSelected="formatSelected"
      ></Openlayers>

      <div
        id="textDiv"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
        "
      >
        <span
          v-for="(item, index) in textSelect"
          :key="index"
          :class="[
            item.isBold ? 'font-weight-bold' : '',
            item.isItalic ? 'font-italic' : '',
          ]"
          :style="{
            'white-space': 'pre',
            position: 'relative',
            top: item.PositionTop != null ? item.PositionTop + 'px' : '',
            left: item.PositionLeft != null ? item.PositionLeft + 'px' : '',
            bottom:
              item.PositionBottom != null ? item.PositionBottom + 'px' : '',
            right: item.PositionRight != null ? item.PositionRight + 'px' : '',
            border: item.isBorder ? 'solid' : 'none',
            'border-width': item.BorderWidth + 'px',
            'border-color': item.BorderHex8,
            'font-size': item.FontSize + 'px',
            color: item.FontHex8,
            'background-color': item.BackgroundHex8,
            'text-decoration': item.isUnderline
              ? 'underline'
              : item.isLinethrough
              ? 'line-through'
              : 'none',
          }"
          v-show="item.visible"
        >
          {{ item.text }}
        </span>
      </div>
      <!-- v-if="legendRain || legendEcho || CommonColorList.length > 0" -->
      <div class="legendBar" :class="{ hasRight: legendhasRight }">
        <b-container>
          <b-row>
            <b-col v-show="legendRain">
              <p class="info">累計雨量<br />毫米 mm</p>
              <div class="legend">
                <li class="color Level-17"></li>
                <li class="color Level-16"><span class="pl-2">300</span></li>
                <li class="color Level-15"><span class="pl-2">200</span></li>
                <li class="color Level-14"><span class="pl-2">150</span></li>
                <li class="color Level-13"><span class="pl-2">130</span></li>
                <li class="color Level-12"><span class="pl-2">110</span></li>
                <li class="color Level-11"><span class="pl-2">90</span></li>
                <li class="color Level-10"><span class="pl-2">70</span></li>
                <li class="color Level-9"><span class="pl-2">50</span></li>
                <li class="color Level-8"><span class="pl-2">40</span></li>
                <li class="color Level-7"><span class="pl-2">30</span></li>
                <li class="color Level-6"><span class="pl-2">20</span></li>
                <li class="color Level-5"><span class="pl-2">15</span></li>
                <li class="color Level-4"><span class="pl-2">10</span></li>
                <li class="color Level-3"><span class="pl-2">6</span></li>
                <li class="color Level-2"><span class="pl-2">2</span></li>
                <li class="color Level-1"><span class="pl-2">1</span></li>
              </div>
            </b-col>
            <b-col v-show="legendEcho">
              <p class="info">回波圖<br />dBZ</p>
              <div class="legend">
                <li class="color echo-75"></li>
                <li class="color echo-70"><span class="pl-2">70</span></li>
                <li class="color echo-65"><span class="pl-2">65</span></li>
                <li class="color echo-60"><span class="pl-2">60</span></li>
                <li class="color echo-55"><span class="pl-2">55</span></li>
                <li class="color echo-50"><span class="pl-2">50</span></li>
                <li class="color echo-45"><span class="pl-2">45</span></li>
                <li class="color echo-40"><span class="pl-2">40</span></li>
                <li class="color echo-35"><span class="pl-2">35</span></li>
                <li class="color echo-30"><span class="pl-2">30</span></li>
                <li class="color echo-25"><span class="pl-2">25</span></li>
                <li class="color echo-20"><span class="pl-2">20</span></li>
                <li class="color echo-15"><span class="pl-2">15</span></li>
                <li class="color echo-10"><span class="pl-2">10</span></li>
                <li class="color echo-5"><span class="pl-2">5</span></li>
                <li class="color echo-0"><span class="pl-2">0</span></li>
                <li class="color echo--5"><span class="pl-2">-5</span></li>
              </div>
            </b-col>
            <b-col v-show="legendFire">
              <p class="info">林火天氣<br />危險指數</p>
              <div class="legend">
                <li class="color fire-70"><span class="pl-2">5</span></li>
                <li class="color fire-65"><span class="pl-2">4</span></li>
                <li class="color fire-60"><span class="pl-2">3</span></li>
                <li class="color fire-55"><span class="pl-2">2</span></li>
                <li class="color fire-50"><span class="pl-2">1</span></li>
              </div>
            </b-col>
            <b-col v-show="legendLand">
              <p class="info">國土利用色碼表</p>
              <div class="legendL">
                <li class="color land-01">
                  <span class="pl-2">農業利用土地</span>
                </li>
                <li class="color land-02">
                  <span class="pl-2">森林利用土地</span>
                </li>
                <li class="color land-03">
                  <span class="pl-2">交通利用土地</span>
                </li>
                <li class="color land-04">
                  <span class="pl-2">水利利用土地</span>
                </li>
                <li class="color land-05">
                  <span class="pl-2">建築利用土地</span>
                </li>
                <li class="color land-06">
                  <span class="pl-2">公共利用土地</span>
                </li>
                <li class="color land-07">
                  <span class="pl-2">遊憩利用土地</span>
                </li>
                <li class="color land-08">
                  <span class="pl-2">礦鹽利用土地</span>
                </li>
                <li class="color land-09">
                  <span class="pl-2">其他利用土地</span>
                </li>
                <li class="color">
                  <a
                    class="pl-2"
                    href="https://maps.nlsc.gov.tw/demo/109%E5%B9%B4%E7%89%88%E5%9C%8B%E5%9C%9F%E5%88%A9%E7%94%A8%E8%89%B2%E7%A2%BC%E8%A1%A8.png"
                    target="_blank"
                    style="color: #7d90fa"
                    >(點擊查看所有圖例...)</a
                  >
                </li>
              </div>
            </b-col>
            <b-col v-show="CommonColorList.length > 0">
              <p class="info">圖例</p>
              <div class="layerGroup">
                <div
                  v-for="(item, index) in CommonColorList"
                  :key="index"
                  class="layerItem"
                >
                  <div class="text-center">
                    <b-icon
                      icon="circle-fill"
                      :style="{
                        color: item.ColorHex,
                      }"
                      v-if="item.styleType == 'point'"
                    ></b-icon>
                    <b-icon
                      icon="align-end"
                      :style="{
                        color: item.ColorHex,
                      }"
                      v-else-if="
                        item.styleType == 'line' ||
                        item.styleType == 'linestring' ||
                        item.styleType == 'multilinestring'
                      "
                    ></b-icon>
                    <b-icon
                      icon="grid3x3"
                      :style="{
                        color: 'rgb(12, 228, 26)',
                      }"
                      v-else-if="item.styleType == 'image'"
                    ></b-icon>
                    <span
                      class="color"
                      :style="{
                        color: item.ColorHex,
                        'background-color': item.BackColorHex,
                        'border-style': item.BorderStyle,
                        'border-color': item.BorderColor,
                        width: item.width,
                        height: item.height,
                      }"
                      v-else-if="item.styleType == 'draw'"
                    >
                    </span>
                    <b-icon
                      icon="square-fill"
                      :style="{
                        color: item.ColorHex,
                      }"
                      v-else
                    ></b-icon>
                  </div>
                  <div class="pl-1">
                    <p>{{ item.title }}</p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>

    <div class="mapInfo"></div>
    <div v-show="popup1.show" class="ol-popup" ref="popup" id="popup">
      <h5>{{ popup1.title }}</h5>
      <p class="box">{{ popup1.content }}</p>
      <div v-if="popup1.fileType == 'mp4' && popup1.fileUrl != null">
        <div>
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="popup1.fileUrl"
            allowfullscreen
          ></b-embed>
        </div>
      </div>
      <div v-else-if="popup1.fileType != 'mp4' && popup1.fileUrl != null">
        <b-img
          :src="popup1.fileUrl"
          fluid
          style="height: 200px; width: 200px"
        ></b-img>
      </div>
    </div>
    <div class="mapInfo">
      <div>座標系統:WGS84</div>
      <div id="mouse-position"></div>
    </div>

    <div class="MainsideBar">
      <b-button-group vertical class="MainsideBarBtn">
        <b-button
          v-b-tooltip.hover.left
          :class="{ active: BtnActive[0] }"
          @click="FunctionSBCShow(0)"
          title="圖層列表"
        >
          <i class="fas fa-layer-group"></i>
        </b-button>
        <b-button
          v-b-tooltip.hover.left
          :class="{ active: BtnActive[2] }"
          @click="FunctionSBCShow(2)"
          title="定位查詢"
        >
          <i class="fas fa-map-marker-alt"></i>
        </b-button>
        <b-button
          v-b-tooltip.hover.left
          :class="{ active: BtnActive[3] }"
          @click="FunctionSBCShow(3)"
          title="繪製標記"
        >
          <b-icon icon="brush"></b-icon>
        </b-button>
        <b-button
          v-b-modal.modal-insert
          v-b-tooltip.hover.left
          title="新增圖層"
        >
          <i class="fas fa-plus"></i>
        </b-button>
        <b-button
          v-b-tooltip.hover.left
          title="開啟圖例"
          @click="FunctionShowCommonColor"
        >
          <i class="fas fa-info"></i>
        </b-button>
      </b-button-group>

      <div class="MainsideBarContent" :class="{ show: sideBarContentShow[0] }">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="圖層列表" active>
              <b-form-group label="">
                <b-form inline class="">
                  <label class="mr-sm-2" for="inline-form-custom-select-pref"
                    >關鍵字查詢</label
                  >
                  <b-form-input
                    id="input-1"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="searchMap"
                    placeholder="請輸入關鍵字"
                    required
                    @keyup.enter="FunctionSearch"
                  ></b-form-input>
                  <b-button variant="info" @click="FunctionSearch"
                    >查詢</b-button
                  >
                </b-form>
              </b-form-group>
              <p>所有資料僅供參考用途，不得作為任何形式證明</p>
              <b-form-group label="" v-if="UploadProgressShow">
                <b-form inline class="">
                  <label class="mr-sm-2" for="inline-form-custom-select-pref"
                    >{{ UploadProgressTitle }} 上傳進度</label
                  >
                  <b-progress
                    style="width: 100%"
                    :value="UploadProgress"
                    max="100"
                    show-progress
                    animated
                  ></b-progress>
                </b-form>
              </b-form-group>
              <div class="layer-item-check">
                <div
                  class="layer-item-check-group"
                  v-if="
                    (MapLayer.data == undefined || MapLayer.data == null) &&
                    MapLayer1.length == 0 &&
                    MapLayer0.length == 0
                  "
                >
                  <span>查無資料</span>
                </div>

                <div
                  class="layer-item-check-group"
                  v-for="item in MapLayer0"
                  :key="item.id"
                >
                  <h5>
                    <span>{{ item.title }}</span>
                    <span
                      class="open-icon"
                      v-show="item.expend"
                      @click="item.expend = !item.expend"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="open-icon"
                      v-show="item.expend == false"
                      @click="item.expend = !item.expend"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </h5>
                  <ul class="pl-3" v-if="item.expend">
                    <li v-for="items in item.data" :key="items.id">
                      <div class="layer-item-check-group-base">
                        <b-form-checkbox
                          v-model="mapSelect"
                          :value="items.ML_ID"
                          @change="changeMap(items)"
                        >
                          {{ items.title }}
                        </b-form-checkbox>
                      </div>
                      <ul v-if="items.expend">
                        <li v-for="items1 in items.data" :key="items1.title">
                          <b-form-checkbox
                            v-model="mapSelect"
                            :value="items1.ML_ID"
                            @change="changeMap(items1)"
                          >
                            {{ items1.title }}
                          </b-form-checkbox>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>

                <div
                  class="layer-item-check-group"
                  v-if="MapLayer.title != null"
                >
                  <h5>
                    <span>{{ MapLayer.title }}</span>
                    <span
                      class="open-icon"
                      v-show="MapLayer.expend"
                      @click="MapLayer.expend = !MapLayer.expend"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="open-icon"
                      v-show="MapLayer.expend == false"
                      @click="MapLayer.expend = !MapLayer.expend"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </h5>
                  <ul class="pl-3" v-if="MapLayer.expend">
                    <li v-for="items in MapLayer.data" :key="items.id">
                      <div class="layer-item-check-group-base">
                        <b-form-checkbox
                          v-model="items.checkAll"
                          @change="forestSetChecked(items)"
                        >
                          {{ items.title }}
                        </b-form-checkbox>
                        <span
                          class="open-icon"
                          v-show="items.expend"
                          @click="items.expend = !items.expend"
                          ><i class="fas fa-caret-up"></i
                        ></span>
                        <span
                          class="open-icon"
                          v-show="items.expend == false"
                          @click="items.expend = !items.expend"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>

                      <ul v-if="items.expend">
                        <li v-for="items1 in items.data" :key="items1.title">
                          <b-form-checkbox
                            v-model="mapSelect"
                            :value="items1.ML_ID"
                            @change="forestChange(items1, 'setChange')"
                          >
                            {{ items1.title }}
                          </b-form-checkbox>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>

                <div
                  class="layer-item-check-group"
                  v-for="item in MapLayer1"
                  :key="item.id"
                >
                  <h5>
                    <span>{{ item.title }}</span>
                    <span
                      class="open-icon"
                      v-show="item.expend"
                      @click="item.expend = !item.expend"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="open-icon"
                      v-show="item.expend == false"
                      @click="item.expend = !item.expend"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </h5>
                  <ul class="pl-3" v-if="item.expend">
                    <li v-for="items in item.data" :key="items.id">
                      <div class="layer-item-check-group-base">
                        <b-form-checkbox
                          v-model="mapSelect"
                          :value="items.ML_ID"
                          @change="changeMap(items)"
                        >
                          {{ items.title }}
                        </b-form-checkbox>
                      </div>
                      <ul v-if="items.expend">
                        <li v-for="items1 in items.data" :key="items1.title">
                          <b-form-checkbox
                            v-model="mapSelect"
                            :value="items1.ML_ID"
                            @change="changeMap(items1)"
                          >
                            {{ items1.title }}
                          </b-form-checkbox>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </b-tab>
            <b-tab title="圖層設定">
              <b-form inline class="justify-content-between mb-3">
                <label for="inline-form-input-name" class="col-form-label"
                  >圖層套疊</label
                >
                <b-button @click="SaveMapLayer">儲存圖層設定</b-button>
                <div v-if="MapLayerSelect.length == 0">尚未選擇</div>
              </b-form>
              <draggable @sort="mapSetZIndex" v-model="MapLayerSelect">
                <transition-group>
                  <div v-for="(item, i) in MapLayerSelect" :key="i">
                    <div class="layer-item-group">
                      <div class="layer-item-list">
                        <div class="layer-item-list-collapse">
                          <div>
                            <div v-if="item.collapseExpend">
                              <div class="layer-item-list-collapse-title">
                                <div>{{ item.title }}</div>
                                <b-icon
                                  icon="caret-up-fill"
                                  aria-hidden="true"
                                  @click="item.collapseExpend = false"
                                ></b-icon>
                              </div>
                              <div class="layer-item-list-collapse-main">
                                <b-row>
                                  <b-col cols="10" class="text-left">
                                    <b-form-checkbox
                                      v-model="item.layerVisible"
                                      switch
                                      @change="mapSetVisible(item)"
                                      >是否顯示
                                    </b-form-checkbox>
                                  </b-col>
                                  <b-col cols="2" class="text-right">
                                    <b-icon
                                      stacked
                                      icon="x-circle-fill"
                                      @click="deleteLayerList(item)"
                                    ></b-icon>
                                  </b-col>
                                </b-row>
                                <b-row class="mt-3">
                                  <b-col cols="4" class="text-right"
                                    >透明度</b-col
                                  >
                                  <b-col cols="8"
                                    ><input
                                      type="range"
                                      min="0"
                                      max="100"
                                      step="1"
                                      @change="mapSetOpacity(item)"
                                      v-model="item.rangeValue"
                                    />
                                  </b-col>
                                </b-row>
                                <b-row
                                  class="mt-3"
                                  v-if="
                                    item.styleType.toLowerCase() !== 'image' &&
                                    item.layerType != 'WMS'
                                  "
                                >
                                  <b-col cols="4" class="text-right"
                                    >顏色設定</b-col
                                  >
                                  <b-col cols="8"
                                    ><div>
                                      <ColorPicker
                                        :myColor="item.layerColor"
                                        :arrIndex="i"
                                        @ChildUpdateColor="FunctionColorPick"
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row
                                  class="mt-3"
                                  align-v="center"
                                  v-if="item.styleType.toLowerCase() == 'point'"
                                >
                                  <b-col cols="4" class="text-right"
                                    >點位大小</b-col
                                  >
                                  <b-col cols="8"
                                    ><div>
                                      <b-form-spinbutton
                                        min="1"
                                        max="100"
                                        v-model="item.pointRadius"
                                        @change="mapSetStyle(item)"
                                      ></b-form-spinbutton>
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row
                                  class="mt-3"
                                  align-v="center"
                                  v-if="
                                    item.styleType.toLowerCase() == 'line' ||
                                    item.styleType.toLowerCase() ==
                                      'linestring' ||
                                    item.styleType.toLowerCase() ==
                                      'multilinestring'
                                  "
                                >
                                  <b-col cols="4" class="text-right"
                                    >線段粗細</b-col
                                  >
                                  <b-col cols="8"
                                    ><div>
                                      <b-form-spinbutton
                                        min="1"
                                        max="100"
                                        v-model="item.lineWidth"
                                        @change="mapSetStyle(item)"
                                      ></b-form-spinbutton>
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row class="mt-3" align-v="center">
                                  <b-col>
                                    <b-button
                                      variant="primary"
                                      block
                                      v-if="item.layerType != 'WMS'"
                                      @click="FunctionLayerSetPosition(item)"
                                      ><b-icon
                                        icon="geo-alt"
                                        aria-hidden="true"
                                      ></b-icon>
                                      <span>定位</span></b-button
                                    >
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                            <div v-else>
                              <div class="layer-item-list-collapse-title">
                                <div>{{ item.title }}</div>
                                <b-icon
                                  icon="caret-down-fill"
                                  aria-hidden="true"
                                  @click="item.collapseExpend = true"
                                ></b-icon>
                              </div>
                              <div class="layer-item-list-collapse-main">
                                <div class="d-flex justify-content-between">
                                  <b-button
                                    v-if="item.layerVisible"
                                    @click="
                                      item.layerVisible = false;
                                      mapSetVisible(item);
                                    "
                                  >
                                    <b-icon
                                      icon="eye-fill"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                  <b-button
                                    v-else
                                    @click="
                                      item.layerVisible = true;
                                      mapSetVisible(item);
                                    "
                                    class=""
                                  >
                                    <b-icon
                                      icon="eye-slash-fill"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                  <b-button
                                    class="btn-group-border"
                                    v-if="
                                      item.styleType.toLowerCase() !==
                                        'image' && item.layerType != 'WMS'
                                    "
                                  >
                                    <div
                                      class="color-setting"
                                      :style="{
                                        'background-color': item.layerColor,
                                      }"
                                    ></div>
                                  </b-button>
                                  <b-button
                                    v-if="item.layerType != 'WMS'"
                                    @click="FunctionLayerSetPosition(item)"
                                  >
                                    <b-icon
                                      icon="geo-alt"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                  <b-button @click="deleteLayerList(item)"
                                    ><b-icon
                                      stacked
                                      icon="x-circle-fill"
                                    ></b-icon
                                  ></b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </b-tab>
            <b-tab title="底圖設定">
              <b-form-group>
                <b-form-radio
                  v-model="baseMapSelected"
                  name="some-radios"
                  value="臺灣通用電子地圖"
                  >臺灣通用電子地圖</b-form-radio
                >
                <b-form-radio
                  v-model="baseMapSelected"
                  name="some-radios"
                  value="正射影像圖(2018~2021年)"
                  >正射影像圖(2018~2021年)</b-form-radio
                >
              </b-form-group>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
      <div class="MainsideBarContent" :class="{ show: sideBarContentShow[2] }">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="定位查詢" active>
              <b-form-group label="定位方式">
                <b-form-select
                  v-model="locationSelected"
                  :options="locationOptions"
                ></b-form-select>
              </b-form-group>
              <div v-if="locationSelected == 0">
                <b-form-group label="座標系統">
                  <b-form-select
                    v-model="positionSelected"
                    :options="positionOptions"
                  ></b-form-select>
                </b-form-group>
                <b-form-group label="X軸座標">
                  <b-form-input
                    class="mt-1"
                    v-model="mapXPosition"
                    type="number"
                    :placeholder="
                      positionSelected == 'wgs84'
                        ? '範例：121'
                        : '範例：250000.0000'
                    "
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Y軸座標">
                  <b-form-input
                    class="mt-1"
                    v-model="mapYPosition"
                    type="number"
                    :placeholder="
                      positionSelected == 'wgs84'
                        ? '範例：22'
                        : '範例：2433557.1303'
                    "
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div v-if="locationSelected == 1">
                <b-form-group label="編號">
                  <b-form-select
                    v-model="forestSelected"
                    :options="forestOptions"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div v-if="locationSelected == 2">
                <b-form-group label="三角點名稱">
                  <b-form-input
                    v-model="triangulationStationSelected"
                    list="triangulationStation-list"
                  ></b-form-input>
                  <b-form-datalist
                    id="triangulationStation-list"
                    :options="triangulationStationOptions"
                  >
                  </b-form-datalist>
                </b-form-group>
              </div>
              <div v-if="locationSelected == 3">
                <b-form-group label="鄉鎮">
                  <b-form-select
                    v-model="townSelected"
                    :options="townOptions"
                    @change="ChangeTown"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- 請選擇鄉鎮 --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="地段">
                  <b-form-select v-model="lotSelected" :options="lotOptions">
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- 請選擇地段 --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="地號(母號+子號共8碼)"
                  v-if="lotSelected != null"
                >
                  <b-form-input
                    v-model="placeNumberSelected"
                    placeholder="請輸入地號(EX:00010000)"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div v-if="locationSelected == 4">
                <b-form-group label="查詢條件">
                  <b-form-radio-group
                    :disabled="engSearchTypeEnabled"
                    v-model="engSearchTypeSelected"
                    :options="engSearchTypeOptions"
                    @change="ChangeEngSearchType"
                    name="radio-options"
                  ></b-form-radio-group>
                </b-form-group>
                <div v-if="engSearchTypeSelected == '工程點位'">
                  <b-form-group label="年分">
                    <b-form-select
                      :disabled="engYearEnabled"
                      :options="engYearOption"
                      v-model="engYearSelected"
                      @change="FunctionChangeEngYear"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group label="工程">
                    <b-form-input
                      :disabled="engEnabled"
                      v-model="engSelected"
                      list="eng-list"
                    ></b-form-input>
                    <b-form-datalist id="eng-list" :options="engOptions">
                    </b-form-datalist>
                  </b-form-group>
                </div>
                <div v-else>
                  <b-form-group label="座標系統">
                    <b-form-select
                      :disabled="engPositionEnabled"
                      v-model="engPositionSelected"
                      :options="positionOptions"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group label="X軸座標">
                    <b-form-input
                      class="mt-1"
                      :disabled="engPositionXEnabled"
                      v-model="engPositionX"
                      type="number"
                      :placeholder="
                        engPositionSelected == 'wgs84'
                          ? '範例：121'
                          : '範例：250000.0000'
                      "
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label="Y軸座標">
                    <b-form-input
                      class="mt-1"
                      :disabled="engPositionYEnabled"
                      v-model="engPositionY"
                      type="number"
                      :placeholder="
                        engPositionSelected == 'wgs84'
                          ? '範例：22'
                          : '範例：2433557.1303'
                      "
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <b-form-group label="半徑(公里)">
                  <b-form-input
                    :disabled="engRadiusEnabled"
                    v-model="engRadius"
                    placeholder="請輸入半徑(上面座標為中心點)"
                  ></b-form-input>
                </b-form-group>

                <b-button variant="success" block @click="engExport"
                  ><b-icon
                    icon="file-earmark-excel"
                    aria-hidden="true"
                  ></b-icon>
                  匯出</b-button
                >
              </div>
              <b-row>
                <b-col cols="6">
                  <b-button
                    block
                    class="mt-2"
                    variant="danger"
                    v-if="locationSelected != 1"
                    @click="clearPosition"
                    >清除座標點</b-button
                  >
                </b-col>
                <b-col cols="6">
                  <b-button
                    block
                    class="mt-2"
                    variant="info"
                    @click="setPosition"
                    >定位</b-button
                  >
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>

      <div class="MainsideBarContent" :class="{ show: sideBarContentShow[3] }">
        <b-card no-body>
          <b-tabs card>
            <b-tab
              title="圖形標記"
              active
              :disabled="OpenTextLayerEditor"
              @click="FunctionDrawTabChange"
            >
              <b-row v-if="!OpenDrawLayerEditor">
                <b-col class="text-center">
                  <b-button @click="FunctionDrawStart">新增圖層</b-button>
                </b-col>
                <b-col class="text-center">
                  <b-button variant="danger" @click="FunctionDrawClear"
                    >清除所有圖層</b-button
                  >
                </b-col>
              </b-row>
              <div class="mt-3" v-if="OpenDrawLayerEditor">
                <b-row>
                  <b-col class="text-center">
                    <b-button @click="UndoDrwaLayerItem"
                      ><b-icon
                        icon="arrow-counterclockwise"
                        aria-hidden="true"
                      ></b-icon
                      >上一步</b-button
                    >
                  </b-col>
                  <b-col class="text-center">
                    <b-button @click="RedoDrwaLayerItem"
                      ><b-icon
                        icon="arrow-clockwise"
                        aria-hidden="true"
                      ></b-icon
                      >下一步</b-button
                    >
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col cols="8">
                    <b-form-group label="樣式名稱">
                      <b-form-input
                        v-model="drawLayerSetting.title"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="形狀">
                      <b-form-select
                        v-model="DrawTypeSelected"
                        :options="DrawTypeOptions"
                        @change="FunctionDrawTypeChange"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="點的半徑">
                      <b-form-input
                        type="number"
                        v-model="drawLayerSetting.pointRadius"
                        @change="DrawSetStyle(drawLayerSetting)"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="線的寬度">
                      <b-form-input
                        type="number"
                        v-model="drawLayerSetting.lineWidth"
                        @change="DrawSetStyle(drawLayerSetting)"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="點">
                      <ColorPicker
                        :myColor="drawLayerSetting.pointColor"
                        :arrIndex="0"
                        @ChildUpdateColor="DrawSetStyle"
                      />
                      <b-form-input
                        type="range"
                        min="0"
                        max="100"
                        v-model="drawLayerSetting.pointRange"
                        @change="DrawSetStyle(drawLayerSetting)"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="線">
                      <ColorPicker
                        :myColor="drawLayerSetting.lineColor"
                        :arrIndex="1"
                        @ChildUpdateColor="DrawSetStyle"
                      />
                      <b-form-input
                        type="range"
                        min="0"
                        max="100"
                        v-model="drawLayerSetting.lineRange"
                        @change="DrawSetStyle(drawLayerSetting)"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="面">
                      <ColorPicker
                        :myColor="drawLayerSetting.areaColor"
                        :arrIndex="2"
                        @ChildUpdateColor="DrawSetStyle"
                      />
                      <b-form-input
                        type="range"
                        min="0"
                        max="100"
                        v-model="drawLayerSetting.areaRange"
                        @change="DrawSetStyle(drawLayerSetting)"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-show="
                    drawLayerSetting.editPointColor ||
                    drawLayerSetting.editLineColor ||
                    drawLayerSetting.editAreaColor
                  "
                >
                  <b-col class="text-right">
                    <b-form-group>
                      <Chrome v-model="PickerColors"></Chrome>
                    </b-form-group>
                    <b-button variant="primary" @click="FunctionPickerColor"
                      >確定</b-button
                    >
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col class="text-center">
                    <b-button
                      variant="danger"
                      block
                      @click="FunctionDrawEditCancel"
                      >取消</b-button
                    >
                  </b-col>
                  <b-col class="text-center">
                    <b-button
                      block
                      variant="success"
                      @click="FunctionDrawEditSave"
                      >儲存</b-button
                    >
                  </b-col>
                </b-row>
              </div>
              <hr />
              <b-row>
                <b-col>
                  <div v-for="(item, i) in drawLayerSelect" :key="i">
                    <div class="layer-item-group">
                      <div class="layer-item-list">
                        <div class="layer-item-list-main-noflex">
                          <b-row>
                            <b-col cols="2" class="text-center">
                              <b-form-checkbox
                                v-model="item.visible"
                                switch
                                @change="item.layer.setVisible(item.visible)"
                              ></b-form-checkbox>
                            </b-col>
                            <b-col cols="4">
                              <span class="layer-item-list-main-title">{{
                                item.title
                              }}</span>
                            </b-col>
                            <b-col cols="4">
                              <b-button
                                variant="info"
                                @click="editDrawLayerItem(item)"
                                >編輯</b-button
                              >
                            </b-col>
                            <b-col cols="2" class="text-right">
                              <b-icon
                                stacked
                                icon="x-circle-fill"
                                @click="deleteDrawLayerItem(item)"
                              ></b-icon>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              title="文字標記"
              :disabled="OpenDrawLayerEditor"
              @click="FunctionDrawTabChange"
            >
              <b-row v-if="!OpenTextLayerEditor">
                <b-col class="text-center">
                  <b-button @click="FunctionAddTextLayer">新增文字</b-button>
                </b-col>
                <b-col class="text-center">
                  <b-button variant="danger" @click="textLayerRemove"
                    >移除所有文字</b-button
                  >
                </b-col>
              </b-row>
              <div class="mt-3" v-if="OpenTextLayerEditor">
                <h3 style="color: red" class="text-center">
                  請點選文字欲放置位置
                </h3>
                <b-row class="mt-3">
                  <b-col>
                    <b-form-group label="字大小">
                      <b-form-input
                        type="number"
                        v-model="textLayerSetting.FontSize"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="text-center">
                    <b-form-group label="字體顏色">
                      <ColorPicker
                        :myColor="textLayerSetting.FontColor"
                        :arrIndex="0"
                        @ChildUpdateColor="TextSetStyle"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="內容">
                      <b-form-textarea
                        placeholder="請輸入文字"
                        rows="3"
                        v-model="textLayerSetting.text"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group label="位置(X)">
                      <b-form-input
                        :disabled="true"
                        type="number"
                        v-model="textLayerSetting.positionX"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="位置(Y)">
                      <b-form-input
                        :disabled="true"
                        type="number"
                        v-model="textLayerSetting.positionY"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col class="text-center">
                    <b-button
                      variant="danger"
                      v-if="!isEditText"
                      @click="deleteTextItem(textLayerSetting)"
                      >取消</b-button
                    >
                  </b-col>
                  <b-col>
                    <b-button
                      block
                      variant="success"
                      @click="FunctionTextEditSave"
                      >儲存</b-button
                    >
                  </b-col>
                </b-row>
              </div>
              <div
                v-for="(item, index) in textLayerSelect"
                :key="index"
                class="mt-3"
              >
                <div class="layer-item-group">
                  <div class="layer-item-list">
                    <div class="layer-item-list-main-noflex">
                      <b-row>
                        <b-col cols="2" class="text-center">
                          <b-form-checkbox
                            v-model="item.visible"
                            switch
                            @change="item.layer.setVisible(item.visible)"
                          ></b-form-checkbox>
                        </b-col>
                        <b-col cols="4">
                          <span class="layer-item-list-main-title">{{
                            item.text
                          }}</span>
                        </b-col>
                        <b-col cols="4">
                          <b-button variant="info" @click="editTextItem(item)"
                            >編輯</b-button
                          >
                        </b-col>
                        <b-col cols="2" class="text-right">
                          <b-icon
                            stacked
                            icon="x-circle-fill"
                            @click="deleteTextLayerItem(item)"
                          ></b-icon>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab
              title="計算"
              :disabled="OpenTextLayerEditor || OpenDrawLayerEditor"
            >
              <b-row>
                <b-col
                  ><b-button
                    block
                    variant="info"
                    class="mb-3"
                    @click="mapCalculate('Polygon')"
                  >
                    <i class="fas fa-ruler-combined mr-1"></i>面積計算</b-button
                  ></b-col
                >
                <b-col
                  ><b-button
                    block
                    variant="success"
                    class="mb-3"
                    @click="mapCalculate('LineString')"
                  >
                    <i class="fas fa-ruler mr-1"></i>距離計算</b-button
                  ></b-col
                >
              </b-row>
              <b-button block class="mb-3" @click="mapCalculate('None')"
                >清除</b-button
              >
            </b-tab>
            <b-tab title="匯出">
              <b-row>
                <b-col>
                  <b-form-group label="比例尺">
                    <b-form-select
                      :options="scaleOption"
                      v-model="scaleSelected"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <b-button variant="info" @click="FunctionResetScale"
                    >設定畫面比例尺</b-button
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <b-form-checkbox v-model="compass" switch
                      >指北針</b-form-checkbox
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <b-button variant="primary" @click="FunctionRemoveExtent"
                    >移除四點座標</b-button
                  >
                </b-col>
                <b-col>
                  <b-button variant="primary" @click="FunctionShowExtent"
                    >顯示四點座標</b-button
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p>請先確認圖面匯出範圍再按下顯示四點座標</p>
                </b-col>
              </b-row>
              <b-row class="mt-3 text-right">
                <b-col>
                  <b-button variant="primary" @click="FunctionPrintPdfTest"
                    >匯出圖檔</b-button
                  >
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>

    <b-modal
      content-class="shadow"
      size="md"
      id="modal-insert"
      title="新增使用者圖層"
      ok-only
      :ok-title="InsertButtonTitle"
      :ok-disabled="UploadProgressShow"
      ok-variant="primary"
      no-close-on-backdrop
      hide-backdrop
      no-stacking
      scrollable
      centered
      @ok="InsertMap"
    >
      <b-form-group label="檔案類型">
        <b-form-select
          v-model="insert.selected"
          :options="insert.options"
          @change="FunctionChangeInsertType"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        label="自定義圖層名稱"
        v-show="insert.selected != '圖片批次上傳'"
      >
        <b-form-input
          type="text"
          v-model="insert.name"
          placeholder="請輸入圖層名稱"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="WMS URL" v-if="insert.selected == 'WMS'">
        <b-form-input
          type="text"
          v-model="insert.wmsUrl"
          placeholder="https://"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="檔案 (座標系統TWD97，注意.zip檔名與內容一致)"
        v-if="insert.selected == 'SHP'"
      >
        <b-form-file
          v-model="insert.shpFile"
          :state="Boolean(insert.shpFile)"
          placeholder="請上傳zip檔,應包含.shp、.shx、.dbf"
          accept=".zip"
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>
      <b-form-group label="檔案" v-if="insert.selected == 'KML'">
        <b-form-file
          v-model="insert.kmlFile"
          :state="Boolean(insert.kmlFile)"
          placeholder="請上傳kml檔"
          accept=".kml"
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>
      <b-form-group label="檔案" v-if="insert.selected == 'GPX'">
        <b-form-file
          v-model="insert.gpxFile"
          :state="Boolean(insert.gpxFile)"
          placeholder="請上傳gpx檔"
          accept=".gpx"
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>
      <b-form-group label="檔案" v-if="insert.selected == 'GEOJSON'">
        <b-form-file
          v-model="insert.geoJson"
          :state="Boolean(insert.geoJson)"
          placeholder="請上傳geojson檔"
          accept=".geojson"
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>
      <div v-if="insert.selected == 'IMAGE'">
        <b-row>
          <b-col>
            <b-form-group label="橫坐標(WGS84)">
              <b-form-input
                v-model="insert.imageData.x"
                placeholder="範例：121"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="縱坐標(WGS84)">
              <b-form-input
                v-model="insert.imageData.y"
                placeholder="範例：22"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="檔案">
          <b-form-file
            v-model="insert.imageFile"
            :state="Boolean(insert.imageFile)"
            placeholder="請上傳圖片檔"
            accept=".png,.jpg,.jpeg"
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
      </div>
      <div v-if="insert.selected == '圖片批次上傳'">
        <b-button variant="primary" @click="FunctionDownloadMulitImageDemo"
          >範例檔案下載</b-button
        >
        <p class="text-danger">.zip包含範例excel及照片</p>
        <b-form-group label="檔案">
          <b-form-file
            v-model="insert.imageMultiData"
            :state="Boolean(insert.imageMultiData)"
            placeholder="請上傳zip檔"
            accept=".zip"
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
      </div>

      <div>圖層保留時間一個月</div>
    </b-modal>
    <b-modal
      id="modal-featureInfo"
      :title="'圖層屬性'"
      ref="modal-featureInfo"
      size="xl"
      ok-only
    >
      <b-tabs content-class="mt-3">
        <b-tab
          v-for="(item, index) in featureInfoArray"
          :key="index"
          :title="item.title"
        >
          <b-container v-if="item.hasPropertyList">
            <b-row
              v-for="(item2, index2) in item.data"
              :key="index2"
              class="p-1 border-bottom"
            >
              <b-col class="text-center" cols="3"
                ><span>{{ item2.title }}</span></b-col
              >
              <b-col class="text-center" cols="3"
                ><span>{{ item2.newTitle }}</span></b-col
              >
              <b-col cols="6"
                ><p class="propertyText">{{ item2.text }}</p></b-col
              >
            </b-row>
          </b-container>
          <b-container v-else>
            <b-row
              v-for="(item2, index2) in item.data"
              :key="index2"
              class="p-1 border-bottom"
            >
              <b-col class="text-center" cols="6"
                ><span>{{ item2.title }}</span></b-col
              >
              <b-col cols="6"
                ><p class="propertyText">{{ item2.text }}</p></b-col
              >
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
    </b-modal>
    <AlertModal :alertModalObj="alertModalObj" />
    <div
      class="text-center"
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
      "
      align-v="center"
      v-if="isLoad"
    >
      <b-row style="position: relative; top: 45vh">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner
          ><span
            style="
              font-size: 20px;
              color: #ffffff;
              font-weight: bold;
              padding-left: 20px;
            "
            >載入中，請稍後...</span
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { Style, Fill, Stroke, Icon, Text } from "ol/style";
import Feature from "ol/Feature";
import AlertModal from "../components/AlertModal.vue";
import draggable from "vuedraggable";
import Openlayers from "../components/Openlayers.vue";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Image as ImageLayer,
} from "ol/layer";
import { TileWMS, XYZ } from "ol/source";
import KML from "ol/format/KML";
import GeoJSON from "ol/format/GeoJSON";
import CircleStyle from "ol/style/Circle";
import Projection from "ol/proj/Projection";
import Static from "ol/source/ImageStatic";
import EsriJSON from "ol/format/EsriJSON";
import { Draw, Modify, Snap } from "ol/interaction";
import { createBox, createRegularPolygon } from "ol/interaction/Draw";
import Polygon from "ol/geom/Polygon";
import { Chrome } from "vue-color";
import { getPointResolution } from "ol/proj";
import html2canvas from "html2canvas";
import OlExtUndoRedo from "ol-ext/interaction/UndoRedo";
import Circle from "ol/geom/Circle";
import { fromLonLat } from "ol/proj";
import ColorPicker from "../components/colorPicker.vue";
import Tiff from "tiff.js/tiff";
// import { unByKey } from "ol/Observable";

export default {
  components: {
    AlertModal,
    draggable,
    Openlayers,
    Chrome,
    ColorPicker,
  },
  data() {
    return {
      isLoad: false,
      initLoadIndex: 0,
      initLoadLength: 0,
      baseMapSelected: "臺灣通用電子地圖",
      MapLayer0: [],
      MapLayer: {},
      MapLayer1: [],
      mapSelect: [],
      MapLayerSelect: [],
      formData: new FormData(),
      insert: {
        selected: "WMS",
        options: [
          "WMS",
          "SHP",
          "KML",
          "GPX",
          "GEOJSON",
          "IMAGE",
          "圖片批次上傳",
        ],
        name: null,
        wmsUrl: null,
        shpFile: null,
        kmlFile: null,
        gpxFile: null,
        geoJson: null,
        imageFile: null,
        imageData: {
          x: null,
          y: null,
        },
        imageMultiData: null,
      },
      locationSelected: 0,
      locationOptions: [
        { text: "座標", value: 0 },
        { text: "保安林編號", value: 1 },
        { text: "三角點名稱", value: 2 },
        { text: "地籍", value: 3 },
        { text: "選單+工程", value: 4 },
      ],
      townOptions: [],
      townSelected: null,
      lotOptions: ["南佳里段"],
      lotSelected: null,
      placeNumberSelected: null,
      locationLayer: null,
      sideBarContentShow: [false, false, false],
      BtnActive: [false, false, false],
      searchMap: null,
      positionSelected: "wgs84",
      positionOptions: ["wgs84", "twd97"],
      forestSelected: "請選擇",
      forestOptions: [],
      triangulationStationSelected: null,
      triangulationStationOptions: [],
      mapXPosition: null,
      mapYPosition: null,
      sideButtonShow: true,
      mapSidebarShow: false,
      positionSidebarShow: false,
      layers: [],
      WmsSelect: [],
      WMSList: [],
      map: { layers: [] },
      bottomShow: false,
      bottomList: [],
      popup1: {
        data: { Overlay: {} },
        show: false,
        title: "",
        content: "",
        fileUrl: "",
        fileType: "",
      },
      slide: null,
      informationModal: { show: false, title: null, content: null },
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
        refresh: false,
      },
      colorInit: "rgba(52, 58, 64, 1)",
      outLayer: {
        title: "外部資源",
        expend: false,
        data: [
          {
            ML_ID: "ML2022080300001",
            title: "日累積雨量圖",
            layerType: "api",
            styleType: "image",
            layerColor: "rgba(60, 60, 60, 1)",
            pointRadius: 10,
            lineWidth: 1,
            rangeValue: 100,
            layerVisible: false,
            zINdex: 1,
          },
          {
            ML_ID: "ML2022080300002",
            title: "雷達回波圖",
            layerType: "api",
            styleType: "image",
            layerColor: "rgba(60, 60, 60, 1)",
            pointRadius: 10,
            lineWidth: 1,
            rangeValue: 100,
            layerVisible: false,
            zINdex: 1,
          },
          {
            ML_ID: "ML2022080300003",
            title: "FGIS",
            layerType: "api",
            styleType: "image",
            layerColor: "rgba(60, 60, 60, 1)",
            pointRadius: 10,
            lineWidth: 1,
            rangeValue: 100,
            layerVisible: false,
            zINdex: 1,
          },
        ],
      },
      featureInfoTitle: "",
      featureInfoItems: [],
      featureInfoArray: [],
      featureList: [],
      featurePropertyList: [],
      featureLayerList: [],
      DrawTypeSelected: "Point",
      DrawTypeOptions: [
        {
          value: "Point",
          text: "點",
        },
        {
          value: "LineString",
          text: "線",
        },
        {
          value: "Polygon",
          text: "面",
        },
        {
          value: "Circle",
          text: "圓形",
        },
      ],
      PickerColors: {
        a: 1,
        hex: "#E9B51A",
        hex8: "#E9B51AFF",
        hsl: {
          a: 1,
          h: 44.99999999999996,
          l: 0.50804064,
          s: 0.8260018876355967,
        },
        hsv: {
          a: 1,
          h: 44.99999999999996,
          s: 0.8887999999999999,
          v: 0.9144,
        },
        oldHue: 44.99999999999996,
        rgba: {
          a: 1,
          b: 26,
          g: 181,
          r: 233,
        },
        source: "hsva",
      },
      OpenDrawLayerEditor: false,
      textLayerSetting: {},
      oriDraw: null,
      drawLayerSetting: {
        title: "",
        visible: true,
        editPointColor: false,
        pointColor: "#E9B51A",
        pointRange: 100,
        pointRadius: 7,
        editLineColor: false,
        lineColor: "#E9B51A",
        lineRange: 100,
        lineWidth: 2,
        editAreaColor: false,
        areaColor: "#E9B51A",
        areaRange: 100,
        layer: null,
        drawModify: null,
        myDraw: null,
        index: 0,
      },
      isEditDraw: false,
      drawLayer: null,
      drawLayerSelect: [],
      textLayerSelect: [],
      drawLayerIndex: 0,
      DrawModify: null,
      myDraw: null,
      drawColor: "rgba(255, 204, 51 , 1)",
      OpenTextLayerEditor: false,
      isShowEditText: false,
      isEditText: false,
      textSelect: [],
      textSetting: {
        mouseListen: false,
        title: "",
        titleisText: false,
        text: "",
        isBold: false,
        isItalic: false,
        isUnderline: false,
        isLinethrough: false,
        isBorder: false,
        BorderWidth: 5,
        BorderHex8: "#000000ff",
        BorderColor: "#000000",
        BorderRange: 100,
        editBorderColor: false,
        PositionTop: 100,
        PositionLeft: 100,
        PositionBottom: null,
        PositionRight: null,
        FontSize: 12,
        FontHex8: "#000000ff",
        FontColor: "#000000",
        FontRange: 100,
        editFontColor: false,
        visible: true,
      },
      output: null,
      legendShow: false,
      legendRain: false,
      legendEcho: false,
      legendFire: false,
      legendLand: false,
      legendhasRight: true,
      CommonColorList: [],
      dims: {
        a0: [1189, 841],
        a1: [841, 594],
        a2: [594, 420],
        a3: [420, 297],
        a4: [297, 210],
        a5: [210, 148],
      },
      formatOption: [
        {
          value: "a0",
          text: "A0 (slow)",
        },
        {
          value: "a1",
          text: "A1",
        },
        {
          value: "a2",
          text: "A2",
        },
        {
          value: "a3",
          text: "A3",
        },
        {
          value: "a4",
          text: "A4",
        },
        {
          value: "a5",
          text: "A5 (fast)",
        },
      ],
      formatSelected: "a4",
      resolutionOption: [
        {
          value: "72",
          text: "72 dpi (fast)",
        },
        {
          value: "150",
          text: "150 dpi",
        },
        {
          value: "200",
          text: "200 dpi",
        },
        {
          value: "300",
          text: "300 dpi (slow)",
        },
      ],
      resolutionSelected: "200",
      scaleOption: [
        {
          value: "500",
          text: "1:500,000",
        },
        {
          value: "250",
          text: "1:250,000",
        },
        {
          value: "100",
          text: "1:100,000",
        },
        {
          value: "50",
          text: "1:50,000",
        },
        {
          value: "25",
          text: "1:25,000",
        },
        {
          value: "10",
          text: "1:10,000",
        },
      ],
      scaleSelected: "250",
      compass: false,
      extentLayer: null,
      isCalculate: false,
      compassLayer: null,
      UploadProgress: 0,
      UploadProgressTitle: "",
      UploadProgressShow: false,
      InsertButtonTitle: "新增",
      // 選單 + 工程
      engSearchTypeOptions: ["工程點位", "自訂點位"],
      engSearchTypeSelected: "工程點位",
      engPositionSelected: "wgs84",
      engPositionX: null,
      engPositionY: null,
      engList: [],
      engYearOption: [],
      engYearSelected: null,
      engOptions: [],
      engSelected: null,
      engRadius: 10,
      engLayer: null,
      engCircleLayer: null,
      engPropertyList: [
        {
          oriProperty: "originalVernacularName",
          newProperty: "原始紀錄物種",
        },
        {
          oriProperty: "basisOfRecord",
          newProperty: "紀錄類型",
        },
        {
          oriProperty: "samplingProtocol",
          newProperty: "調查方法",
        },
        {
          oriProperty: "eventDate",
          newProperty: "觀測日期",
        },
        {
          oriProperty: "decimalLatitude",
          newProperty: "緯度(十進位)",
        },
        {
          oriProperty: "decimalLongitude",
          newProperty: "經度(十進位)",
        },
        {
          oriProperty: "recordedBy",
          newProperty: "記錄者",
        },
        {
          oriProperty: "individualCount",
          newProperty: "個體數量",
        },
        {
          oriProperty: "taxonGroup",
          newProperty: "類群",
        },
        {
          oriProperty: "simplifiedScientificName",
          newProperty: "簡學名",
        },
        {
          oriProperty: "vernacularName",
          newProperty: "分類群俗名",
        },
        {
          oriProperty: "protectedStatusTW",
          newProperty: "保育類等級",
        },
        {
          oriProperty: "datasetName",
          newProperty: "所屬資料集",
        },
        {
          oriProperty: "datasetURL",
          newProperty: "資料集網址",
        },
      ],
      engPositionClick: false,
      engSearchTypeEnabled: false,
      engYearEnabled: false,
      engEnabled: false,
      engPositionEnabled: false,
      engPositionXEnabled: false,
      engPositionYEnabled: false,
      engRadiusEnabled: false,
      wmsTip: "",
      showWmsTip: false,
      alertLayers: [],
      WMS_API: (data) => this.userRequest.post("API/Default/WMS_API", data),
      POINT_API: (data) => this.userRequest.post("API/Default/POINT_API", data),
      apiInsertMapLayer: (data) =>
        this.userRequest.post("API/Default/InsertMapLayer", data),
      apiGetMapLayerListForMap2D: (data) =>
        this.userRequest.post("API/Default/GetMapLayerListForMap2D", data),
      apiGetMapLayerListForRoomInMap2D: (data) =>
        this.userRequest.post(
          "API/Default/GetMapLayerListForRoomInMap2D",
          data
        ),
      apiSaveMapUserLayer: (data) =>
        this.userRequest.post("API/Default/SaveMapUserLayer", data),
      apiGetMapLayerListForSearchMap2D: (data) =>
        this.userRequest.post(
          "API/Default/GetMapLayerListForSearchMap2D",
          data
        ),
      apiGetPFPosition: (data) =>
        this.userRequest.post("API/Default/GetPFPosition", data),
      apiGetTriangulationStationList: (data) =>
        this.userRequest.post("API/Default/GetTriangulationStationList", data),
      apiGetOA0040: (data) =>
        this.userRequest.post("API/Default/GetOA0040", data),
      apiGetHDMOS: (data) =>
        this.userRequest.post("API/Default/GetHDMOS", data),
      apiGetFGIS: (data) => this.userRequest.post("API/Default/GetFGIS", data),
      apiGetFFWI: (data) => this.userRequest.post("API/Default/GetFFWI", data),
      apiGetSentinal: (data) =>
        this.userRequest.post("API/Default/GetSentinal", data),
      apiComparison: (data) =>
        this.userRequest.post("API/Default/Comparison", data),
      apiCadastralPositioning: (data) =>
        this.userRequest.post("API/Default/CadastralPositioning", data),
      apiGetMeteorogicalData: (data) =>
        this.userRequest.post("API/Default/GetMeteorogicalData", data),
      apiGetNCDR: (data) => this.userRequest.post("API/Default/GetNCDR", data),
      apiGetEcologicalCheck: (data) =>
        this.userRequest.post("API/Default/GetEcologicalCheck", data),
      apiExportEcological: (data) =>
        this.userRequest.post("API/Default/ExportEcological", data),
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    baseMapSelected() {
      this.setBaseMap(this.baseMapSelected);
    },
    mapSidebarShow() {
      this.sideButtonShow = !this.mapSidebarShow;
    },
    positionSidebarShow() {
      this.sideButtonShow = !this.positionSidebarShow;
    },
    compass() {
      this.setCompass();
    },
    "textLayerSetting.FontSize": function () {
      this.changeTextLayer();
    },
    "textLayerSetting.FontColor": function () {
      this.changeTextLayer();
    },
    "textLayerSetting.text": function () {
      this.changeTextLayer();
    },
    "textLayerSetting.positionX": function () {
      this.changeTextLayer();
    },
    "textLayerSetting.positionY": function () {
      this.changeTextLayer();
    },
    initLoadIndex() {
      if (this.initLoadIndex == this.MapLayerSelect.length) {
        this.mapSetZIndex();
        this.initLoadIndex = 0;
        this.initLoadLength = 0;
        this.isLoad = false;
      }
    },
  },
  methods: {
    init() {
      this.isLoad = true;
      this.initLoadIndex = 0;
      if (this.IsNull(this.$route.query.ML_ID)) {
        let ML_ID = this.$route.query.ML_ID;
        this.FunctionToken(this.GetMapLayerListForRoomInMap2D, {
          ML_ID: ML_ID,
        });
      } else {
        this.FunctionToken(this.GetMapLayerListForMap2D, {});
      }
      this.FunctionToken(this.GetTriangulationStationList, {});
      this.$refs.map.map.on("singleclick", this.MapLayerClick);
      this.FunctionGetTownOption();
      this.GetEngineering();
      this.initEventListener();
    },
    initEventListener() {
      const _this = this;
      document.addEventListener("keydown", function (evt) {
        if (_this.textSetting.mouseListen === true) {
          if (evt.keyCode === 37) {
            console.log("按下 ← 鍵");
            _this.textLayerSetting.positionX -= 0.0005;
          } else if (evt.keyCode === 38) {
            console.log("按下 ↑ 鍵");
            _this.textLayerSetting.positionY += 0.0005;
          } else if (evt.keyCode === 39) {
            console.log("按下 → 鍵");
            _this.textLayerSetting.positionX += 0.0005;
          } else if (evt.keyCode === 40) {
            console.log("按下 ↓ 鍵");
            _this.textLayerSetting.positionY -= 0.0005;
          }
        }
      });
    },
    mapSetZIndex() {
      let count = this.MapLayerSelect.length + 1;
      this.MapLayerSelect.forEach((x) => {
        count--;
        x.Layer.setZIndex(count);
      });
    },
    mapCalculate(value) {
      this.isCalculate = value != "None";
      this.$refs.map.typeSelectOnchange(value);
    },
    clearCalculate() {
      this.$refs.map.measureToolend();
    },
    InsertMap() {
      if (this.IsNull(this.insert.name)) {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0");
        let yyyy = today.getFullYear();
        today = yyyy + "/" + mm + "/" + dd;
        this.formData.append("ML_TYPE", "使用者圖層");
        this.formData.append("ML_CATEGORY", this.insert.selected);
        this.formData.append("ML_NAME", this.insert.name);
        this.formData.append("ML_LAYERMODE", 2);
        this.formData.append("ML_ENDDATE", today);
        if (this.insert.selected == "WMS") {
          if (this.IsNull(this.insert.wmsUrl)) {
            this.formData.append("ML_URL", this.insert.wmsUrl);
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.ShowAlertModal("請填寫URL");
          }
        } else if (this.insert.selected == "SHP") {
          if (this.IsNull(this.insert.shpFile)) {
            this.formData.append(this.insert.shpFile.name, this.insert.shpFile);
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.ShowAlertModal("請上傳SHP檔案");
          }
        } else if (this.insert.selected == "KML") {
          if (this.IsNull(this.insert.kmlFile)) {
            this.formData.append(this.insert.kmlFile.name, this.insert.kmlFile);
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.ShowAlertModal("請上傳KML檔案");
          }
        } else if (this.insert.selected == "GPX") {
          if (this.IsNull(this.insert.gpxFile)) {
            this.formData.append(this.insert.gpxFile.name, this.insert.gpxFile);
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.ShowAlertModal("請上傳GPX檔案");
          }
        } else if (this.insert.selected == "GEOJSON") {
          if (this.IsNull(this.insert.geoJson)) {
            this.formData.append(this.insert.geoJson.name, this.insert.geoJson);
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.ShowAlertModal("請上傳geoJson檔案");
          }
        } else if (this.insert.selected == "IMAGE") {
          if (
            this.IsNull(this.insert.imageData.x) !== null &&
            this.IsNull(this.insert.imageData.y) !== null
          ) {
            this.formData.append("ML_CENTER_X", this.insert.imageData.x);
            this.formData.append("ML_CENTER_Y", this.insert.imageData.y);
            if (this.IsNull(this.insert.imageFile)) {
              this.formData.append(
                this.insert.imageFile.name,
                this.insert.imageFile
              );
              this.FunctionToken(this.InsertMapLayer, {});
            } else {
              this.ShowAlertModal("請上傳圖片檔");
            }
          } else {
            this.ShowAlertModal("請輸入圖片座標");
          }
        } else if (this.insert.selected == "圖片批次上傳") {
          if (this.IsNull(this.insert.imageMultiData)) {
            this.formData.append(
              this.insert.imageMultiData.name,
              this.insert.imageMultiData
            );
            this.FunctionToken(this.InsertMapLayer, {});
          } else {
            this.ShowAlertModal("請上傳zip檔案");
          }
        }
      } else {
        this.ShowAlertModal("請填寫圖層名稱");
      }
    },
    FunctionChangeInsertType() {
      if (this.insert.selected == "圖片批次上傳") this.insert.name = "nope";
    },
    FunctionSearch() {
      let json = {
        keyword: this.searchMap,
        MU_MAPSELECTED: this.mapSelect.toString(),
      };
      this.FunctionToken(this.GetMapLayerListForSearchMap2D, json);
    },
    FunctionSBCShow(value) {
      let active = this.BtnActive;
      switch (value) {
        case 0:
          if (!active[0]) {
            this.sideBarContentShow = [true, false, false, false];
            this.BtnActive = [true, false, false, false];
          } else {
            this.SideBarCancel();
          }
          break;
        case 1:
          if (!active[1]) {
            this.sideBarContentShow = [false, true, false, false];
            this.BtnActive = [false, true, false, false];
          } else {
            this.SideBarCancel();
          }
          break;
        case 2:
          if (!active[2]) {
            this.sideBarContentShow = [false, false, true, false];
            this.BtnActive = [false, false, true, false];
          } else {
            this.SideBarCancel();
          }
          break;
        case 3:
          if (!active[3]) {
            this.sideBarContentShow = [false, false, false, true];
            this.BtnActive = [false, false, false, true];
          } else {
            this.SideBarCancel();
          }
          break;
      }
    },
    SideBarCancel() {
      this.sideBarContentShow = [false, false, false, false];
      this.BtnActive = [false, false, false, false];
    },
    mapSetStyle(value) {
      console.log(value);
      if (value.layerColor == "") {
        value.layerColor = null;
      }
      var newStyle;
      if (value.styleType.toLowerCase() == "point") {
        newStyle = new Style({
          image: new CircleStyle({
            radius: value.pointRadius,
            fill: new Fill({ color: value.layerColor }),
            stroke: new Stroke({ color: "black", width: 1 }),
          }),
        });
      } else if (
        value.styleType.toLowerCase() == "line" ||
        value.styleType.toLowerCase() == "linestring" ||
        value.styleType.toLowerCase() == "multilinestring"
      ) {
        newStyle = new Style({
          stroke: new Stroke({
            color: value.layerColor,
            width: value.lineWidth,
          }),
        });
      } else {
        newStyle = new Style({
          fill: new Fill({
            color: value.layerColor,
          }),
          stroke: new Stroke({
            color: "black",
          }),
        });
      }

      // value.Layer.setStyle(newStyle);
      value.Layer.getSource()
        .getFeatures()
        .forEach((feature) => {
          let alertLayer = this.alertLayers.find((f) => f.feature === feature);
          if (alertLayer) {
            alertLayer.oriStyle = newStyle;
            alertLayer.feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: "#00fefa",
                  width: 8,
                }),
                fill: newStyle.getFill(),
              })
            );
          } else {
            feature.setStyle(newStyle);
          }
        });
    },
    //調整透明度
    mapSetOpacity(value) {
      const opacity = parseFloat(value.rangeValue);
      value.Layer.setOpacity(opacity / 100.0);
    },
    //調整顯示狀態
    mapSetVisible(value) {
      value.Layer.setVisible(value.layerVisible);
    },
    deleteLayerList(newValue) {
      let index = -1;
      for (let i in this.MapLayerSelect) {
        if (this.MapLayerSelect[i].ML_ID == newValue.ML_ID) {
          index = i;
        }
      }
      let next = true;
      this.MapLayer.data.forEach((x) => {
        x.data.forEach((a) => {
          if (newValue.ML_ID == a.ML_ID) {
            a.pointRadius = 10;
            a.lineWidth = 2;
            a.layerColor = "rgba(52, 58, 64, 1)";
            a.rangeValue = "100";
            a.zINdex = null;
            a.layerVisible = true;
            next = false;
          }
        });
      });
      if (next) {
        this.MapLayer1.forEach((x) => {
          x.data.forEach((a) => {
            if (newValue.ML_ID == a.ML_ID) {
              a.pointRadius = 10;
              a.lineWidth = 2;
              a.layerColor = "rgba(52, 58, 64, 1)";
              a.rangeValue = "100";
              a.zINdex = null;
              a.layerVisible = true;
              next = false;
            }
          });
        });
      }
      if (next) {
        this.MapLayer0.forEach((x) => {
          x.data.forEach((a) => {
            if (newValue.ML_ID == a.ML_ID) {
              a.pointRadius = 10;
              a.lineWidth = 2;
              a.layerColor = "rgba(52, 58, 64, 1)";
              a.rangeValue = "100";
              a.zINdex = null;
              a.layerVisible = true;
              next = false;
            }
          });
        });
      }

      if (next) {
        this.outLayer.data.forEach((a) => {
          if (newValue.ML_ID == a.ML_ID) {
            a.pointRadius = 10;
            a.lineWidth = 2;
            a.layerColor = "rgba(52, 58, 64, 1)";
            a.rangeValue = "100";
            a.zINdex = null;
            a.layerVisible = false;
            next = false;
          }
        });
      }

      if (index !== -1) {
        this.MapLayerSelect.splice(index, 1);
      }
      let newArr = [];
      let newArr1 = [];
      this.MapLayerSelect.forEach((x) => {
        newArr.push(x);
        newArr1.push(x.ML_ID);
      });

      this.MapLayerSelect = newArr;
      this.mapSelect = newArr1;
      this.$refs.map.map.removeLayer(newValue.Layer);
      this.forestChange(newValue);
      this.FunctionLegendShow();
    },
    forestSetChecked(newValue) {
      newValue.expend = true;
      if (newValue.checkAll) {
        newValue.data.forEach((x) => {
          var element = this.mapSelect.find((element) => element == x.ML_ID);
          if (element == undefined) {
            this.mapSelect.push(x.ML_ID);
            this.changeMap(x);
          }
        });
      } else {
        this.MapLayer.data.forEach((x) => {
          if (x.title == newValue.title && newValue.expend) {
            for (let i = 0; i < x.data.length; i++) {
              this.mapSelect = this.mapSelect.filter(function (currentChar) {
                return currentChar !== x.data[i].ML_ID;
              });
            }
            newValue.data.forEach((x) => {
              this.changeMap(x);
            });
          }
        });
      }
    },
    forestChange(newValue, value) {
      var element = this.mapSelect.find((element) => element == newValue.ML_ID);
      //單一未選
      if (element == undefined) {
        for (let i = 0; i < this.MapLayer.data.length; i++) {
          this.MapLayer.data[i].data.forEach((x) => {
            if (newValue.parentTitle == x.parentTitle) {
              this.MapLayer.data[i].checkAll = false;
            }
          });
        }
      }
      //全選
      else {
        let arrayNum = 0;
        let count = 0;
        let dataLength;
        for (let i = 0; i < this.MapLayer.data.length; i++) {
          this.MapLayer.data[i].data.forEach((q) => {
            var element = this.mapSelect.find((element) => element == q.ML_ID);
            if (element != undefined) {
              if (q.parentTitle == newValue.parentTitle) {
                dataLength = this.MapLayer.data[i].data.length;
                arrayNum = i;
                count++;
              }
            }
          });
        }
        if (count == dataLength) {
          this.MapLayer.data[arrayNum].checkAll = true;
        }
      }
      if (value == "setChange") {
        this.changeMap(newValue);
      }
    },
    changeMap(newValue) {
      console.log(newValue);

      let isAPILayer = false;
      if (this.initLoadLength == 0) this.isLoad = true;
      var element = this.mapSelect.find((element) => element == newValue.ML_ID);
      if (element == undefined) {
        var isDelete = false;
        if (newValue.Layer != undefined) {
          isDelete = true;
          this.$refs.map.map.removeLayer(newValue.Layer);
          if (newValue.layerType == "IMAGE") {
            this.$refs.map.map.removeInteraction(newValue.modify);
          }
          newValue.modify = null;
        } else {
          const eleLayer = this.MapLayerSelect.find(
            (x) => x.ML_ID == newValue.ML_ID
          );
          if (eleLayer !== undefined) {
            this.$refs.map.map.removeLayer(eleLayer.Layer);
            if (newValue.layerType == "IMAGE") {
              this.$refs.map.map.removeInteraction(newValue.modify);
            }
            newValue.modify = null;
            isDelete = true;
          }
        }
        if (isDelete) {
          let index = -1;
          for (let i in this.MapLayerSelect) {
            if (this.MapLayerSelect[i].ML_ID == newValue.ML_ID) {
              index = i;
            }
          }
          if (index !== -1) {
            this.MapLayerSelect.splice(index, 1);
            newValue.Layer = undefined;
          }
        }
      } else {
        if (newValue.layerType == "WMS") {
          const newWMSSource = new TileWMS({
            url: newValue.url,
            params: JSON.parse(newValue.param),
            serverType: "geoserver",
            overlaps: true,
          });
          var hasShownWarning = false;
          newWMSSource.on("tileloaderror", function () {
            if (newValue.source === "外部圖資" && !hasShownWarning) {
              hasShownWarning = true;
              alert("外部圖資回應延遲，請稍後再試");
            }
          });
          var Layer = new TileLayer({
            title: newValue.title,
            visible: newValue.layerVisible,
            opacity: 1,
            source: newWMSSource,
          });
          if (newValue.title == "地籍圖臺東縣(110年)") {
            Layer.setMinResolution(0.00000104858); // 1/2000
            Layer.setMaxResolution(0.00000524293); // 1/400
            this.showWmsTip = true;
            this.wmsTip =
              "請將地圖畫面放大至比例尺1/400~1/2000範圍內，即可顯示地籍圖資訊";
          }
          if (newValue.Layer == undefined) {
            newValue.Layer = Layer;
          }
          this.$refs.map.map.addLayer(newValue.Layer);
          if (this.initLoadLength !== 0) this.initLoadIndex++;
        } else if (newValue.layerType == "WMTS") {
          let sourceXYZ = new XYZ({
            url: newValue.url,
            wrapX: false,
            crossOrigin: "anonymous",
          });
          sourceXYZ.on("tileloaderror", function () {
            if (newValue.source === "外部圖資") {
              alert("外部圖資回應延遲，請稍後再試");
            }
          });
          const Layer = new TileLayer({
            source: sourceXYZ,
            visible: newValue.layerVisible,
            opacity: 0.5,
          });
          if (newValue.Layer == undefined) {
            newValue.Layer = Layer;
          }
          this.$refs.map.map.addLayer(newValue.Layer);
          if (this.initLoadLength !== 0) this.initLoadIndex++;
        } else if (newValue.layerType == "IMAGE") {
          const geom = new Point([newValue.x, newValue.y - 0.000001]);
          const feature = new Feature(geom);
          feature.setStyle(
            new Style({
              image: new Icon({
                anchor: [0.5, 1],
                scale: 0.1,
                src: newValue.url,
              }),
            })
          );
          const geom2 = new Point([newValue.x, newValue.y]);
          const featurePoint = new Feature(geom2);
          featurePoint.setStyle(
            new Style({
              image: new Icon({
                anchor: [0.5, 1],
                scale: 0.1,
                src: "openlayer/img09.png",
              }),
            })
          );
          let newSource = new VectorSource({
            features: [feature, featurePoint],
            wrapX: false,
          });
          let newLayer = new VectorLayer({
            source: newSource,
          });

          newSource.on("featuresloaderror", function () {
            if (newValue.source === "外部圖資") {
              alert("外部圖資回應延遲，請稍後再試");
            }
          });
          if (newValue.Layer == undefined) {
            newValue.Layer = newLayer;
          }
          this.$refs.map.map.addLayer(newValue.Layer);

          newValue.modify = new Modify({
            hitDetection: newValue.Layer,
            source: newSource,
          });
          this.$refs.map.map.addInteraction(newValue.modify);

          if (this.initLoadLength !== 0) this.initLoadIndex++;
        } else if (newValue.layerType == "API") {
          isAPILayer = true;
          setTimeout(() => {
            this.changeMapForAPI(newValue);
          }, "2000");
        } else {
          var newStyle;
          if (newValue.styleType.toLowerCase() == "point") {
            newStyle = new Style({
              image: new CircleStyle({
                radius: newValue.pointRadius,
                fill: new Fill({ color: newValue.layerColor }),
                stroke: new Stroke({ color: "black", width: 1 }),
              }),
            });
          } else if (
            newValue.styleType.toLowerCase() == "line" ||
            newValue.styleType.toLowerCase() == "linestring" ||
            newValue.styleType.toLowerCase() == "multilinestring"
          ) {
            newStyle = new Style({
              stroke: new Stroke({
                color: newValue.layerColor,
                width: newValue.lineWidth,
              }),
            });
          } else {
            newStyle = new Style({
              fill: new Fill({
                color: newValue.layerColor,
              }),
              stroke: new Stroke({
                color: "black",
              }),
            });
          }
          let newLayer;
          const opacity = parseFloat(newValue.rangeValue) / 100.0;
          if (newValue.layerType == "KML") {
            var newKMLSource = new VectorSource({
              url: newValue.url,
              format: new KML({
                extractStyles: false,
              }),
              visible: newValue.layerVisible,
              overlaps: true,
            });

            newKMLSource.on("featuresloaderror", function () {
              if (newValue.source === "外部圖資") {
                alert("外部圖資回應延遲，請稍後再試");
              }
            });

            newLayer = new VectorLayer({
              source: newKMLSource,
              style: newStyle,
              opacity: opacity,
            });
          } else {
            var newSource = new VectorSource({
              url: newValue.url,
              format: new GeoJSON(),
              visible: newValue.layerVisible,
              overlaps: true,
            });
            newSource.on("featuresloaderror", function () {
              if (newValue.source === "外部圖資") {
                alert("外部圖資回應延遲，請稍後再試");
              }
            });
            newLayer = new VectorLayer({
              source: newSource,
              style: newStyle,
              opacity: opacity,
            });
          }
          if (newValue.Layer == undefined) {
            newValue.Layer = newLayer;
          }

          newValue.Layer.on("error", function (event) {
            console.log("地图图层加载错误:", event.error);
          });
          this.$refs.map.map.addLayer(newValue.Layer);
          if (this.initLoadLength !== 0) this.initLoadIndex++;
        }

        newValue.collapseExpend = false;
        this.MapLayerSelect.unshift(newValue);
      }
      if (this.initLoadLength == 0) {
        this.mapSetZIndex();
        if (!isAPILayer) this.isLoad = false;
      }
      this.FunctionLegendShow();
    },
    changeMapForAPI(newValue) {
      if (newValue.url === "GetOA0040") {
        this.FunctionToken(this.GetOA0040, {
          ML_ID: newValue.ML_ID,
          source: newValue.source,
        });
      } else if (newValue.url === "GetFFWI") {
        this.FunctionToken(this.GetFFWI, {
          ML_ID: newValue.ML_ID,
          source: newValue.source,
        });
      } else if (newValue.url === "GetSentinal") {
        this.FunctionToken(this.GetSentinal, {
          ML_ID: newValue.ML_ID,
          source: newValue.source,
        });
      } else if (newValue.url === "GetHDMOS") {
        this.FunctionToken(this.GetHDMOS, {
          ML_ID: newValue.ML_ID,
          source: newValue.source,
        });
      } else if (newValue.url === "W_C0034_005") {
        this.FunctionToken(this.GetMeteorogicalData, {
          TYPE: "W-C0034-005",
          newValue: newValue,
          source: newValue.source,
        });
      } else if (newValue.url === "O-B0070-001") {
        this.FunctionToken(this.GetMeteorogicalData, {
          TYPE: "O-B0070-001",
          newValue: newValue,
          source: newValue.source,
        });
      } else if (newValue.url === "W-C0034-002") {
        this.FunctionToken(this.GetMeteorogicalDataKmz, {
          TYPE: "W-C0034-002",
          newValue: newValue,
          source: newValue.source,
        });
      } else if (newValue.url === "E-A0014-001") {
        this.FunctionToken(this.GetMeteorogicalData, {
          TYPE: "E-A0014-001",
          newValue: newValue,
          source: newValue.source,
        });
      } else if (newValue.url === "ForestFire") {
        this.FunctionToken(this.GetNCDR, {
          TYPE: "ForestFire",
          newValue: newValue,
          source: newValue.source,
        });
      } else if (newValue.url === "LandslideAlert") {
        this.FunctionToken(this.GetNCDR, {
          TYPE: "LandslideAlert",
          newValue: newValue,
          source: newValue.source,
        });
      } else if (newValue.url === "API_DEM") {
        const sourceStatic = new Static({
          url: "Upload/TaitangDem/Layer0.png",
          projection: "EPSG:4326",
          imageExtent: [
            120.6476349630478, 21.7946228149194, 121.7099313417917,
            23.59510155914149,
          ],
        });
        sourceStatic.on("imageloaderror", function () {
          if (newValue.source === "外部圖資") {
            alert("外部圖資回應延遲，請稍後再試");
          }
        });
        var newLayer = new ImageLayer({
          source: sourceStatic,
          visible: true,
        });
        if (newValue.Layer == undefined) {
          newValue.Layer = newLayer;
        }
        this.$refs.map.map.addLayer(newLayer);
        this.isLoad = false;
      }
    },
    styleFunction(feature) {
      const styles = {
        Point: new Style({
          image: new CircleStyle({
            radius: 10,
            fill: new Fill({ color: this.colorInit }),
            stroke: new Stroke({ color: "black", width: 1 }),
          }),
        }),
        LineString: new Style({
          stroke: new Stroke({ color: this.colorInit, width: 2 }),
        }),
        Polygon: new Style({
          stroke: new Stroke({ color: "black", width: 1 }),
          fill: new Fill({ color: this.colorInit }),
        }),
      };

      return [styles[feature.getGeometry().getType()]];
    },
    setPosition() {
      if (this.locationSelected == 0) {
        if (this.IsNull(this.mapXPosition) && this.IsNull(this.mapYPosition)) {
          let position = [];
          if (this.locationSelected == 0) {
            let x = parseFloat(this.mapXPosition);
            let y = parseFloat(this.mapYPosition);
            position = [x, y];
            if (this.positionSelected == "twd97") {
              position = this.$refs.map.Pro4Transform(
                "EPSG:3826",
                "EPSG:4326",
                position
              );
            }
          }
          this.$refs.map.fitTo(position, 15);
          this.$refs.map.ClearAllIconFeature();
          this.$refs.map.CreateIconFeature(position);
        } else {
          this.ShowAlertModal("請輸入座標");
        }
      } else if (this.locationSelected == 1) {
        if (
          this.forestSelected !== undefined &&
          this.forestSelected !== null &&
          this.forestSelected !== "" &&
          this.forestSelected !== "請選擇"
        ) {
          let findP = this.MapLayer.data.find(
            (x) => x.title == this.forestSelected
          );
          let findPP = findP.data[findP.data.length - 1];
          let findM = this.MapLayerSelect.find((x) => x.ML_ID == findPP.ML_ID);
          if (findM != undefined) {
            if (!findM.layerVisible) findM.layerVisible = true;
          } else {
            this.mapSelect.push(findPP.ML_ID);
            this.changeMap(findPP);
          }
          this.FunctionToken(this.GetPFPosition, {
            PF_NAME: this.forestSelected,
          });
        } else {
          alert("請選擇要定位的保安林編號");
        }
      } else if (this.locationSelected == 2) {
        const find = this.triangulationStationOptions.find(
          (x) => x.text == this.triangulationStationSelected
        );
        this.$refs.map.fitTo(find.pos, 15);
        this.$refs.map.ClearAllIconFeature();
        this.$refs.map.CreateIconFeature(find.pos);
      } else if (this.locationSelected == 3) {
        if (this.lotSelected != null && this.placeNumberSelected != null) {
          this.isLoad = true;
          if (this.locationLayer != null) {
            this.$refs.map.map.removeLayer(this.locationLayer);
            this.locationLayer = null;
          }
          let json = {
            TYPE: "LOCATION",
            CADASTRAL_POSITIONING_SELECT: {
              CL_ID: this.lotSelected,
              placeNumber: this.placeNumberSelected,
            },
          };
          this.FunctionToken(this.CadastralPositioning, json);
        } else alert("請選擇地段並輸入地號");
      } else if (this.locationSelected == 4) {
        let msg = "";

        if (
          this.engRadius != undefined &&
          this.engRadius != null &&
          this.engRadius != 0
        ) {
          let lat = 0,
            lon = 0;
          if (this.engSearchTypeSelected == "工程點位") {
            if (this.engSelected != null && this.engSelected != "") {
              let search = this.engList.filter(
                (x) => x.eng_name == this.engSelected
              );
              if (search.length == 1) {
                let latlon = this.UTM2LatLon(search[0].x1, search[0].y1, 0, 0);
                lat = latlon[0];
                lon = latlon[1];
              } else {
                msg = "查詢筆數不符 \r\n";
              }
            } else {
              msg = "請選擇查詢工程 \r\n";
            }
          } else {
            if (this.engPositionX != null && this.engPositionY != null) {
              if (this.engPositionType == "twd97") {
                let x = parseFloat(this.engPositionX);
                let y = parseFloat(this.engPositionY);
                let position = [x, y];
                position = this.$refs.map.Pro4Transform(
                  "EPSG:3826",
                  "EPSG:4326",
                  position
                );
                lon = position[0];
                lat = position[1];
              } else {
                lon = parseFloat(this.engPositionX);
                lat = parseFloat(this.engPositionY);
              }
            }
          }
          this.isLoad = true;
          this.FunctionToken(this.GetEcologicalCheck, {
            lat: lat,
            lon: lon,
            radius: this.engRadius,
          });
        } else {
          msg = "請輸入半徑 \r\n";
        }

        if (msg != "") {
          this.ShowAlertModal(msg);
        } else {
          this.engSearchTypeEnabled = true;
          this.engYearEnabled = true;
          this.engEnabled = true;
          this.engPositionEnabled = true;
          this.engPositionXEnabled = true;
          this.engPositionYEnabled = true;
          this.engRadiusEnabled = true;
          this.engPositionClick = false;
        }
      }
    },
    clearPosition() {
      this.$refs.map.ClearAllIconFeature();
      if (this.locationLayer != null) {
        this.$refs.map.map.removeLayer(this.locationLayer);
        this.locationLayer = null;
      }
      if (this.engLayer != null) {
        this.$refs.map.map.removeLayer(this.engLayer);
        this.engLayer = null;
      }
      if (this.engCircleLayer != null) {
        this.$refs.map.map.removeLayer(this.engCircleLayer);
        this.engCircleLayer = null;
      }

      this.engSearchTypeEnabled = false;
      this.engYearEnabled = false;
      this.engEnabled = false;
      this.engPositionEnabled = false;
      this.engPositionXEnabled = false;
      this.engPositionYEnabled = false;
      this.engRadiusEnabled = false;
      this.engPositionClick = true;
    },
    setBaseMap(value) {
      this.$refs.map.ChangeBasemap(value);
    },
    SaveMapLayer() {
      this.isLoad = true;
      let arr = [];
      this.MapLayerSelect.forEach((x) => {
        let zINdex = x.Layer.getZIndex();
        let obj = {
          MU_MLID: x.ML_ID,
          MU_POINTRADIUS: x.pointRadius,
          MU_LINEWIDTH: x.lineWidth,
          MU_LAYERCOLOR: x.layerColor,
          MU_LAYERVISIBLE: x.layerVisible,
          MU_RANGEVALUE: x.rangeValue,
          MU_ZINDEX: zINdex,
          MU_STYLE_TYPE: x.styleType,
        };
        arr.push(obj);
      });
      let json = {
        MU_LAYERSELECTED: JSON.stringify(arr),
      };
      this.FunctionToken(this.SaveMapUserLayer, json);
    },
    MapLayerClick(evt) {
      if (evt.dragging) return; // 排除拖曳

      if (this.textSetting.mouseListen) {
        this.textLayerSetting.positionX = evt.coordinate[0];
        this.textLayerSetting.positionY = evt.coordinate[1];
      } else if (this.engPositionClick) {
        this.engPositionX = evt.coordinate[0];
        this.engPositionY = evt.coordinate[1];
      } else if (!this.isCalculate && !this.OpenDrawLayerEditor) {
        this.featureList = [];

        this.$refs.map.map.forEachFeatureAtPixel(
          evt.pixel,
          (feature, layer) => {
            this.featureList.push({
              properties: feature.getProperties(),
              layer: layer,
            });

            // 醒目提醒功能...
            let alertLayer = {};
            let style = feature.getStyle()
              ? feature.getStyle()
              : layer.getStyle();
            if (style) {
              let color = "";
              if (style.getImage()) {
                color = style.getImage().getStroke().getColor();
                console.log(color);
                feature.setStyle(
                  new Style({
                    image: new CircleStyle({
                      radius: 10,
                      stroke: new Stroke({
                        color: "#00fefa",
                        width: 8,
                      }),
                      fill: style.getImage().getFill(),
                    }),
                  })
                );
              } else {
                color = style.getStroke().getColor();
                feature.setStyle(
                  new Style({
                    stroke: new Stroke({
                      color: "#00fefa",
                      width: 8,
                    }),
                    fill: style.getFill(),
                  })
                );
              }

              alertLayer.oriStyle = color;
              alertLayer.feature = feature;
              alertLayer.layer = layer;
              this.alertLayers.push(alertLayer);
            }
          }
        );

        const _this = this;
        let wmsLayers = this.MapLayerSelect.filter(
          (x) => x.layerType == "WMS" && x.layerVisible == true
        );
        if (wmsLayers && wmsLayers.length > 0) {
          wmsLayers.forEach((wms) => {
            let url = wms.Layer.getSource().getFeatureInfoUrl(
              evt.coordinate,
              _this.$refs.map.map.getView().getResolution(),
              _this.$refs.map.map.getView().getProjection(),
              { INFO_FORMAT: "application/json" }
            );
            if (url) {
              if (url.includes("REQUEST=GetMap"))
                url = url.replace("REQUEST=GetMap", "REQUEST=GetFeatureInfo");
              fetch(url)
                .then((response) => response.json())
                .then((json) => {
                  if (json.features.length > 0) {
                    _this.featureList.push({
                      properties: json.features[0].properties,
                      layer: wms.Layer,
                    });
                    _this.FunctionShowProperty();
                  } else {
                    _this.FunctionShowProperty();
                  }
                })
                .catch(() => {
                  _this.FunctionShowProperty();
                });
            } else {
              _this.FunctionShowProperty();
            }
          });
        } else {
          _this.FunctionShowProperty();
        }
      }
    },
    FunctionShowProperty() {
      if (!this.featureList) return;

      this.featureInfoArray = [];
      for (let i = 0; i < this.featureList.length; i++) {
        let featureInfoItems = [];
        let headerTitle = "標題";
        let newLayerObject = this.MapLayerSelect.find(
          (s) => s.Layer === this.featureList[i].layer
        );
        let hasPropertyList = false;

        // 提取點擊目標的屬性
        Object.keys(this.featureList[i].properties).forEach((key) => {
          let newKey = key;
          if (key !== "geometry" && key !== "headertitle") {
            if (
              newLayerObject &&
              newLayerObject.propertyList &&
              newLayerObject.propertyList.length > 0
            ) {
              let property = newLayerObject.propertyList.find(
                (p) => p.oriProperty === key
              );
              if (property) {
                newKey = property.newProperty;
              }
              hasPropertyList = true;
            } else {
              if (
                this.locationLayer &&
                this.locationLayer == this.featureList.layer
              ) {
                headerTitle = "地籍定位";
              } else {
                let property = this.engPropertyList.find(
                  (p) => p.oriProperty === key
                );
                if (property) {
                  newKey = property.newProperty;
                }

                hasPropertyList = true;
              }
            }

            featureInfoItems.push({
              title: key,
              newTitle: newKey,
              text: this.featureList[i].properties[key],
            });
          }

          if (
            key === "headertitle" ||
            key === "vernacularName" ||
            key === "originalVernacularName"
          ) {
            headerTitle = this.featureList[i].properties[key];
          }
        });

        this.featureInfoArray.push({
          title: headerTitle,
          data: featureInfoItems,
          hasPropertyList: hasPropertyList,
        });
      }

      if (this.featureInfoArray && this.featureInfoArray.length > 0) {
        this.$refs["modal-featureInfo"].show();
      }
    },
    CancelAlertLayer() {
      this.alertLayers.forEach((x) => {
        let style = x.feature.getStyle();
        if (style) {
          if (style.getImage()) {
            x.feature.setStyle(
              new Style({
                image: new CircleStyle({
                  radius: 10,
                  fill: style.getImage().getFill(),
                  stroke: new Stroke({
                    color: x.oriStyle,
                    width: 1,
                  }),
                }),
              })
            );
          } else {
            style.setStroke(
              new Stroke({
                color: x.oriStyle,
                width: 1,
              })
            );
            x.feature.setStyle(style);
          }
        }
      });
      this.alertLayers = [];
    },
    FunctionLayerSetPosition(item) {
      if (item.layerType != "WMS") {
        const extent = item.Layer.getSource();
        if (extent.imageExtent_) {
          this.$refs.map.view.fit(extent.imageExtent_);
        } else {
          const pos = extent.getExtent();
          if (pos[0] === pos[2] || pos[1] === pos[3]) {
            this.$refs.map.view.animate({
              center: [pos[0], pos[1]],
              duration: 1000,
            });
          } else {
            this.$refs.map.view.fit(extent.getExtent());
          }
        }
      }
    },
    // ===============================
    FunctionDrawTabChange() {
      this.mapCalculate("None");
    },
    FunctionPickerColor() {
      if (this.drawLayerSetting.editPointColor) {
        this.drawLayerSetting.pointColor = this.PickerColors.hex8;
        this.drawLayerSetting.editPointColor = false;
        this.DrawSetStyle(this.drawLayerSetting);
      }
      if (this.drawLayerSetting.editLineColor) {
        this.drawLayerSetting.lineColor = this.PickerColors.hex8;
        this.drawLayerSetting.editLineColor = false;
        this.DrawSetStyle(this.drawLayerSetting);
      }
      if (this.drawLayerSetting.editAreaColor) {
        this.drawLayerSetting.areaColor = this.PickerColors.hex8;
        this.drawLayerSetting.editAreaColor = false;
        this.DrawSetStyle(this.drawLayerSetting);
      }
      if (this.textSetting.editBorderColor) {
        this.textSetting.BorderColor = this.PickerColors.hex8;
        this.textSetting.editBorderColor = false;
      }
      if (this.textSetting.editFontColor) {
        this.textSetting.FontColor = this.PickerColors.hex8;
        this.textSetting.editFontColor = false;
      }
      if (this.textSetting.editBackgroundColor) {
        this.textSetting.BackgroundColor = this.PickerColors.hex8;
        this.textSetting.editBackgroundColor = false;
      }
    },
    FunctionDrawStart() {
      this.OpenDrawLayerEditor = true;
      this.drawLayerSetting = {
        title: "圖層" + (this.drawLayerSelect.length + 1),
        visible: true,
        editPointColor: false,
        pointColor: "#E9B51A",
        pointRange: 100,
        pointRadius: 7,
        editLineColor: false,
        lineColor: "#E9B51A",
        lineRange: 100,
        lineWidth: 2,
        editAreaColor: false,
        areaColor: "#E9B51A",
        areaRange: 100,
        layer: null,
        drawModify: null,
        myDraw: null,
        id: Date.now(),
      };
      this.drawLayerSetting.layer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
          fill: new Fill({
            color: this.drawLayerSetting.areaColor,
          }),
          stroke: new Stroke({
            color: this.drawLayerSetting.lineColor,
            width: this.drawLayerSetting.lineWidth,
          }),
          image: new CircleStyle({
            radius: this.drawLayerSetting.pointRadius,
            fill: new Fill({
              color: this.drawLayerSetting.pointColor,
            }),
          }),
        }),
        visible: this.drawLayerSetting.visible,
        zIndex: 99,
      });
      this.$refs.map.map.addLayer(this.drawLayerSetting.layer);
      const target = this; // this.$refs.map
      this.drawLayerSetting.myDraw = {
        init: function () {
          target.$refs.map.map.addInteraction(this.Point);
          this.Point.setActive(false);
          target.$refs.map.map.addInteraction(this.LineString);
          this.LineString.setActive(false);
          target.$refs.map.map.addInteraction(this.Polygon);
          this.Polygon.setActive(false);
          target.$refs.map.map.addInteraction(this.Circle);
          this.Circle.setActive(false);
          target.$refs.map.map.addInteraction(this.Square);
          this.Square.setActive(false);
          target.$refs.map.map.addInteraction(this.Box);
          this.Box.setActive(false);
          target.$refs.map.map.addInteraction(this.Star);
          this.Star.setActive(false);
          target.$refs.map.map.addInteraction(this.FreeHand);
          this.FreeHand.setActive(false);
        },
        Point: new Draw({
          source: target.drawLayerSetting.layer.getSource(),
          type: "Point",
        }),
        LineString: new Draw({
          source: target.drawLayerSetting.layer.getSource(),
          type: "LineString",
        }),
        Polygon: new Draw({
          source: target.drawLayerSetting.layer.getSource(),
          type: "Polygon",
        }),
        Circle: new Draw({
          source: target.drawLayerSetting.layer.getSource(),
          type: "Circle",
        }),
        Square: new Draw({
          source: target.drawLayerSetting.layer.getSource(),
          type: "Circle",
          geometryFunction: createRegularPolygon(4),
        }),
        Box: new Draw({
          source: target.drawLayerSetting.layer.getSource(),
          type: "Circle",
          geometryFunction: createBox(),
        }),
        Star: new Draw({
          source: target.drawLayerSetting.layer.getSource(),
          type: "Circle",
          geometryFunction: function (coordinates, geometry) {
            const center = coordinates[0];
            const last = coordinates[coordinates.length - 1];
            const dx = center[0] - last[0];
            const dy = center[1] - last[1];
            const radius = Math.sqrt(dx * dx + dy * dy);
            const rotation = Math.atan2(dy, dx);
            const newCoordinates = [];
            const numPoints = 12;
            for (let i = 0; i < numPoints; ++i) {
              const angle = rotation + (i * 2 * Math.PI) / numPoints;
              const fraction = i % 2 === 0 ? 1 : 0.5;
              const offsetX = radius * fraction * Math.cos(angle);
              const offsetY = radius * fraction * Math.sin(angle);
              newCoordinates.push([center[0] + offsetX, center[1] + offsetY]);
            }
            newCoordinates.push(newCoordinates[0].slice());
            if (!geometry) {
              geometry = new Polygon([newCoordinates]);
            } else {
              geometry.setCoordinates([newCoordinates]);
            }
            return geometry;
          },
        }),
        FreeHand: new Draw({
          source: target.drawLayerSetting.layer.getSource(),
          type: "LineString",
          freehand: true,
        }),
        activeDraw: null,
        setActive: function (active) {
          if (this.activeDraw) {
            this.activeDraw.setActive(false);
            this.activeDraw = null;
          }
          if (active) {
            const type = target.DrawTypeSelected;
            this.activeDraw = this[type];
            this.activeDraw.setActive(true);
          }
        },
      };
      this.drawLayerSetting.myDraw.init();
      this.drawLayerSetting.myDraw.setActive(true);
      const snap = new Snap({
        source: this.drawLayerSetting.layer.getSource(),
      });
      this.$refs.map.map.addInteraction(snap);

      this.drawLayerSetting.ur = new OlExtUndoRedo({
        source: this.drawLayerSetting.layer.getSource(),
      });
      this.$refs.map.map.addInteraction(this.drawLayerSetting.ur);
    },
    FunctionDrawEditCancel() {
      this.drawLayerSetting.ur.blockEnd();
      this.drawLayerSetting.ur.undo();
      if (this.drawLayerSetting.layer != null && !this.isEditDraw) {
        this.$refs.map.map.removeLayer(this.drawLayerSetting.layer);
      }
      this.drawLayerSetting.myDraw.setActive(false);
      this.drawLayerSetting.myDraw = this.oriDraw;
      this.drawLayerSetting = {
        title: "圖層" + (this.drawLayerSelect.length + 1),
        visible: true,
        editPointColor: false,
        pointColor: "#E9B51A",
        pointRange: 100,
        pointRadius: 7,
        editLineColor: false,
        lineColor: "#E9B51A",
        lineRange: 100,
        lineWidth: 2,
        editAreaColor: false,
        areaColor: "#E9B51A",
        areaRange: 100,
        layer: null,
        drawModify: null,
        myDraw: null,
      };
      this.OpenDrawLayerEditor = false;
      this.isEditDraw = false;
    },
    FunctionDrawEditSave() {
      this.drawLayerSetting.myDraw.setActive(false);
      this.drawLayerSetting.ur.clear();
      if (!this.isEditDraw) this.drawLayerSelect.push(this.drawLayerSetting);
      this.OpenDrawLayerEditor = false;
      this.isEditDraw = false;
      this.drawLayerSetting = {
        title: "圖層" + (this.drawLayerSelect.length + 1),
        visible: true,
        editPointColor: false,
        pointColor: "#E9B51A",
        pointRange: 100,
        pointRadius: 7,
        editLineColor: false,
        lineColor: "#E9B51A",
        lineRange: 100,
        lineWidth: 2,
        editAreaColor: false,
        areaColor: "#E9B51A",
        areaRange: 100,
        layer: null,
        drawModify: null,
        myDraw: null,
      };
      this.DrawAndTextMode();
    },
    FunctionDrawTypeChange() {
      for (let index = 0; index < this.drawLayerSelect.length; index++) {
        const element = this.drawLayerSelect[index];
        element.myDraw.setActive(false);
      }
      this.drawLayerSetting.myDraw.setActive(true);
    },
    FunctionDrawClear() {
      if (this.drawLayerSelect.length > 0) {
        this.drawLayerSelect.forEach((element) => {
          this.$refs.map.map.removeLayer(element.layer);
        });
        this.drawLayerSelect = [];
      }
      this.DrawAndTextMode();
    },
    DrawSetStyle(value, arrIndex) {
      if (arrIndex === 0) {
        this.drawLayerSetting.pointColor = value;
      } else if (arrIndex === 1) {
        this.drawLayerSetting.lineColor = value;
      } else if (arrIndex === 2) {
        this.drawLayerSetting.areaColor = value;
      }
      const item = this.drawLayerSetting;
      let pColor = item.pointColor;
      if (item.pointRange !== undefined && item.pointRange !== null) {
        var a = parseFloat(item.pointRange) / 100.0;
        a = Math.round(255 * a);
        pColor = item.pointColor + (a | (1 << 8)).toString(16).slice(1);
      }
      let lColor = item.lineColor;
      if (item.lineRange !== undefined && item.lineRange !== null) {
        var b = parseFloat(item.lineRange) / 100.0;
        b = Math.round(255 * b);
        lColor = item.lineColor + (b | (1 << 8)).toString(16).slice(1);
      }
      let aColor = item.areaColor;
      if (item.areaRange !== undefined && item.areaRange !== null) {
        var c = parseFloat(item.areaRange) / 100.0;
        c = Math.round(255 * c);
        aColor = item.areaColor + (c | (1 << 8)).toString(16).slice(1);
      }
      const newStyle = new Style({
        fill: new Fill({
          color: aColor,
        }),
        stroke: new Stroke({
          color: lColor,
          width: item.lineWidth,
        }),
        image: new CircleStyle({
          radius: item.pointRadius,
          fill: new Fill({
            color: pColor,
          }),
        }),
      });
      item.layer.setStyle(newStyle);
    },
    editDrawLayerItem(item) {
      if (
        this.drawLayerSetting.myDraw != undefined &&
        this.drawLayerSetting.myDraw != null
      ) {
        this.drawLayerSetting.myDraw.setActive(false);
      }
      if (
        this.drawLayerSetting.layer != undefined &&
        this.drawLayerSetting.layer != null
      ) {
        this.$refs.map.map.removeLayer(this.drawLayerSetting.layer);
      }
      this.isEditDraw = true;
      this.OpenDrawLayerEditor = true;
      this.drawLayerSetting = item;
      this.drawLayerSetting.ur.clear();
      this.drawLayerSetting.ur.blockStart("edit");
      this.oriDraw = item.myDraw;
      this.drawLayerSetting.myDraw.setActive(true);
    },
    deleteDrawLayerItem(item) {
      item.myDraw.setActive(false);
      this.$refs.map.map.removeLayer(item.layer);
      this.drawLayerSelect.splice(item.index, 1);
      let newArray = [];
      if (this.drawLayerSelect.length > 0) {
        let newIndex = 0;
        this.drawLayerSelect.forEach((x) => {
          x.index = newIndex;
          newArray.push(x);
          newIndex++;
        });
      }
      this.drawLayerSelect = newArray;
      this.DrawAndTextMode();
    },
    UndoDrwaLayerItem() {
      this.drawLayerSetting.ur.undo();
    },
    RedoDrwaLayerItem() {
      this.drawLayerSetting.ur.redo();
    },
    updateCoordinates(event) {
      this.textSetting.PositionLeft = event.clientX;
      this.textSetting.PositionTop = event.clientY;
    },
    FunctionTextEditSave() {
      if (
        this.textLayerSetting.text == null ||
        this.textLayerSetting.text == ""
      ) {
        alert("請輸入內容");
      } else {
        this.$refs.map.map.removeLayer(this.textLayerSetting.layer);
        this.textLayerSetting.layer = null;
        this.textLayerSetting.position = [
          this.textLayerSetting.positionX,
          this.textLayerSetting.positionY,
        ];
        let obj = this.textLayerSetting;
        let textArr = [];
        textArr.push(obj);
        let extentFeatures = [];
        textArr.forEach((x) => {
          let geom = new Point(x.position);
          let feature = new Feature(geom);
          feature.setStyle(
            new Style({
              text: new Text({
                font: x.FontSize + "px sans-serif",
                text: x.text,
                fill: new Fill({
                  color: x.FontColor,
                }),
              }),
            })
          );
          extentFeatures.push(feature);
        });
        let newSource = new VectorSource({
          features: extentFeatures,
          wrapX: false,
        });
        this.textLayerSetting.layer = new VectorLayer({
          source: newSource,
          visible: this.textLayerSetting.visible,
        });
        if (!this.isEditText) {
          this.textLayerSelect.push(this.textLayerSetting);
        }
        this.$refs.map.map.addLayer(this.textLayerSetting.layer);
        this.isEditText = false;
        this.OpenTextLayerEditor = false;
        this.textSetting.mouseListen = false;
      }
      this.DrawAndTextMode();
    },
    FunctionAddTextLayer() {
      this.textSetting.mouseListen = true;
      this.OpenTextLayerEditor = true;
      this.isEditText = false;
      this.textLayerSetting = {
        position: [121.122, 22.794],
        positionX: 121.122,
        positionY: 22.794,
        text: "文字內容",
        FontSize: 100,
        FontColor: "#000000",
        visible: true,
        id: Date.now(),
      };
      this.textLayerSetting.position = [
        this.textLayerSetting.positionX,
        this.textLayerSetting.positionY,
      ];
      let textArr = [
        {
          position: [121.122, 22.794],
          text: null,
          FontSize: 12,
          FontColor: "#000000",
          visible: true,
          id: Date.now(),
        },
      ];
      let extentFeatures = [];
      textArr.forEach((x) => {
        let geom = new Point(x.position);
        this.textLayerSetting.feature = new Feature(geom);
        this.textLayerSetting.feature.setStyle(
          new Style({
            text: new Text({
              font: x.FontSize + "px sans-serif",
              text: x.text,
              fill: new Fill({
                color: x.FontColor,
              }),
            }),
          })
        );
        extentFeatures.push(this.textLayerSetting.feature);
      });
      let newSource = new VectorSource({
        features: extentFeatures,
        wrapX: false,
      });
      const textLayer = new VectorLayer({
        source: newSource,
        zIndex: 99,
        visible: this.textLayerSetting.visible,
      });
      this.$refs.map.map.addLayer(textLayer);
      this.textLayerSetting.layer = textLayer;
    },
    textLayerRemove() {
      this.OpenTextLayerEditor = false;
      this.isEditText = false;
      if (this.textLayerSetting.layer != null) {
        this.$refs.map.map.removeLayer(this.textLayerSetting.layer);
        this.textLayerSetting.layer = null;
      }
      if (this.textLayerSelect.length > 0) {
        this.textLayerSelect.forEach((x) => {
          this.$refs.map.map.removeLayer(x.layer);
        });
        this.textLayerSelect = [];
      }
      this.DrawAndTextMode();
    },
    editTextItem(item) {
      this.textSetting.mouseListen = true;
      this.textLayerSetting = item;
      this.OpenTextLayerEditor = true;
      this.isEditText = true;
    },
    changeTextLayer() {
      this.$refs.map.map.removeLayer(this.textLayerSetting.layer);
      this.textLayerSetting.position = [
        this.textLayerSetting.positionX,
        this.textLayerSetting.positionY,
      ];
      let textArr = [this.textLayerSetting];
      let extentFeatures = [];
      textArr.forEach((x) => {
        let geom = new Point(x.position);
        this.textLayerSetting.feature = new Feature(geom);
        this.textLayerSetting.feature.setStyle(
          new Style({
            text: new Text({
              font: x.FontSize + "px sans-serif",
              text: x.text,
              fill: new Fill({
                color: x.FontColor,
              }),
            }),
          })
        );
        extentFeatures.push(this.textLayerSetting.feature);
      });
      let newSource = new VectorSource({
        features: extentFeatures,
        wrapX: false,
      });
      this.textLayerSetting.layer = new VectorLayer({
        source: newSource,
        visible: this.textLayerSetting.visible,
        zIndex: 99,
      });
      this.$refs.map.map.addLayer(this.textLayerSetting.layer);
    },
    deleteTextItem(item) {
      this.textSetting.mouseListen = false;
      let newArray = [];
      let newId = 1;
      this.$refs.map.map.removeLayer(this.textLayerSetting.layer);
      this.textSelect.forEach((x) => {
        if (item.id !== x.id) {
          x.id = newId;
          newArray.push(x);
          newId++;
        }
      });
      this.textSelect = newArray;
      this.OpenTextLayerEditor = false;
    },
    deleteTextLayerItem(item) {
      let arrIndex = this.textLayerSelect.findIndex((x) => x.id === item.id);
      this.textLayerSelect.splice(arrIndex, 1);
      this.$refs.map.map.removeLayer(item.layer);
      let newArray = [];
      if (this.textLayerSelect.length > 0) {
        let newIndex = 0;
        this.textLayerSelect.forEach((x) => {
          x.index = newIndex;
          newArray.push(x);
          newIndex++;
        });
      }
      this.textLayerSelect = newArray;
      this.DrawAndTextMode();
    },
    TextSetStyle(value, arrIndex) {
      if (arrIndex === 0) {
        this.textLayerSetting.FontColor = value;
      }
    },
    DrawAndTextMode() {
      const mode =
        this.drawLayerSelect.length > 0 || this.textLayerSelect.length > 0;
      if (mode) {
        sessionStorage.setItem("DrawAndTextMode", mode);
      } else {
        sessionStorage.removeItem("DrawAndTextMode");
      }
    },
    setCompass() {
      if (this.compass) {
        const extent = this.$refs.map.view.calculateExtent(
          this.$refs.map.map.getSize()
        );
        const r = this.$refs.map.view.getResolutionForExtent(
          extent,
          this.$refs.map.map.getSize()
        );

        let x = extent[0] + r * 10;
        let y = extent[1] + r * 200;
        let extentFeatures = [];

        let geom = new Point([x, y]);
        let feature = new Feature(geom);
        feature.setStyle(
          new Style({
            image: new Icon({
              anchor: [0, 0],
              scale: 0.25,
              src: "openlayer/image-removebg-preview.png",
            }),
          })
        );
        extentFeatures.push(feature);

        let newSource = new VectorSource({
          features: extentFeatures,
          wrapX: false,
        });
        this.compassLayer = new VectorLayer({
          source: newSource,
          visible: this.textLayerSetting.visible,
        });
        this.$refs.map.map.addLayer(this.compassLayer);
      } else {
        this.$refs.map.map.removeLayer(this.compassLayer);
      }
    },
    FunctionShowCommonColor() {
      this.legendShow =
        this.CommonColorList.length == 0 &&
        (this.MapLayerSelect.length > 0 || this.drawLayerSelect.length > 0);
      this.legendhasRight =
        this.BtnActive[0] ||
        this.BtnActive[1] ||
        this.BtnActive[2] ||
        this.BtnActive[3];
      this.FunctionLegendShow();
    },
    FunctionLegendShow() {
      this.CommonColorList = [];
      this.legendRain = false;
      this.legendEcho = false;
      this.legendFire = false;
      this.legendLand = false;
      if (this.legendShow) {
        this.MapLayerSelect.forEach((element) => {
          if (element.layerVisible) {
            if (element.title == "日累積雨量圖") {
              this.legendRain = element.title == "日累積雨量圖";
            } else if (element.title == "雷達回波圖") {
              this.legendEcho = element.title == "雷達回波圖";
            } else if (element.title == "林火風險評估") {
              this.legendFire = element.title == "林火風險評估";
            } else if (element.title == "國土利用現況調查成果圖(全國最新)") {
              this.legendLand =
                element.title == "國土利用現況調查成果圖(全國最新)";
            } else {
              this.CommonColorList.push({
                title: element.title,
                BackColorHex: element.layerColor,
                ColorHex: element.layerColor,
                styleType: element.styleType.toLowerCase(),
                BorderStyle: "none",
                width: "24px",
                height: "24px",
              });
            }
          }
        });
        // this.drawLayerSelect.forEach((element) => {
        //   this.CommonColorList.push({
        //     title: element.title,
        //     styleType: "draw",
        //     ColorHex: element.pointColor,
        //     BorderColor: element.lineColor,
        //     BackColorHex: element.areaColor,
        //     BorderStyle: "solid",
        //     width: "30px",
        //     height: "30px",
        //   });
        // });
      }
    },
    FunctionDownloadMulitImageDemo() {
      window.open("openlayer/批次檔案上傳.xlsx");
    },
    FunctionResetScale() {
      this.$refs.map.scaleLine.setDpi(this.resolutionSelected);
      const extent = this.$refs.map.view.calculateExtent(
        this.$refs.map.map.getSize()
      );
      const projection = new Projection({
        code: "EPSG:4326",
        extent: extent,
      });
      const scaleResolution =
        this.scaleSelected /
        getPointResolution(
          projection,
          this.resolutionSelected / 25.39855,
          this.$refs.map.map.getView().getCenter()
        );
      this.$refs.map.map.updateSize();
      this.$refs.map.map.getView().setResolution(scaleResolution);
    },
    FunctionPrintPdf() {
      this.$refs.map.FunctionPrintPdf(this.compass);
    },
    FunctionPrintPdfTest() {
      html2canvas(document.querySelector("#capture")).then(function (canvas) {
        let a = document.createElement("a");
        a.href = canvas
          .toDataURL("image/jpeg", 0.92)
          .replace("image/jpeg", "image/octet-stream");
        a.download = "image.jpg";
        a.click();
      });
    },
    FunctionRemoveExtent() {
      this.$refs.map.map.removeLayer(this.extentLayer);
      this.extentLayer = null;
    },
    FunctionShowExtent() {
      if (this.extentLayer == null) {
        const extent = this.$refs.map.view.calculateExtent(
          this.$refs.map.map.getSize()
        );
        const r = this.$refs.map.view.getResolutionForExtent(
          extent,
          this.$refs.map.map.getSize()
        );
        var extentFeatures = [];
        const geomArray = [
          {
            title: "畫面左上座標",
            text:
              "(" +
              Math.round((extent[0] + r * 10) * 1000000) / 1000000 +
              "," +
              Math.round((extent[3] - r * 10) * 1000000) / 1000000 +
              ")",
            x: extent[0] + r * 10,
            y: extent[3] - r * 10,
          },
          {
            title: "畫面左下座標",
            text:
              "(" +
              Math.round((extent[0] + r * 10) * 1000000) / 1000000 +
              "," +
              Math.round((extent[1] + r * 75) * 1000000) / 1000000 +
              ")",
            x: extent[0] + r * 10,
            y: extent[1] + r * 75,
          },
          {
            title: "畫面右下座標",
            text:
              "(" +
              Math.round((extent[2] - r * 200) * 1000000) / 1000000 +
              "," +
              Math.round((extent[1] + r * 75) * 1000000) / 1000000 +
              ")",
            x: extent[2] - r * 200,
            y: extent[1] + r * 75,
          },
          {
            title: "畫面右上座標",
            text:
              "(" +
              Math.round((extent[2] - r * 200) * 1000000) / 1000000 +
              "," +
              Math.round((extent[3] - r * 10) * 1000000) / 1000000 +
              ")",
            x: extent[2] - r * 200,
            y: extent[3] - r * 10,
          },
        ];
        geomArray.forEach((element) => {
          const geom = new Point([element.x, element.y]);
          const feature = new Feature(geom);
          feature.setStyle(
            new Style({
              image: new Icon({
                anchor: [0, 0],
                scale: 0.1,
                src: "openlayer/img09.png",
              }),
              text: new Text({
                font: "16px sans-serif",
                text: element.text,
                offsetX: 102,
                offsetY: 12,
                fill: new Fill({
                  color: [0, 0, 0, 1],
                }),
              }),
            })
          );
          extentFeatures.push(feature);
        });
        let newSource = new VectorSource({
          features: extentFeatures,
          wrapX: false,
        });
        this.extentLayer = new VectorLayer({
          source: newSource,
        });
        this.$refs.map.map.addLayer(this.extentLayer);
      }
    },
    UpdateColorHex(item, settingItem) {
      if (settingItem === "Border") {
        if (item.BorderRange !== undefined && item.BorderRange !== null) {
          var a = parseFloat(item.BorderRange) / 100.0;
          a = Math.round(255 * a);
          item.BorderHex8 =
            item.BorderColor + (a | (1 << 8)).toString(16).slice(1);
        }
      } else if (settingItem === "Font") {
        if (item.FontRange !== undefined && item.FontRange !== null) {
          var b = parseFloat(item.FontRange) / 100.0;
          b = Math.round(255 * b);
          item.FontHex8 = item.FontColor + (b | (1 << 8)).toString(16).slice(1);
        }
      } else if (settingItem === "Background") {
        if (
          item.BackgroundRange !== undefined &&
          item.BackgroundRange !== null
        ) {
          var c = parseFloat(item.BackgroundRange) / 100.0;
          c = Math.round(255 * c);
          item.BackgroundHex8 =
            item.BackgroundColor + (c | (1 << 8)).toString(16).slice(1);
        }
      }
    },
    // =================================
    FunctionGetTownOption() {
      let json = {
        TYPE: "TOWN_OPTION",
        COMPARISON_CITY_SELECT: {
          CC_ID: "CCONT9RZLf20Yhrxyxpl4sQ",
        },
      };
      this.FunctionToken(this.GetTownOptions, json);
    },
    ChangeTown() {
      if (this.townSelected != null) {
        let json = {
          TYPE: "LOT_OPTION",
          COMPARISON_CITY_SELECT: {
            CT_ID: this.townSelected,
          },
        };
        this.FunctionToken(this.GetLotOptions, json);
      } else alert("參數錯誤");
    },
    engExport() {
      let msg = "";
      let lat = 0,
        lon = 0;
      if (
        this.engRadius != undefined &&
        this.engRadius != null &&
        this.engRadius != 0
      ) {
        if (this.engSearchTypeSelected == "工程點位") {
          if (this.engSelected != null && this.engSelected != "") {
            let search = this.engList.filter(
              (x) => x.eng_name == this.engSelected
            );
            if (search.length == 1) {
              let latlon = this.UTM2LatLon(search[0].x1, search[0].y1, 0, 0);
              lat = latlon[0];
              lon = latlon[1];
            } else {
              msg = "查詢筆數不符 \r\n";
            }
          } else {
            msg = "請選擇查詢工程 \r\n";
          }
        } else {
          if (this.engPositionX != null && this.engPositionY != null) {
            if (this.engPositionType == "twd97") {
              let x = parseFloat(this.engPositionX);
              let y = parseFloat(this.engPositionY);
              let position = [x, y];
              position = this.$refs.map.Pro4Transform(
                "EPSG:3826",
                "EPSG:4326",
                position
              );
              lon = position[0];
              lat = position[1];
            } else {
              lon = parseFloat(this.engPositionX);
              lat = parseFloat(this.engPositionY);
            }
          }
        }
      } else {
        msg = "請輸入半徑 \r\n";
      }

      if (msg != "") {
        this.ShowAlertModal(msg);
      } else {
        this.isLoad = true;
        this.FunctionToken(this.ExportEcological, {
          lat: lat,
          lon: lon,
          radius: this.engRadius,
          typeSelected: this.engSearchTypeSelected,
          positionSelected: this.engPositionSelected,
          engYear: this.engYearSelected,
          engName: this.engSelected,
        });
        this.engSearchTypeEnabled = true;
        this.engYearEnabled = true;
        this.engEnabled = true;
        this.engPositionEnabled = true;
        this.engPositionXEnabled = true;
        this.engPositionYEnabled = true;
        this.engRadiusEnabled = true;
        this.engPositionClick = false;
      }
    },
    //======================api===========================
    //新增圖資
    InsertMapLayer(data) {
      this.InsertButtonTitle = "上傳中";
      this.UploadProgress = 0;
      this.UploadProgressShow = true;
      this.UploadProgressTitle = this.insert.name;
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      const config = {
        onUploadProgress: (progress) => {
          this.UploadProgress = Math.floor(
            (progress.loaded / progress.total) * 100 - 1
          );
        },
      };
      this.userRequest
        .post("API/Default/InsertMapLayer", this.formData, config)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.ShowAlertModal("新增成功");
            this.formData = new FormData();
            this.insert = {
              selected: "WMS",
              options: [
                "WMS",
                "SHP",
                "KML",
                "GPX",
                "GEOJSON",
                "IMAGE",
                "圖片批次上傳",
              ],
              name: null,
              wmsUrl: null,
              shpFile: null,
              kmlFile: null,
              gpxFile: null,
              geoJson: null,
              imageFile: null,
              imageData: {
                x: null,
                y: null,
              },
              imageMultiData: null,
            };
            this.FunctionToken(this.GetMapLayerListForMap2D, {});
            this.UploadProgressTitle = "";
            this.UploadProgressShow = false;
            this.UploadProgress = 0;
            this.InsertButtonTitle = "新增";
          } else {
            this.formData = new FormData();
            this.UploadProgressTitle = "";
            this.UploadProgressShow = false;
            this.UploadProgress = 0;
            this.InsertButtonTitle = "新增";
            this.ShowAlertModal(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
          this.UploadProgressTitle = "";
          this.UploadProgressShow = false;
          this.UploadProgress = 0;
          this.InsertButtonTitle = "新增";
        });
    },
    //取得圖層列表
    GetMapLayerListForMap2D(data) {
      this.apiGetMapLayerListForMap2D(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let data = JSON.parse(json.Data);
            this.MapLayer0 = data.USERARRAY;
            this.MapLayer = data.PFARRAY;
            this.MapLayer1 = data.OTHERSARRAY;
            this.mapSelect = JSON.parse(data.MU_MAPSELECTED);
            let userLayerArr = JSON.parse(data.MU_LAYERSELECTED);
            if (userLayerArr.length > 0) {
              this.initLoadLength = userLayerArr.length;
              this.MapLayer.data.forEach((x) => {
                x.data.forEach((y) => {
                  const element = userLayerArr.find((z) => z.ML_ID == y.ML_ID);
                  if (element !== undefined) {
                    this.changeMap(y);
                    if (y.layerType !== "API") {
                      this.mapSetOpacity(y);
                      this.mapSetVisible(y);
                      if (y.layerType !== "WMS") {
                        this.mapSetStyle(y);
                      }
                    }
                  }
                });
              });
              this.MapLayer1.forEach((x) => {
                x.data.forEach((y) => {
                  const element = userLayerArr.find((z) => z.ML_ID == y.ML_ID);
                  if (element !== undefined) {
                    this.changeMap(y);
                    if (y.layerType !== "API") {
                      this.mapSetOpacity(y);
                      this.mapSetVisible(y);
                      if (y.layerType !== "WMS") {
                        this.mapSetStyle(y);
                      }
                    }
                  }
                });
              });
              this.isLoad = false;
            } else {
              this.isLoad = false;
            }

            // 載入保安林編號選項 (定位用)
            data.PFARRAY.data.forEach((element) => {
              this.forestOptions.push(element.title);
            });
            this.forestOptions.unshift("請選擇");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetMapLayerListForRoomInMap2D(data) {
      this.apiGetMapLayerListForRoomInMap2D(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.MapLayer = jsonData.PFARRAY;
            this.MapLayer1 = jsonData.OTHERSARRAY;
            this.mapSelect = JSON.parse(jsonData.MU_MAPSELECTED);
            this.mapSelect.push(data.ML_ID);
            let userLayerArr = JSON.parse(jsonData.MU_LAYERSELECTED);
            if (userLayerArr.length > 0) {
              this.initLoadLength = userLayerArr.length;
              this.MapLayer.data.forEach((x) => {
                x.data.forEach((y) => {
                  const element = userLayerArr.find((z) => z.ML_ID == y.ML_ID);
                  if (element !== undefined) {
                    this.changeMap(y);
                    if (y.layerType !== "API") {
                      this.mapSetOpacity(y);
                      this.mapSetVisible(y);
                      if (y.layerType !== "WMS") {
                        this.mapSetStyle(y);
                      }
                    }
                  }
                });
              });
              this.MapLayer1.forEach((x) => {
                x.data.forEach((y) => {
                  const element = userLayerArr.find((z) => z.ML_ID == y.ML_ID);
                  if (element !== undefined) {
                    this.changeMap(y);
                    if (y.layerType !== "API") {
                      this.mapSetOpacity(y);
                      this.mapSetVisible(y);
                      if (y.layerType !== "WMS") {
                        this.mapSetStyle(y);
                      }
                    }
                  }
                });
              });
            } else {
              this.isLoad = false;
            }
            let position = [jsonData.Layer_Center_X, jsonData.Layer_Center_Y];
            this.$refs.map.fitTo(position, 15);
            jsonData.PFARRAY.data.forEach((element) => {
              this.forestOptions.push(element.title);
            });
            this.forestOptions.unshift("請選擇");
          } else {
            console.log(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //儲存圖層設定
    SaveMapUserLayer(data) {
      this.apiSaveMapUserLayer(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.$bvToast.toast(`儲存成功`, {
              title: "提示",
              variant: "info",
              autoHideDelay: 1000,
            });
          } else {
            this.ShowAlertModal(json.Data);
          }
          this.isLoad = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoad = false;
        });
    },
    //搜尋關鍵字
    GetMapLayerListForSearchMap2D(data) {
      this.apiGetMapLayerListForSearchMap2D(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let data = JSON.parse(json.Data);
            this.MapLayer = data.PFARRAY;
            this.MapLayer1 = data.OTHERSARRAY;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetPFPosition(data) {
      this.apiGetPFPosition(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            let position = [jsonData.Layer_Center_X, jsonData.Layer_Center_Y];
            this.$refs.map.fitTo(position, 15);
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetTriangulationStationList(data) {
      this.apiGetTriangulationStationList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.triangulationStationOptions = jsonData;
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetOA0040(data) {
      this.apiGetOA0040(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            const extent = [119.188024, 21.523313, 123.578233, 25.918078];
            const projection = new Projection({
              code: "xkcd-image",
              units: "pixels",
              extent: extent,
            });

            var element = this.MapLayerSelect.find(
              (x) => x.ML_ID == data.ML_ID
            );
            if (element != undefined) {
              const sourceStatic = new Static({
                url: "data:image/png;base64," + jsonData[0].url,
                projection: projection,
                imageExtent: extent,
              });

              sourceStatic.on("imageloaderror", function () {
                if (data.source === "外部圖資") {
                  alert("外部圖資回應延遲，請稍後再試");
                }
              });

              let newLayer = new ImageLayer({
                source: sourceStatic,
                visible: element.layerVisible,
              });
              element.Layer = newLayer;
              this.$refs.map.map.addLayer(newLayer);
            }

            if (this.initLoadLength !== 0) {
              this.initLoadIndex++;
            } else {
              this.mapSetZIndex();
              this.isLoad = false;
            }
            this.mapSetZIndex();
          } else {
            alert(json.Message + "：" + json.Data);
          }
          this.isLoad = false;
        })
        .catch((err) => {
          console.log(err);
          var element = this.MapLayerSelect.find((x) => x.ML_ID == data.ML_ID);
          if (element != undefined) this.deleteLayerList(element);
          this.isLoad = false;
        });
    },
    GetFFWI(data) {
      this.apiGetFFWI(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            var element = this.MapLayerSelect.find(
              (x) => x.ML_ID == data.ML_ID
            );
            if (element != undefined) {
              const sourceStatic = new Static({
                url: json.Data,
                imageExtent: [119.2, 21.68, 122.3, 25.7],
                imageLoadFunction: function (image, src) {
                  var xhr = new XMLHttpRequest();
                  xhr.responseType = "arraybuffer";
                  xhr.open("GET", src);
                  xhr.onload = function () {
                    var tiff = new Tiff({ buffer: xhr.response });
                    var canvas = tiff.toCanvas();
                    var context = canvas.getContext("2d");

                    // Remove white background
                    var imageData = context.getImageData(
                      0,
                      0,
                      canvas.width,
                      canvas.height
                    );
                    var data = imageData.data;
                    for (var i = 0; i < data.length; i += 4) {
                      // Check if the pixel is white (you may need to adjust the threshold)
                      if (
                        data[i] === 255 &&
                        data[i + 1] === 255 &&
                        data[i + 2] === 255
                      ) {
                        // Set the alpha channel to 0 to make it transparent
                        data[i + 3] = 0;
                      }
                    }

                    // Put the modified image data back onto the canvas
                    context.putImageData(imageData, 0, 0);

                    image.getImage().src = canvas.toDataURL();
                  };
                  xhr.send();
                },
              });
              sourceStatic.on("imageloaderror", function () {
                if (data.source === "外部圖資") {
                  alert("外部圖資回應延遲，請稍後再試");
                }
              });
              let newLayer = new ImageLayer({
                source: sourceStatic,
              });
              element.Layer = newLayer;
              this.$refs.map.map.addLayer(newLayer);
            }
            this.isLoad = false;
          } else {
            this.isLoad = false;
            // alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetSentinal(data) {
      this.apiGetSentinal(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            var element = this.MapLayerSelect.find(
              (x) => x.ML_ID == data.ML_ID
            );
            if (element) {
              const sourceXYZ = new XYZ({
                url: json.Data + "{z}/{y}/{x}.jpg",
              });
              sourceXYZ.on("tileloaderror", function () {
                if (data.source === "外部圖資") {
                  alert("外部圖資回應延遲，請稍後再試");
                }
              });
              let newLayer = new TileLayer({
                source: sourceXYZ,
              }); // extent: [119.85, 21.59, 122.11, 25.32],
              element.Layer = newLayer;
              this.$refs.map.map.addLayer(newLayer);
            }
          } else {
            alert(json.Message + "：" + json.Data);
          }
          this.isLoad = false;
        })
        .catch((err) => {
          this.isLoad = false;
          console.log(err);
        });
    },
    GetHDMOS(data) {
      this.apiGetHDMOS(data)
        .then((res) => {
          const extent = [119.188024, 21.523313, 123.578233, 25.918078];
          const projection = new Projection({
            code: "xkcd-image",
            units: "pixels",
            extent: extent,
          });
          const sourceStatic = new Static({
            url: "data:image/png;base64," + res.data.Result._buffer,
            projection: projection,
            imageExtent: extent,
          });
          sourceStatic.on("imageloaderror", function () {
            if (data.source === "外部圖資") {
              alert("外部圖資回應延遲，請稍後再試");
            }
          });
          let newLayer = new ImageLayer({
            source: sourceStatic,
          });
          var element = this.MapLayerSelect.find((x) => x.ML_ID == data.ML_ID);
          if (element != undefined) {
            element.Layer = newLayer;
            element.layerVisible = true;
          }
          this.$refs.map.map.addLayer(newLayer);
          if (this.initLoadLength !== 0) {
            this.initLoadIndex++;
          } else {
            this.mapSetZIndex();
            this.isLoad = false;
          }
        })
        .catch(() => {
          console.log("Bad Request");
          var element = this.MapLayerSelect.find((x) => x.ML_ID == data.ML_ID);
          if (element != undefined) this.deleteLayerList(element);
          this.isLoad = false;
        });
    },
    GetMeteorogicalData(data) {
      this.apiGetMeteorogicalData(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            jsonData.features.forEach((feature) => {
              let propertiesKey = Object.keys(feature.properties);
              for (let index = 0; index < propertiesKey.length; index++) {
                if (feature.properties[propertiesKey[index]] == null)
                  delete feature.properties[propertiesKey[index]];
              }
              feature.geometry.coordinates = JSON.parse(
                feature.geometry.coordinates
              );
            });
            let newStyle = new Style({
              image: new CircleStyle({
                radius: data.newValue.pointRadius,
                fill: new Fill({ color: data.newValue.layerColor }),
                stroke: new Stroke({ color: "black", width: 1 }),
              }),
            });
            let sourceVector = new VectorSource({
              features: new GeoJSON().readFeatures(jsonData),
              visible: data.newValue.layerVisible,
              overlaps: true,
            });
            sourceVector.on("featuresloaderror", function () {
              if (data.source === "外部圖資") {
                alert("外部圖資回應延遲，請稍後再試");
              }
            });
            let newLayer = new VectorLayer({
              source: sourceVector,
              style: newStyle,
            });
            data.newValue.Layer = newLayer;
            this.$refs.map.map.addLayer(newLayer);
          }
          this.isLoad = false;
        })
        .catch((err) => {
          console.log("Bad Request");
          console.log(err);
          this.isLoad = false;
        });
    },
    GetMeteorogicalDataKmz(data) {
      this.apiGetMeteorogicalData(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            let sourceVector = new VectorSource({
              url: json.Data,
              format: new KML({
                extractStyles: false,
              }),
              visible: data.newValue.layerVisible,
              overlaps: true,
            });
            sourceVector.on("featuresloaderror", function () {
              if (data.source === "外部圖資") {
                alert("外部圖資回應延遲，請稍後再試");
              }
            });
            let newLayer = new VectorLayer({
              source: sourceVector,
              opacity: 1,
            });
            data.newValue.Layer = newLayer;
            this.$refs.map.map.addLayer(newLayer);
          }
          this.isLoad = false;
        })
        .catch((err) => {
          console.log("Bad Request");
          console.log(err);
          this.isLoad = false;
        });
    },
    GetNCDR(data) {
      this.apiGetNCDR(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            jsonData.features.forEach((feature) => {
              let propertiesKey = Object.keys(feature.properties);
              for (let index = 0; index < propertiesKey.length; index++) {
                if (feature.properties[propertiesKey[index]] == null)
                  delete feature.properties[propertiesKey[index]];
              }
              feature.geometry.coordinates = JSON.parse(
                feature.geometry.coordinates
              );
            });
            let newStyle = new Style({
              image: new CircleStyle({
                radius: data.newValue.pointRadius,
                fill: new Fill({ color: data.newValue.layerColor }),
                stroke: new Stroke({ color: "black", width: 1 }),
              }),
            });
            let newLayer = new VectorLayer({
              source: new VectorSource({
                features: new GeoJSON().readFeatures(jsonData),
                visible: data.newValue.layerVisible,
                overlaps: true,
              }),
              style: newStyle,
            });
            data.newValue.Layer = newLayer;
            this.$refs.map.map.addLayer(newLayer);
          }
          this.isLoad = false;
        })
        .catch((err) => {
          console.log("Bad Request");
          console.log(err);
          this.isLoad = false;
        });
    },
    GetFGIS(data) {
      this.apiGetFGIS(data)
        .then((res) => {
          const extent = [120.86351274, 23.41196835, 120.94595881, 23.43889777];
          const projection = new Projection({
            code: "xkcd-image",
            units: "pixels",
            extent: extent,
          });
          const esrijsonFormat = new EsriJSON();
          const features = esrijsonFormat.readFeatures(res, {
            featureProjection: projection,
          });
          const vectorSource = new VectorSource();
          if (features.length > 0) {
            vectorSource.addFeatures(features);
          }

          let newLayer = new VectorLayer({
            source: vectorSource,
          });
          this.outLayer.data[2].Layer = newLayer;
          this.outLayer.data[2].layerVisible = true;
          this.$refs.map.map.addLayer(newLayer);
          this.mapSetZIndex();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 地籍定位
    GetTownOptions(data) {
      this.apiComparison(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.townOptions = jsonData;
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetLotOptions(data) {
      this.apiComparison(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.lotOptions = jsonData;
            if (this.lotOptions.length == 0)
              this.lotOptions.push({
                value: "",
                text: "無資料",
                disabled: true,
              });
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    CadastralPositioning(data) {
      this.apiCadastralPositioning(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            let featureJson = [];
            jsonData.forEach((x) => {
              let item = x;
              item.geometry.coordinates = JSON.parse(x.geometry.coordinates);
              featureJson.push(item);
            });
            const mapJson = {
              type: "FeatureCollection",
              name: "臺東縣地籍110",
              crs: {
                type: "name",
                properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
              },
              features: featureJson,
            };
            const vectorSource = new VectorSource({
              features: new GeoJSON().readFeatures(mapJson),
            });
            this.locationLayer = new VectorLayer({
              source: vectorSource,
              style: new Style({
                fill: new Fill({
                  color: "#dfbfff",
                }),
                stroke: new Stroke({
                  color: "black",
                }),
              }),
            });
            this.$refs.map.map.addLayer(this.locationLayer);
            this.isLoad = false;
            const extent = this.locationLayer.getSource();
            this.$refs.map.view.fit(extent.getExtent());
          } else {
            alert(json.Message + "：" + json.Data);
            this.isLoad = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoad = false;
        });
    },
    GetEngineering() {
      this.$axios
        .get(
          "https://data.moa.gov.tw/Service/OpenData/TransService.aspx?UnitId=iznHxCJ2d3Ow"
        )
        .then((res) => {
          this.engList = res.data.filter(
            (x) => x.x1 != "" && x.y1 != "" && x.city == "台東縣"
          );
          this.engYearOption = [];
          this.engOptions = [];
          [...new Set(this.engList.map((item) => item.planyear))].forEach(
            (e) => {
              if (e > 107) {
                this.engYearOption.push({
                  text: "民國" + e + "年",
                  value: e,
                });
              }
            }
          );
          this.engOptions = [
            ...new Set(
              this.engList.map((item) => {
                return {
                  text: item.eng_no,
                  value: item.eng_name,
                };
              })
            ),
          ];
          this.engYearOption.unshift({ text: "請選擇", value: null });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    FunctionChangeEngYear() {
      let list = this.engList;
      if (this.engYearSelected != null)
        list = this.engList.filter((x) => x.planyear == this.engYearSelected);
      this.engOptions = [
        ...new Set(
          list.map((item) => {
            return {
              text: item.eng_no,
              value: item.eng_name,
            };
          })
        ),
      ];
      this.engSelected = null;
    },
    ChangeEngSearchType() {
      this.engYearSelected = null;
      this.engSelected = null;
      this.engPositionX = null;
      this.engPositionY = null;
      this.engRadius = 10;
      this.FunctionChangeEngYear();
      this.engPositionClick = this.engSearchTypeSelected == "自訂點位";
    },
    GetEcologicalCheck(data) {
      this.apiGetEcologicalCheck(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            jsonData.features.forEach((feature) => {
              let propertiesKey = Object.keys(feature.properties);
              for (let index = 0; index < propertiesKey.length; index++) {
                if (feature.properties[propertiesKey[index]] == null)
                  delete feature.properties[propertiesKey[index]];
              }
              feature.geometry.coordinates = JSON.parse(
                feature.geometry.coordinates
              );
            });
            let newStyle = new Style({
              image: new CircleStyle({
                radius: 8,
                fill: new Fill({ color: "#abd9cd" }),
                stroke: new Stroke({ color: "black", width: 1 }),
              }),
            });
            let newLayer = new VectorLayer({
              source: new VectorSource({
                features: new GeoJSON().readFeatures(jsonData),
                visible: true,
                overlaps: true,
              }),
              style: newStyle,
              zIndex: 2,
            });
            this.$refs.map.map.addLayer(newLayer);
            if (this.engLayer != null)
              this.$refs.map.map.removeLayer(this.engLayer);
            this.engLayer = newLayer;

            if (this.engCircleLayer != null)
              this.$refs.map.map.removeLayer(this.engCircleLayer);

            var center = fromLonLat([data.lon, data.lat]);
            var circle = new Circle(center, this.engRadius * 1100).transform(
              "EPSG:3857",
              "EPSG:4326"
            );
            let circleFeature = new Feature(circle);
            if (this.engSearchTypeSelected == "工程點位") {
              circleFeature.setProperties({
                年分: this.engYearSelected,
                工程: this.engSelected,
                半徑: this.engRadius + "公里",
                headertitle: "工程範圍",
              });
            } else {
              circleFeature.setProperties({
                坐標系: this.engPositionSelected,
                X軸座標: this.engPositionX,
                Y軸座標: this.engPositionY,
                半徑: this.engRadius + "公里",
                headertitle: "工程範圍",
              });
            }

            this.engCircleLayer = new VectorLayer({
              source: new VectorSource({
                features: [circleFeature],
                overlaps: true,
              }),
              style: new Style({
                fill: new Fill({ color: "rgba(0, 0, 255, 0.1)" }),
                stroke: new Stroke({
                  color: "blue", // 边框颜色
                  width: 2, // 边框宽度
                }),
              }),
              zIndex: 3,
            });
            this.$refs.map.map.addLayer(this.engCircleLayer);
            const extent = this.engLayer.getSource();
            this.$refs.map.view.fit(extent.getExtent());
          } else {
            alert(json.Message + "：" + json.Data);
          }
          this.isLoad = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoad = false;
        });
    },
    ExportEcological(data) {
      this.apiExportEcological(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            window.open(json.Data);
            this.isLoad = false;
          } else {
            this.isLoad = false;
          }
        })
        .catch((err) => {
          this.isLoad = false;
          console.log(err);
        });
    },
    ShowAlertModal(msg) {
      this.alertModalObj.show = true;
      this.alertModalObj.content = msg;
      this.alertModalObj.refresh = false;
    },
    FunctionColorPick(value, arrIndex) {
      this.MapLayerSelect[arrIndex].layerColor = value;
      this.mapSetStyle(this.MapLayerSelect[arrIndex]);
      this.FunctionLegendShow();
    },
  },
};
</script>

<style lang="scss">
p.propertyText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.legendBar {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  max-height: 60vh;
  &.hasRight {
    right: 330px;
  }
  .info {
    white-space: nowrap;
    border-bottom: 1px solid;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
  }
  .legend {
    padding-left: 20px;
    li {
      background-position: left 2px;
      background-repeat: no-repeat;
      list-style-type: none;
      white-space: nowrap;
      padding-bottom: 5px;
      padding-left: 24px;
      width: 24px;
      height: 24px;
      &.Level-1 {
        background-color: #cacaca;
      }
      &.Level-2 {
        background-color: #9efdff;
      }
      &.Level-3 {
        background-color: #01d2fd;
      }
      &.Level-4 {
        background-color: #00a5fe;
      }
      &.Level-5 {
        background-color: #0177fd;
      }
      &.Level-6 {
        background-color: #27a41c;
      }
      &.Level-7 {
        background-color: #01f92f;
      }
      &.Level-8 {
        background-color: #ffff33;
      }
      &.Level-9 {
        background-color: #fed428;
      }
      &.Level-10 {
        background-color: #ffa71f;
      }
      &.Level-11 {
        background-color: #ff2a08;
      }
      &.Level-12 {
        background-color: #da2303;
      }
      &.Level-13 {
        background-color: #aa1801;
      }
      &.Level-14 {
        background-color: #ac20a3;
      }
      &.Level-15 {
        background-color: #db2cd1;
      }
      &.Level-16 {
        background-color: #fd38fa;
      }
      &.Level-17 {
        background-color: #ffd4fd;
      }
      &.echo--5 {
        background-color: #a564d8;
      }
      &.echo-0 {
        background-color: #bc9ad7;
      }
      &.echo-5 {
        background-color: #9a9a9a;
      }
      &.echo-10 {
        background-color: #757575;
      }
      &.echo-15 {
        background-color: #eaa8a9;
      }
      &.echo-20 {
        background-color: #d68a8c;
      }
      &.echo-25 {
        background-color: #b46e6e;
      }
      &.echo-30 {
        background-color: #8ffb95;
      }
      &.echo-35 {
        background-color: #68ba2a;
      }
      &.echo-40 {
        background-color: #fefe78;
      }
      &.echo-45 {
        background-color: #ced06b;
      }
      &.echo-50 {
        background-color: #df6057;
      }
      &.echo-55 {
        background-color: #b90000;
      }
      &.echo-60 {
        background-color: #940000;
      }
      &.echo-65 {
        background-color: #0000f9;
      }
      &.echo-70 {
        background-color: #ffffff;
      }
      &.echo-75 {
        background-color: #c700f9;
      }

      &.fire-50 {
        background-color: #009245;
      }
      &.fire-55 {
        background-color: #8cc63f;
      }
      &.fire-60 {
        background-color: #fcc821;
      }
      &.fire-65 {
        background-color: #f7931e;
      }
      &.fire-70 {
        background-color: #ff0000;
      }
    }
  }

  .legendL {
    padding-left: 20px;
    width: 190px;
    li {
      list-style-type: none;
      white-space: nowrap;
      padding-bottom: 5px;
      padding-left: 10px;
      margin-left: 0;
      padding-right: 0;
      &.land-01::before {
        background-color: rgb(152, 230, 0);
        color: rgb(152, 230, 0);
        width: 24px;
        height: 24px;
        content: "123";
        font-size: 80%;
        margin-right: 5px;
      }
      &.land-02::before {
        background-color: rgb(90, 194, 0);
        color: rgb(90, 194, 0);
        width: 24px;
        height: 24px;
        content: "123";
        font-size: 80%;
        margin-right: 5px;
      }
      &.land-03::before {
        background-color: rgb(255, 85, 0);
        color: rgb(255, 85, 0);
        width: 24px;
        height: 24px;
        content: "123";
        font-size: 80%;
        margin-right: 5px;
      }
      &.land-04::before {
        background-color: rgb(151, 219, 242);
        color: rgb(151, 219, 242);
        width: 24px;
        height: 24px;
        content: "123";
        font-size: 80%;
        margin-right: 5px;
      }
      &.land-05::before {
        background-color: rgb(255, 161, 161);
        color: rgb(255, 161, 161);
        width: 24px;
        height: 24px;
        content: "123";
        font-size: 80%;
        margin-right: 5px;
      }
      &.land-06::before {
        background-color: rgb(255, 197, 89);
        color: rgb(255, 197, 89);
        width: 24px;
        height: 24px;
        content: "123";
        font-size: 80%;
        margin-right: 5px;
      }
      &.land-07::before {
        background-color: rgb(255, 251, 20);
        color: rgb(255, 251, 20);
        width: 24px;
        height: 24px;
        content: "123";
        font-size: 80%;
        margin-right: 5px;
      }
      &.land-08::before {
        background-color: rgb(206, 140, 255);
        color: rgb(206, 140, 255);
        width: 24px;
        height: 24px;
        content: "123";
        font-size: 80%;
        margin-right: 5px;
      }
      &.land-09::before {
        background-color: rgb(225, 225, 225);
        color: rgb(225, 225, 225);
        width: 24px;
        height: 24px;
        content: "123";
        font-size: 80%;
        margin-right: 5px;
      }
    }
  }

  span.color {
    display: block;
  }
}
</style>
