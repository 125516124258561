<script>
//import API from "../api/API";
export default {
  name: "Mixin",
  data() {
    return {
      TokenID: "",
      Token: "",
      // Google Key localhost
      googleParams: {
        client_id:
          "597334172172-j1fe3hs7nr8vtu0m8pgtftm0nim25gej.apps.googleusercontent.com",
      },

      userRequest: this.$axios.create({ baseURL: "../api" }), //本機端
      // userRequest: this.$axios.create({ baseURL: "/110forest50/api" }), //弱掃端

      MeteorogicalRequest: this.$axios.create({
        baseURL: "https://opendata.cwb.gov.tw/fileapi/v1/opendataapi",
      }), // 氣象局
      //本機端
      geoServerUrl:
        "/geoserver/geoserver/wms?service=wms&version=1.1.1&request=GetCapabilities",
      // // Google Key 正式機
      // googleParams: {
      //   client_id:
      //     "23300426003-b48jfkqgk81kg959ujbe496bj23vdhj1.apps.googleusercontent.com",
      // },
      // 本機跨域
      // userRequest: this.$axios.create({
      //   baseURL: "http://giep2-pc/110forest50/api",
      // }),
      apiToken: (data) => this.userRequest.post("API/Default/Token", data),
      apiACCOUNT_GROUPMy: (data) =>
        this.userRequest.post("API/Default/ACCOUNT_GROUPMy", data),
    };
  },
  created() {
    this.userSignCheck();
  },
  mounted() {
    // this.setPage()
    //this.userSignCheck();
  },
  computed: {},
  methods: {
    userSignCheck() {
      if (sessionStorage.getItem("TokenID") != "") {
        this.TokenID = sessionStorage.getItem("TokenID");
      }
      let now_router = this.$route.name;
      if (
        now_router != "ForgetPassword" &&
        now_router != "Login" &&
        now_router != "VueLogin" &&
        this.TokenID === null
      ) {
        alert("請登入系統!!");
        this.$router.push({ path: "/" });
      }
    },
    FunctionToken: function (EXfunction, data_in) {
      this.apiToken({})
        .then((res) => {
          // console.log([EXfunction, data_in])
          data_in.TokenID = this.TokenID;
          data_in.Token = res.data;
          // data_in.Page = this.page;
          // console.log(data_in);
          EXfunction(data_in);
        })
        .catch((error) => {
          alert("發生錯誤!" + error);
        });
    },
    FunctionTokenAsync(data_in) {
      return new Promise((resolve, reject) => {
        this.apiToken({})
          .then((res) => {
            // console.log([EXfunction, data_in])
            data_in.TokenID = this.TokenID;
            data_in.Token = res.data;
            resolve(data_in);
            //EXfunction(data_in);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GoToIndex() {
      this.$router.push({ path: "/" });
    },
    //=====================================================
    _sessionData(key, val = undefined) {
      if (val == undefined) {
        return sessionStorage.getItem(key);
      } else if (val == "") {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, val);
      }
    },
    IsNull(data) {
      if (data == "" || data == null || data == undefined) {
        return false;
      } else {
        return true;
      }
    },
    _Vupdate(type = "w") {
      if (type == "w") {
        this._watcher.run();
      } else if (type == "f") {
        this.$forceUpdate();
      }
    },
    //轉換時間
    dateFormat(item) {
      if (item != null) {
        let date = item.split("T")[0].split("-");
        let substr = date[0] + "年" + date[1] + "月" + date[2] + "日";
        return substr;
      }
    },
    //==================================================================
    modal_init(modals) {
      let th1 = this;
      let modal = {};
      for (let i in modals) {
        let item = modals[i];
        modal[item.name] = {
          show: function () {
            th1.$bvModal.show(item.id);
          },
          hide: function () {
            th1.$bvModal.hide(item.id);
          },
        };
      }
      this.modal = modal;
    },
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    Str_Replace(str, if1, if2) {
      return (str + "").replace(new RegExp(if1, "g"), if2);
    },
    StrCap(str1, n1, n2) {
      if (n2 < 0) {
        return str1.substr(n1, str1.length + n2);
      } else {
        return str1.substr(n1, n2);
      }
    },
    str2date(str) {
      let str1 = this.Str_Replace(
        this.Str_Replace(this.Str_Replace(str, " ", ""), "/", ""),
        "-",
        ""
      );
      return (
        this.StrCap(str1, 0, 4) +
        "/" +
        this.StrCap(str1, 4, 2) +
        "/" +
        this.StrCap(str1, 6, 2)
      );
    },
    str2date2(str) {
      let str1 = this.Str_Replace(
        this.Str_Replace(this.Str_Replace(str, " ", ""), "/", ""),
        "-",
        ""
      );
      if (str1.length == 6) {
        return (
          Number(this.StrCap(str1, 0, 2)) +
          1911 +
          "/" +
          this.StrCap(str1, 2, 2) +
          "/" +
          this.StrCap(str1, 4, 2)
        );
      } else {
        return (
          Number(this.StrCap(str1, 0, 3)) +
          1911 +
          "/" +
          this.StrCap(str1, 3, 2) +
          "/" +
          this.StrCap(str1, 5, 2)
        );
      }
    },
    str2date3(str) {
      // 民國轉西元
      let str1 = this.Str_Replace(
        this.Str_Replace(this.Str_Replace(str, " ", ""), "/", ""),
        "-",
        ""
      );
      if (str1.length >= 8) {
        return this.str2date(str1);
      } else if (str1.length >= 6) {
        return this.str2date2(str1);
      } else {
        return "";
      }
    },
    date2str(date) {
      //西元轉民國

      return [
        date.getFullYear(),
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1),
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
      ];
    },
    date2str2(date) {
      return {
        date: [
          date.getFullYear(),
          date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1),
          date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
        ],
        time: [
          date.getHours() > 9 ? date.getHours() : "0" + date.getHours(),
          date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes(),
          date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds(),
        ],
      };
    },
    DeepCopy(data, if1 = 0) {
      if (if1 == 0) {
        return JSON.parse(JSON.stringify(data));
      }
      /*
        else if(if1==1){
            return $.extend(true, {}, data);;
        }
        */
    },
    _sleep(fun, time) {
      setTimeout(fun, time);
    },
    _imgRead(e, fun) {
      let d1 = new FileReader();
      d1.readAsDataURL(e.target.files[0]);
      d1.onloadend = function () {
        fun(this.result);
      };
    },
    json2Fdata(json) {
      let Fdata = new FormData();
      for (let i in json) {
        Fdata.append(i, this.IsNull(json[i]));
      }
      return Fdata;
    },
    Clog(data) {
      console.log(data);
    },
    Elog(data) {
      console.log(data);
    },
    IsNum(data) {
      if (isNaN(Number(data))) {
        return 0;
      } else {
        return Number(data);
      }
    },
    IsNullKeys(data, keys, err = "") {
      let val = data;
      for (let i in keys) {
        let key = keys[i];
        if (this.IsNull(val[key]) == "") {
          return err;
        } else {
          val = val[key];
        }
      }
      return val;
    },
    _go(url) {
      if (isNaN(url)) {
        this.$router.push(url);
      } else {
        this.$router.go(url);
      }
    },
  },
};
</script>

<style>
.colorBox {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -6px;
  margin-right: 0.5rem;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
</style>
