<template>
  <div class="page">
    <div class="page-content">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="檢訂成果" active>
            <VERIFICATIONDATA 
              :PF_ID="PF_ID" 
              :forestName="forestName" 
              :resultItems="resultItems"
              :EditPermission="EditPermission"
              @getData="init"
            ></VERIFICATIONDATA>
          </b-tab>
          <b-tab title="歷年陳情、議題">
            <RECEPTION
              :PF_ID="PF_ID" 
              :forestName="forestName"
              :issueItems="issueItems"
              :EditPermission="EditPermission"
              :latestMap="latestMap"
              @getData="init"
            ></RECEPTION>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
import VERIFICATIONDATA from "../../components/RevisionResultDetail/VERIFICATIONDATA.vue";
import RECEPTION from "../../components/RevisionResultDetail/RECEPTION.vue";
export default {
  components: {
    VERIFICATIONDATA,
    RECEPTION,
  },
  data() {
    return {
      PF_ID: "",
      Permission: {},
      forestName: "",
      EditPermission: false,
      // ========================== VERIFICATIONDATA ==============================
      resultItems: [],
      // ========================== api ==============================
      issueItems: [],
      latestMap: "",
      // ========================== api ==============================
      apiGetMyGroupPermission: (data) =>
        this.userRequest.post("API/Default/GetMyGroupPermission", data),
      apiGetRevisionResultDetailList: (data) =>
        this.userRequest.post("API/Default/GetRevisionResultDetailList", data),
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.PF_ID) {
        this.PF_ID = this.$route.query.PF_ID;
        this.FunctionToken(this.GetMyGroupPermission, {});
        this.FunctionToken(this.GetRevisionResultDetailList, {
          PF_ID: this.PF_ID,
        });
      } else {
        this.$router.push({ path: "RevisionResult" });
      }
        
    },
    GetMyGroupPermission(data) {
      this.apiGetMyGroupPermission(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.Permission = jsonData;
          } else {
            console.log("fail");
          }
        })
        .catch(() => {
          console.log("fail");
        });
    },
    //取得檢訂成果
    GetRevisionResultDetailList(data) {
      this.apiGetRevisionResultDetailList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            console.log(jsonData)
            this.forestName = jsonData.PF_NAME;
            this.EditPermission = jsonData.RevisionResult_Editor;
            this.resultItems = jsonData.VERIFICATIONDATALIST;
            this.issueItems = jsonData.RECEPTIONLIST;
            for (let i = 0; i < this.resultItems.length; i++) {
              if (this.resultItems[i].VD_MLID != null) {
                this.latestMap = this.resultItems[i].VD_MLID;
                break;
              }
            }
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
}
</script>
