import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueDragResize from 'vue-drag-resize'
import mixin from './components/Mixin.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { LMap, LTileLayer, } from 'vue2-leaflet';
import VueApexCharts from 'vue-apexcharts'
import 'leaflet/dist/leaflet.css';
import Loading from 'vue-loading-overlay';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import AOS from 'aos';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'aos/dist/aos.css'; // You can also use <link> for styles
import './assets/custom.scss'
import './assets/EvenObj.js'
import '@/assets/scss/argon.scss';
import JsonExcel from "vue-json-excel";
import htmlToPdf from './common/htmlToPdf.js'
import 'ol-ext/dist/ol-ext.min.css';
import CoordTrans from './common/CoordTrans.js'

Vue.component("downloadExcel", JsonExcel);
Vue.component('VueSlider', VueSlider)
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component("Loading", Loading);
Vue.component('apexchart', VueApexCharts)
Vue.component('vue-drag-resize', VueDragResize)
Vue.component('AOS', AOS)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
Vue.use(VueApexCharts)
Vue.use(VueSidebarMenu)
Vue.use(htmlToPdf)
Vue.use(CoordTrans)

Vue.mixin(mixin)
Vue.config.productionTip = false
Vue.prototype.$axios = axios;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
