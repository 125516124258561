<template>
  <div class="page">
    <div class="page-content">
      <div>
        <b-row no-gutters align-h="between" align-v="center">
          <b-col cols="auto">
            <b-breadcrumb class="mb-0" :items="breadcrumb"></b-breadcrumb
          ></b-col>
          <b-col cols="auto"> </b-col>
        </b-row>
      </div>
      <b-container class="mt-5 bv-example-row">
        <b-row align-h="center">
          <b-col sm="12" md="6">
            <form>
              <div class="form-group">
                <label>帳號</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="account.Account"
                  disabled
                  placeholder="請輸入您的帳號"
                />
              </div>
              <div class="form-group">
                <label>使用者名稱</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="account.Name"
                  placeholder="請輸入您的使用者名稱"
                />
              </div>
              <div class="form-group">
                <label>電子信箱</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="account.Email"
                  placeholder="請輸入您的電子信箱"
                />
              </div>
              <div>
                <div class="social-media-icon">
                  <b-row class="bv-example-row">
                    <b-col sm="12" md="6" lg="6">
                      <a
                        :href="lineConnectUrl"
                        class="line-login"
                        v-if="!account.LineLogin"
                      >
                        <b-img
                          :src="require('@/assets/imgs/btn_login.png')"
                        ></b-img
                        ><span class="line-login-title">綁定Line</span>
                      </a>

                      <a
                        class="line-login"
                        v-else
                        @click="FunctionAccount('LineCancel')"
                      >
                        <b-img
                          :src="require('@/assets/imgs/btn_login.png')"
                        ></b-img
                        ><span class="line-login-title">取消綁定Line</span>
                      </a>
                    </b-col>
                    <b-col sm="12" md="6" lg="6"
                      ><GoogleLogin
                        v-if="!account.GoogleLogin"
                        :params="googleParams"
                        :renderParams="GoogleLoginCss"
                        :onSuccess="GoogleLoginSuccess"
                        :onFailure="onFailure"
                      ></GoogleLogin>
                      <button
                        class="btn btn-dark btn-block"
                        v-else
                        @click="FunctionAccount('GoogleCancel')"
                      >
                        取消綁定Google
                      </button></b-col
                    >
                  </b-row>
                </div>
              </div>
              <button
                class="btn btn-dark btn-block"
                @click="FunctionAccount('Normal')"
              >
                確認
              </button>
            </form>
          </b-col>
        </b-row>
      </b-container>

      <AlertModal :alertModalObj="alertModalObj" @getData="init" />
    </div>
  </div>
</template>
<script>
import GoogleLogin from "vue-google-login";
import AlertModal from "../components/AlertModal.vue";
export default {
  components: {
    GoogleLogin,
    AlertModal,
  },
  data() {
    return {
      lineConnectUrl:
        "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657117832&redirect_uri=https://www.tfda.tw/lineLogin.html&state=connectToklenId&scope=openid%20profile",
      breadcrumb: [
        {
          text: "個人資料維護",
          active: true,
        },
      ],
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
        refresh: false,
      },
      GoogleLoginCss: {
        width: "standard",
        height: 40,
        longtitle: true,
      },

      account: {
        Email: null,
        Account: null,
        Name: null,
        LineLogin: false,
        GoogleLogin: true,
      },
      apiMyACCOUNTBySingleSign: (data) =>
        this.userRequest.post("API/Default/MyACCOUNTBySingleSign", data),
      apiUpdateMyACCOUNT: (data) =>
        this.userRequest.post("API/Default/UpdateMyACCOUNT", data),

      apiAccountBindingGoogle: (data) =>
        this.userRequest.post("API/Default/AccountBindingGoogle", data),
    };
  },
  computed: {
    checkEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.account.Email).toLowerCase());
    },
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.FunctionToken(this.MyACCOUNTBySingleSign, {});
    },
    GoogleLoginSuccess(googleUser) {
      console.log(googleUser.getBasicProfile());
      let GoogleID = googleUser.getBasicProfile().getId();
      this.FunctionToken(this.AccountBindingGoogle, { AC_EMAIL: GoogleID });
    },
    onFailure(googleUser) {
      console.log(googleUser);
    },
    AccountBindingGoogle(data) {
      this.apiAccountBindingGoogle(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            // const jsonData = JSON.parse(json.Data);
            this.alertModalObj.show = true;
            this.alertModalObj.content = "綁定Google帳號成功";
            this.alertModalObj.refresh = true;
          } else {
            this.alertModalObj.content = json.Message + "：" + json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    FunctionAccount(value) {
      if (value == "Normal") {
        var mag = [];
        this.account.Name == null ? mag.push("請填寫使用者名稱") : "";
        if (this.account.Email != null) {
          if (!this.checkEmail) {
            mag.push("電子信箱格式錯誤");
          }
        } else {
          mag.push("請填寫電子信箱");
        }
        if (mag.length == 0) {
          let json = {};
          json.AC_USERName = this.account.Name;
          json.AC_Email = this.account.Email;
          json.Mode = value;
          this.FunctionToken(this.UpdateMyACCOUNT, json);
        } else {
          this.alertModalObj.title = "提示";
          this.alertModalObj.show = true;
          this.alertModalObj.content = mag;
          this.alertModalObj.refresh = false;
        }
      } else if (value == "GoogleCancel") {
        let json = {};
        json.AC_GOOGLEID = "CANCLE";
        json.Mode = value;
        this.FunctionToken(this.UpdateMyACCOUNT, json);
      } else if (value == "LineCancel") {
        let json = {};
        json.AC_LINEID = "CANCLE";
        json.Mode = value;
        this.FunctionToken(this.UpdateMyACCOUNT, json);
      }
    },
    //======================api===========================
    //取得帳號列表
    MyACCOUNTBySingleSign(data) {
      this.apiMyACCOUNTBySingleSign(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            let lineStatus = false;
            let googleStatus = false;
            jsonData.AC_LINEID == null
              ? (lineStatus = false)
              : (lineStatus = true);
            jsonData.AC_GOOGLEID == null
              ? (googleStatus = false)
              : (googleStatus = true);
            (this.account = {
              Email: jsonData.AC_EMAIL,
              Account: jsonData.AC_USERID,
              Name: jsonData.AC_USERNAME,
              LineLogin: lineStatus,
              GoogleLogin: googleStatus,
            }),
              (this.lineConnectUrl =
                "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657117832&redirect_uri=https://www.tfda.tw/lineLogin.html&state=" +
                jsonData.EncodeTokenID +
                "&scope=openid%20profile");
            console.log(jsonData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //更新帳號
    UpdateMyACCOUNT(data) {
      this.apiUpdateMyACCOUNT(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新帳號成功";
            if (data.Mode == "Normal") {
              this.$router.push({ path: "/Map2D" });
            } else {
              this.init();
            }
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
