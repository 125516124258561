<template>
  <div
      class="text-center"
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
      "
      align-v="center"
      v-if="isLoad"
    >
      <b-row style="position: relative; top: 45vh">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner
          ><span
            style="
              font-size: 20px;
              color: #ffffff;
              font-weight: bold;
              padding-left: 20px;
            "
            >載入中，請稍後...</span
          >
        </b-col>
      </b-row>
    </div>
</template>
<script>
export default {
  props: ["isLoad"],
  data() {
    return {};
  },
}
</script>
