<template>
  <div class="page">
    <div class="page-content">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="帳號管理" active>
            <b-row no-gutters align-h="between" align-v="center">
              <b-col cols="auto">
                <b-breadcrumb
                  class="mb-0"
                  :items="accountBreadcrumb"
                ></b-breadcrumb
              ></b-col>
              <b-col cols="auto">
                <b-button
                  class="mr-3 mt-1"
                  variant="outline-dark"
                  v-b-modal.account-modal
                  @click="
                    account.Title = '新增帳號';
                    account.OKTitle = '新增';
                  "
                >
                  <b-icon icon="plus"></b-icon> 新增</b-button
                >
              </b-col>
            </b-row>

            <b-container>
              <div class="table-responsive content-box">
                <b-table
                  hover
                  class="table table-bordered table-striped"
                  :items="accountItems"
                  :fields="accountFields"
                  :per-page="perPage"
                  :current-page="currentPage"
                  show-empty
                  empty-text="查無資料"
                  tbody-tr-class="text-nowrap"
                >
                  <template #cell(AC_STATUS)="data">
                    <div>{{ data.value == 0 ? "未啟用" : "已啟用" }}</div>
                  </template>
                  <template #cell(operate)="data">
                    <b-button-group>
                      <b-button
                        variant="success"
                        v-b-modal.account-modal
                        class="ml-3"
                        @click="
                          account.AC_ID = data.item.AC_ID;
                          account.Account = data.item.AC_USERID;
                          account.Name = data.item.AC_USERNAME;
                          account.Email = data.item.AC_EMAIL;
                          account.IdentitySelected = data.item.AC_GROUP_ID;
                          account.Status = data.item.AC_STATUS;
                          account.Title = '編輯帳號';
                          account.OKTitle = '確定';
                        "
                      >
                        <b-icon icon="pencil"></b-icon> 編輯</b-button
                      >
                      <b-button
                        variant="danger"
                        v-b-modal.delete-info
                        class="ml-3"
                        @click="FunctionDelete(data, 'account')"
                      >
                        <b-icon icon="trash"></b-icon> 刪除</b-button
                      >
                      <b-button
                        variant="light"
                        class="ml-3"
                        v-if="data.item.AC_STATUS == 0"
                        @click="FunctionReSendMail(data.item)"
                      >
                        <b-icon icon="arrow-up-right-square"></b-icon>
                        重發開通郵件</b-button
                      >
                      <b-button
                        variant="dark"
                        class="ml-3"
                        @click="FunctionShowLog(data.item)"
                      >
                        <b-icon icon="receipt"></b-icon> 登入紀錄</b-button
                      >
                    </b-button-group>
                  </template>
                </b-table>
              </div>
            </b-container>

            <div class="overflow-auto">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                class="pt-3"
                aria-controls="my-table"
                align="center"
                first-number
                last-number
                limit="10"
              ></b-pagination>
            </div>
          </b-tab>
          <b-tab title="權限管理">
            <b-row no-gutters align-h="between" align-v="center">
              <b-col cols="auto">
                <b-breadcrumb
                  class="mb-0"
                  :items="authorityBreadcrumb"
                ></b-breadcrumb
              ></b-col>
              <b-col cols="auto">
                <b-button
                  class="mr-3"
                  variant="outline-dark"
                  v-b-modal.authority-modal
                  @click="
                    authority.Title = '新增權限群組';
                    authority.OKTitle = '新增';
                  "
                >
                  <b-icon icon="plus"></b-icon> 新增</b-button
                >
              </b-col>
            </b-row>

            <b-container>
              <div class="table-responsive content-box">
                <b-table
                  hover
                  class="table table-bordered table-striped"
                  :items="authorityItems"
                  :fields="authorityFields"
                  :per-page="perPage"
                  :current-page="currentPage"
                  show-empty
                  empty-text="查無資料"
                  tbody-tr-class="text-nowrap"
                >
                  <template #cell(operate)="data">
                    <b-button-group>
                      <b-button
                        variant="success"
                        v-b-modal.authority-modal
                        @click="
                          authority.Title = '編輯權限群組';
                          authority.OKTitle = '確定';
                          authority.AG_ID = data.item.AG_ID;
                          authority.Name = data.item.AG_NAME;
                          authority.selected = JSON.parse(data.item.AG_PJID);
                        "
                      >
                        <b-icon icon="pencil"></b-icon> 編輯</b-button
                      >
                      <b-button
                        variant="danger"
                        class="ml-3"
                        v-b-modal.delete-info
                        @click="FunctionDelete(data, 'authority')"
                      >
                        <b-icon icon="trash"></b-icon> 刪除</b-button
                      >
                    </b-button-group>
                  </template>
                </b-table>
              </div>
            </b-container>
          </b-tab>
          <b-tab title="使用統計">
            <b-row no-gutters align-h="between" align-v="center" class="py-4">
              <b-col cols="auto">
                <div class="px-3">
                  <b-form inline>
                    <label class="mr-sm-2" for="inline-form-custom-select-pref"
                      >年份：</label
                    >
                    <b-form-select
                      class="mr-3"
                      :options="statisticYearOption"
                      v-model="statisticYearSelect"
                    ></b-form-select>
                    <label class="mr-sm-2" for="inline-form-custom-select-pref"
                      >月份：</label
                    >
                    <b-form-select
                      class="mr-3"
                      :options="statisticMonthOption"
                      v-model="statisticMonthSelect"
                    ></b-form-select>
                    <label class="mr-sm-2" for="inline-form-custom-select-pref"
                      >群組</label
                    >
                    <b-form-checkbox-group
                      class="mr-3"
                      v-model="statisticGroupSelect"
                      :options="statisticGroupOption"
                    ></b-form-checkbox-group>
                    <b-button variant="dark" @click="FunctionSearch"
                      >查詢</b-button
                    >
                  </b-form>
                </div>
              </b-col>
            </b-row>
            <b-container>
              <apexchart
                type="bar"
                height="350"
                ref="statisticLoginChart"
                :options="chartOptionsBar"
                :series="seriesBar"
              ></apexchart>
            </b-container>
          </b-tab>
        </b-tabs>
      </b-card>

      <b-modal
        content-class="shadow"
        size="lg"
        id="account-modal"
        :title="account.Title"
        ok-only
        :ok-title="account.OKTitle"
        ok-variant="primary"
        no-close-on-backdrop
        hide-backdrop
        no-stacking
        scrollable
        centered
        @ok="FunctionAccount"
        @close="FunctionResetModal"
      >
        <b-form-group label="帳號">
          <b-form-input type="text" v-model="account.Account"></b-form-input>
        </b-form-group>
        <b-form-group label="使用者名稱">
          <b-form-input type="text" v-model="account.Name"></b-form-input>
        </b-form-group>
        <!-- <b-form-group label="密碼" v-if="account.AC_ID == null">
          <b-form-input
            type="password"
            v-model="account.Password"
          ></b-form-input>
        </b-form-group> -->
        <b-form-group label="電子信箱">
          <b-form-input type="text" v-model="account.Email"></b-form-input>
        </b-form-group>
        <b-form-group label="狀態" v-if="account.AC_ID != null">
          <b-form-checkbox
            v-model="account.Status"
            :value="1"
            :unchecked-value="0"
          >
            <label v-if="account.Status === 1">啟用</label>
            <label v-else>未啟用</label>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="權限">
          <b-form-select
            v-model="account.IdentitySelected"
            :options="account.IdentityOptions"
          ></b-form-select>
        </b-form-group>
      </b-modal>
      <b-modal
        content-class="shadow"
        size="sm"
        id="authority-modal"
        :title="authority.Title"
        ok-only
        :ok-title="authority.OKTitle"
        ok-variant="primary"
        no-close-on-backdrop
        hide-backdrop
        no-stacking
        scrollable
        @ok="FunctionAccount_Group"
        @close="FunctionResetModal"
      >
        <b-form-group label="群組名稱">
          <b-form-input type="text" v-model="authority.Name"></b-form-input>
        </b-form-group>
        <b-form-group label="權限指定">
          <b-form-checkbox
            v-for="option in authorityOptions"
            v-model="authority.selected"
            :key="option.text"
            :value="option.value"
            name="flavour-3a"
          >
            {{ option.text }}
          </b-form-checkbox>
          <!-- <ul v-for="option in authorityOptionsDetail2" :key="option.text">
            <li>
              {{ option.text }}
              <b-form-checkbox
                v-for="optionDetail in option.value"
                v-model="authority.selected"
                :key="optionDetail.text"
                :value="optionDetail.value"
                name="flavour-3a"
              >
                {{ optionDetail.text }}
              </b-form-checkbox>
            </li>
          </ul> -->
          <label>圖台管理</label>
          <b-form-checkbox
            class="ml-3"
            v-model="authority.selected"
            value="PJ202205100003_01"
            name="flavour-3a"
            >檢視</b-form-checkbox
          >
          <b-form-checkbox
            class="ml-3"
            v-model="authority.selected"
            value="PJ202205100003_02"
            name="flavour-3a"
            >編輯</b-form-checkbox
          >
          <div class="mt-2">
            <label>資料管理</label>
            <ul v-for="option in authorityOptionsDetail" :key="option.text">
              <li>
                {{ option.text }}
                <b-form-checkbox
                  v-for="optionDetail in option.value"
                  v-model="authority.selected"
                  :key="optionDetail.text"
                  :value="optionDetail.value"
                  name="flavour-3a"
                >
                  {{ optionDetail.text }}
                </b-form-checkbox>
              </li>
            </ul>
          </div>
          <!-- <ul class="access-management">
            <li>資料管理</li>
            <ul v-for="option in authorityOptionsDetail" :key="option.text">
              <li>
                {{ option.text }}
                <ul>
                  <li>
                    <b-form-checkbox
                      v-for="optionDetail in option.value"
                      v-model="authority.selected"
                      :key="optionDetail.text"
                      :value="optionDetail.value"
                      name="flavour-3a"
                    >
                      {{ optionDetail.text }}
                    </b-form-checkbox>
                  </li>
                </ul>
              </li>
            </ul>
          </ul> -->
        </b-form-group>
      </b-modal>
      <b-modal
        content-class="shadow"
        size="lg"
        id="logDetail-modal"
        :title="logUser + '登入紀錄'"
        ok-only
        ok-title="關閉"
        ok-variant="primary"
        no-close-on-backdrop
        hide-backdrop
        no-stacking
        scrollable
        centered
        @ok="FunctionResetModal"
        @close="FunctionResetModal"
      >
        <b-container>
          <div class="table-responsive content-box">
            <b-table
              hover
              class="table table-bordered table-striped"
              :items="logItems"
              :fields="logFields"
              :per-page="logPerPage"
              :current-page="logCurrentPage"
              show-empty
              empty-text="查無資料"
              tbody-tr-class="text-nowrap"
            >
            </b-table>
          </div>
        </b-container>

        <div class="overflow-auto">
          <b-pagination
            v-model="logCurrentPage"
            :total-rows="logRows"
            :per-page="logPerPage"
            class="pt-3"
            aria-controls="my-table"
            align="center"
            first-number
            last-number
            limit="10"
          ></b-pagination>
        </div>
      </b-modal>
      <AlertModal :alertModalObj="alertModalObj" @getData="init" />
      <DeleteModal :deleteModalObj="deleteModalObj" @getData="init" />
    </div>
    <Loading :isLoad="isLoad"></Loading>
  </div>
</template>
<script>
import AlertModal from "../components/AlertModal.vue";
import DeleteModal from "../components/DeleteModal.vue";
import Loading from "../components/Loading.vue";
export default {
  components: {
    AlertModal,
    DeleteModal,
    Loading,
  },
  data() {
    return {
      isLoad: false,
      accountBreadcrumb: [
        {
          text: "帳號管理",
          active: true,
        },
      ],
      authorityBreadcrumb: [
        {
          text: "權限管理",
          active: true,
        },
      ],
      perPage: 8,
      currentPage: 1,
      accountItems: [],
      accountFields: [
        {
          key: "AC_USERID",
          label: "帳號",
        },
        {
          key: "AC_USERNAME",
          label: "使用者名稱",
        },
        {
          key: "AC_EMAIL",
          label: "電子信箱",
        },
        {
          key: "AG_NAME",
          label: "權限",
        },
        {
          key: "AC_STATUS",
          label: "狀態",
        },
        {
          key: "operate",
          label: "操作",
          thClass: "append",
          tdClass: "append",
        },
      ],
      authorityItems: [],
      authorityFields: [
        {
          key: "AG_NAME",
          label: "群組名稱",
        },
        {
          key: "operate",
          label: "操作",
          thClass: "append",
          tdClass: "append",
        },
      ],
      account: {
        Title: null,
        OKTitle: null,
        AC_ID: null,
        Account: null,
        Password: null,
        // PasswordConfirm: null,
        Name: null,
        Email: null,
        Status: 0,
        IdentityOptions: [],
        IdentitySelected: 0,
      },
      authority: {
        AG_ID: null,
        Title: null,
        OKTitle: null,
        Name: null,
        selected: [],
        options: [],
      },
      authorityOptions: [
        { text: "帳號管理", value: "PJ202205100001" },
        { text: "空間圖台", value: "PJ202205100002" },
        { text: "空間分析", value: "PJ202205100004" },
      ],
      authorityOptionsDetail: [
        {
          text: "保安林檢訂成果",
          value: [
            { text: "檢視", value: "PJ202205100006_01" },
            { text: "編輯", value: "PJ202205100006_02" },
          ],
        },
        // {
        //   text: "國土綠網計畫成果",
        //   value: [
        //     { text: "檢視", value: "PJ202205100007_01" },
        //     { text: "編輯", value: "PJ202205100007_02" },
        //   ],
        // },
        {
          text: "林地管理",
          value: [
            { text: "檢視", value: "PJ202205100008_01" },
            { text: "編輯", value: "PJ202205100008_02" },
          ],
        },
        // {
        //   text: "水資源及設施管理",
        //   value: [
        //     { text: "檢視", value: "PJ202205100009_01" },
        //     { text: "編輯", value: "PJ202205100009_02" },
        //   ],
        // },
        {
          text: "林地災害潛勢評估",
          value: [
            { text: "檢視", value: "PJ202205100010_01" },
            { text: "編輯", value: "PJ202205100010_02" },
          ],
        },
      ],
      authorityOptionsDetail2: [
        {
          text: "圖台管理",
          value: [
            { text: "檢視", value: "PJ202205100003_01" },
            { text: "編輯", value: "PJ202205100003_02" },
          ],
        },
      ],
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
        refresh: false,
      },
      deleteModalObj: {
        AC_ID: null,
        AG_ID: null,
        content: " null",
      },
      logUser: "",
      logItems: [],
      logFields: [
        {
          key: "AL_IP",
          label: "IP",
        },
        {
          key: "AL_MDATE",
          label: "登入時間",
        },
        {
          key: "AL_MSG",
          label: "說明",
        },
      ],
      logPerPage: 8,
      logCurrentPage: 1,
      logRows: 0,
      statisticYearOption: [],
      statisticYearSelect: null,
      statisticMonthOption: [],
      statisticMonthSelect: null,
      statisticGroupOption: [],
      statisticGroupSelect: [],
      seriesBar: [],
      chartOptionsBar: {
        chart: {
          type: "bar",
          height: "auto",
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "天";
            },
          },
        },
      },
      apiGetAccountList: (data) =>
        this.userRequest.post("API/Default/GetAccountList", data),
      apiGetAccount_GroupList: (data) =>
        this.userRequest.post("API/Default/GetAccount_GroupList", data),
      apiInsertACCOUNT: (data) =>
        this.userRequest.post("API/Default/InsertACCOUNT", data),
      apiUpdateAccount: (data) =>
        this.userRequest.post("API/Default/UpdateAccount", data),
      apiGetProjectList: (data) =>
        this.userRequest.post("API/Default/GetProjectList", data),
      apiInsertAccount_Group: (data) =>
        this.userRequest.post("API/Default/InsertAccount_Group", data),
      apiUpdateACCOUNT_GROUP: (data) =>
        this.userRequest.post("API/Default/UpdateACCOUNT_GROUP", data),
      apiReSendMail: (data) =>
        this.userRequest.post("API/Default/ReSendMail", data),
      apiAccountLogin: (data) =>
        this.userRequest.post("API/Default/AccountLogin", data),
    };
  },
  computed: {
    rows() {
      return this.accountItems.length;
    },
    checkEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.account.Email).toLowerCase());
    },
  },
  created() {
    this.FunctionToken(this.GetAccountList, {});
    this.FunctionToken(this.GetAccount_GroupList, {});
    this.FunctionToken(this.GetProjectList, {});
  },
  mounted() {
    this.statisticYearSelect = new Date().getFullYear();
    this.statisticMonthSelect = new Date().getMonth() + 1;
    for (let i = 1; i < 13; i++) {
      this.statisticMonthOption.push({
        text: i,
        value: i,
      });
    }
    this.statisticMonthOption.unshift({
      text: "請選擇",
      value: null,
    });
    this.FunctionToken(this.GetChartOption, { TYPE: "CHART_OPTION" });
  },
  watch: {
    statisticGroupOption() {
      if (this.statisticGroupOption.length > 0) {
        for (let index = 0; index < this.statisticGroupOption.length; index++) {
          const element = this.statisticGroupOption[index];
          this.statisticGroupSelect.push(element.value);
        }
        this.FunctionSearch();
      }
    },
  },
  methods: {
    FunctionSetAuthority() {
      console.log(this.authority.selected);

      let arr = this.authority.selected;
      let dataManger = arr.find((o) => o === "PJ202205100004");
      console.log(dataManger);
      if (dataManger === undefined) {
        this.authority.selected.forEach(function (item, index, arr) {
          item === "PJ202205100005" && arr.splice(index, 1);
          item === "PJ202205100005_01" && arr.splice(index, 1);
          item === "PJ202205100005_02" && arr.splice(index, 1);
          item === "PJ202205100006" && arr.splice(index, 1);
          item === "PJ202205100006_01" && arr.splice(index, 1);
          item === "PJ202205100006_02" && arr.splice(index, 1);
          item === "PJ202205100007" && arr.splice(index, 1);
          item === "PJ202205100007_01" && arr.splice(index, 1);
          item === "PJ202205100007_02" && arr.splice(index, 1);
        });
      } else if (dataManger != undefined) {
        this.authority.selected.push(
          "PJ202205100005",
          "PJ202205100005_01",
          "PJ202205100005_02",
          "PJ202205100006",
          "PJ202205100006_01",
          "PJ202205100006_02",
          "PJ202205100007",
          "PJ202205100007_01",
          "PJ202205100007_02",
          "PJ202205100008",
          "PJ202205100008_01",
          "PJ202205100008_02",
          "PJ202205100009",
          "PJ202205100009_01",
          "PJ202205100009_02"
        );
      }
    },
    init() {
      this.FunctionToken(this.GetAccountList, {});
      this.FunctionToken(this.GetAccount_GroupList, {});
      this.FunctionResetModal();
    },
    FunctionDelete(value, key) {
      console.log(value);
      if (key == "account") {
        this.deleteModalObj = {
          AC_ID: value.item.AC_ID,
          content: "確定要刪除此筆帳號嗎?",
        };
      } else if (key == "authority") {
        this.deleteModalObj = {
          AG_ID: value.item.AG_ID,
          content: "確定要刪除此筆權限群組嗎?",
        };
      }
    },
    FunctionResetModal() {
      this.account.AC_ID = null;
      this.account.Account = null;
      this.account.Password = null;
      this.account.Name = null;
      this.account.Email = null;
      this.account.IdentitySelected = this.account.IdentityOptions[0].value;

      this.authority.AG_ID = null;
      this.authority.Name = null;
      this.authority.selected = [];

      this.logUser = "";
      this.logItems = [];
    },
    FunctionAccount() {
      if (this.account.AC_ID == null) {
        var mag = [];
        this.account.Account == null ? mag.push("請填寫帳號") : "";
        this.account.Name == null ? mag.push("請填寫使用者名稱") : "";
        if (this.account.Email != null) {
          if (!this.checkEmail) {
            mag.push("電子信箱格式錯誤");
          }
        } else {
          mag.push("請填寫電子信箱");
        }
        if (mag.length == 0) {
          let json = {};
          json.AC_USERID = this.account.Account;
          json.AC_USERNAME = this.account.Name;
          json.AC_EMAIL = this.account.Email;
          json.AC_PWD = this.account.Password;
          json.AC_GROUP_ID = this.account.IdentitySelected;
          this.FunctionToken(this.InsertACCOUNT, json);
        } else {
          this.alertModalObj.title = "提示";
          this.alertModalObj.show = true;
          this.alertModalObj.content = mag;
          this.alertModalObj.refresh = false;
        }
      } else {
        let json = {};
        json.AC_ID = this.account.AC_ID;
        json.AC_USERID = this.account.Account;
        json.AC_USERNAME = this.account.Name;
        json.AC_EMAIL = this.account.Email;
        json.AC_GROUP_ID = this.account.IdentitySelected;
        json.AC_STATUS = this.account.Status;
        this.FunctionToken(this.UpdateAccount, json);
      }
    },
    FunctionAccount_Group() {
      if (this.authority.AG_ID == null) {
        if (this.IsNull(this.authority.Name)) {
          let json = {};
          json.AG_NAME = this.authority.Name;
          json.AG_PJID = JSON.stringify(this.authority.selected);
          console.log(json);
          this.FunctionToken(this.InsertAccount_Group, json);
        } else {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "請輸入群組名稱";
          this.alertModalObj.refresh = false;
        }
      } else {
        let json = {};
        json.AG_ID = this.authority.AG_ID;
        json.AG_NAME = this.authority.Name;
        json.AG_PJID = JSON.stringify(this.authority.selected);
        // console.log(json);
        this.FunctionToken(this.UpdateACCOUNT_GROUP, json);
      }
    },
    FunctionReSendMail(value) {
      this.isLoad = true;
      this.FunctionToken(this.ReSendMail, { AC_USERID: value.AC_USERID });
    },
    FunctionShowLog(value) {
      console.log(value);
      this.logUser = value.AC_USERNAME;
      this.FunctionToken(this.AccountLogin, {
        TYPE: "LIST",
        ACCOUNT_LOGIN_SELECT: {
          AL_ACID: value.AC_ID,
        },
      });
    },
    FunctionSearch() {
      if (
        this.statisticYearSelect == undefined ||
        this.statisticYearSelect == null ||
        this.statisticYearSelect == ""
      )
        alert("請選擇年份");
      else if (
        this.statisticGroupSelect == undefined ||
        this.statisticGroupSelect == null ||
        this.statisticGroupSelect.length == 0
      )
        alert("請至少選擇一個群組");
      else {
        let json = {
          TYPE: "CHART",
          ACCOUNT_LOGIN_SELECT: {
            searchYear: this.statisticYearSelect,
            searchMonth: this.statisticMonthSelect,
            searchGroup: this.statisticGroupSelect,
          },
        };
        this.FunctionToken(this.GetChart, json);
      }
    },
    //======================api===========================
    //取得帳號列表
    GetAccountList(data) {
      this.apiGetAccountList(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.accountItems = JSON.parse(json.Data);
            console.log(this.accountItems);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // GetAccount_GroupList(data) {
    //   this.apiGetAccount_GroupList(data)
    //     .then((res) => {
    //       let json = JSON.parse(res.data);
    //       if (json.Status) {
    //         // console.log(JSON.parse(json.Data));
    //         let DataArr = JSON.parse(json.Data);
    //         let item = [];
    //         for (let i = 0; i < DataArr.length; i++) {
    //           let Data = {
    //             text: DataArr[i].AG_NAME,
    //             value: DataArr[i].AG_ID,
    //           };
    //           item.push(Data);
    //         }
    //         this.account.IdentitySelected = item[0].value;
    //         this.account.IdentityOptions = item;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    //建立帳號
    InsertACCOUNT(data) {
      this.apiInsertACCOUNT(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "新增成功";
            this.alertModalObj.refresh = true;
          } else {
            console.log(json);
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //更新帳號
    UpdateAccount(data) {
      this.apiUpdateAccount(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          console.log(json);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新帳號成功";
            this.alertModalObj.refresh = true;
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //取得權限清單
    GetProjectList(data) {
      this.apiGetProjectList(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let DataArr = JSON.parse(json.Data);
            let item = [];
            for (let i = 0; i < DataArr.length; i++) {
              let Data = {
                text: DataArr[i].PJ_NAME,
                value: DataArr[i].PJ_ID,
              };
              item.push(Data);
            }
            this.authority.options = DataArr;
            console.log("GetProjectList");
            console.log(DataArr);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //取得群組權限
    GetAccount_GroupList(data) {
      this.apiGetAccount_GroupList(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            console.log("GetAccount_GroupList");
            console.log(JSON.parse(json.Data));
            this.authorityItems = JSON.parse(json.Data);

            let DataArr = JSON.parse(json.Data);
            let item = [];
            for (let i = 0; i < DataArr.length; i++) {
              let Data = {
                text: DataArr[i].AG_NAME,
                value: DataArr[i].AG_ID,
              };
              item.push(Data);
            }
            this.account.IdentitySelected = item[0].value;
            this.account.IdentityOptions = item;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //新增權限清單
    InsertAccount_Group(data) {
      this.apiInsertAccount_Group(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "新增成功";
            this.alertModalObj.refresh = true;
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //更新群組權限
    UpdateACCOUNT_GROUP(data) {
      this.apiUpdateACCOUNT_GROUP(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          // console.log(json);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新群組權限成功";
            this.alertModalObj.refresh = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ReSendMail(data) {
      this.apiReSendMail(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            // const jsonData = JSON.parse(json.Data);
            // console.log(jsonData);
            this.alertModalObj.show = true;
            this.alertModalObj.content = "發送成功";
            this.alertModalObj.refresh = true;
          } else {
            alert(json.Message + "：" + json.Data);
          }
          this.isLoad = false;
        })
        .catch((err) => {
          this.isLoad = false;
          console.log(err);
        });
    },
    AccountLogin(data) {
      this.apiAccountLogin(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            console.log(jsonData);
            this.logItems = jsonData;
            this.logRows = jsonData.length;
            this.$root.$emit("bv::show::modal", "logDetail-modal", "#btnShow");
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetChartOption(data) {
      this.apiAccountLogin(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            console.log(jsonData);
            this.statisticYearOption = jsonData.yearOption;
            this.statisticYearOption.unshift({ value: null, text: "請選擇" });
            this.statisticGroupOption = jsonData.groupOption;
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetChart(data) {
      this.apiAccountLogin(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            console.log(jsonData);
            this.$refs.statisticLoginChart.updateOptions({
              xaxis: {
                categories: jsonData.labels,
              },
            });
            this.seriesBar = [
              {
                name: jsonData.value[0].text,
                data: jsonData.value[0].value,
              },
            ];
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scope>
.access-management {
  ul {
    li {
      ul {
        padding-left: 0;
        padding-bottom: 1rem;
        li {
          list-style: none;
        }
      }
    }
  }
}
</style>
