<template>
  <div>
    <b-row no-gutters align-h="between" align-v="center" class="py-4">
      <b-col cols="auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="RevisionResult">保安林檢訂成果</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ forestName }}
            </li>
          </ol>
        </nav>
      </b-col>
      <b-col cols="auto">
        <b-button
          class="mr-3 mt-1"
          variant="warning"
          v-if="showlatestMap"
          :to="'/Map2D?ML_ID=' + latestMap"
        >
          <b-icon icon="map"></b-icon> 瀏覽圖台</b-button
        >
        <b-button
          v-if="EditPermission"
          class="mr-3 mt-1"
          variant="outline-dark"
          v-b-modal.issue-modal
          @click="
            issue.Title = '新增歷年陳情、議題';
            issue.OKTitle = '新增';
          "
        >
          <b-icon icon="plus"></b-icon> 新增</b-button
        >
      </b-col>
    </b-row>
    <b-col>
      <div class="table-responsive content-box">
        <b-table
          hover
          bordered
          :items="list"
          :fields="fields"
          show-empty
          empty-text="查無資料"
          tbody-tr-class="text-nowrap"
        >
          <template #cell(PR_STARTDATE)="data">
            {{ dateFormat(data.value) }}
          </template>
          <template #cell(PR_SITUATIONDATE)="data">
            {{ dateFormat(data.value) }}
          </template>
          <template #cell(PR_MLID)="data">
            <b-button
              variant="primary"
              title="關聯圖資"
              @click="FunctionLayerModalOpen(data.item)"
              ref="btnShow"
              v-if="Permission.RevisionResult_Editor"
            >
              關聯圖資</b-button
            >
            <b-button
              variant="warning"
              :to="'/Map2D?ML_ID=' + data.item.PR_MLID"
              v-if="data.item.PR_MLID != null"
            >
              <b-icon icon="map"></b-icon
            ></b-button>
          </template>
          <template #cell(PR_COURSEFILE)="data">
            <b-button
              variant="light"
              v-if="data.item.PR_COURSEFILE != null"
              @click="FunctionDownload(data.item.PR_COURSEFILE)"
            >
              <b-icon icon="file-earmark-text"></b-icon
            ></b-button>
          </template>
          <template #cell(PR_OTHERFILE)="data">
            <b-button
              variant="light"
              v-if="data.item.PR_OTHERFILE != null"
              @click="FunctionDownload(data.item.PR_OTHERFILE)"
            >
              <b-icon icon="file-earmark-text"></b-icon
            ></b-button>
          </template>
          <template #cell(operate)="data">
            <b-button-group>
              <b-button
                variant="success"
                v-b-modal.issue-modal
                @click="
                  issue.Title = '編輯歷年陳情、議題';
                  issue.OKTitle = '確定';
                  issue.PR_ID = data.item.PR_ID;
                  issue.itemSelected = data.item.PR_TYPE;
                  issue.StartDate = data.item.PR_STARTDATE;
                  issue.unitSelected = data.item.PR_UNIT;
                  issue.unitDetail = data.item.PR_UNITOTHER;
                  issue.Content = data.item.PR_NOTE;
                  issue.LatestHandle = data.item.PR_SITUATION;
                  issue.LatestDate = data.item.PR_SITUATIONDATE;
                "
              >
                <b-icon icon="pencil"></b-icon> 編輯</b-button
              >
              <b-button
                variant="light"
                v-b-modal.delete-info
                @click="deleteModalObj.PR_ID = data.item.PR_ID"
              >
                <b-icon icon="trash"></b-icon> 刪除</b-button
              >
            </b-button-group>
          </template>
        </b-table>
      </div>
    </b-col>
    <div class="text-center">
      <b-button variant="dark" to="RevisionResult">返回</b-button>
    </div>
    <b-modal
        content-class="shadow"
        size="lg"
        id="issue-modal"
        :title="issue.Title"
        ok-only
        :ok-title="issue.OKTitle"
        ok-variant="primary"
        no-stacking
        scrollable
        centered
        @ok="FunctionCheckIssue"
        @close="FunctionResetModal"
      >
        <b-row>
          <b-col cols="6">
            <b-form-group label="保安林編號">
              <b-form-input
                type="text"
                v-model="forestName"
                disabled
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="6">
            <b-form-group label="*項目">
              <b-form-select
                v-model="issue.itemSelected"
                :options="issue.itemOptions"
              ></b-form-select> </b-form-group
          ></b-col>
        </b-row>
        <b-row>
          <b-col cols="6"
            ><b-form-group label="*陳情人/單位">
              <b-form-select
                v-model="issue.unitSelected"
                :options="issue.unitOptions"
              ></b-form-select>
              <b-form-input
                class="mt-3"
                v-if="issue.unitSelected == '其他'"
                type="text"
                v-model="issue.unitDetail"
                placeholder="請填入陳情人或單位"
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="6">
            <b-form-group label="*起始日期">
              <b-form-datepicker
                type="text"
                v-model="issue.StartDate"
              ></b-form-datepicker> </b-form-group
          ></b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="*簡述">
              <b-form-input
                type="text"
                v-model="issue.Content"
                placeholder="請輸入簡述陳情/議題"
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="6">
            <b-form-group label="最新處理情形">
              <b-form-input
                type="text"
                v-model="issue.LatestHandle"
                placeholder="請輸入最新處理情形"
              ></b-form-input> </b-form-group
          ></b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="最新處理日期">
              <b-form-datepicker
                type="text"
                v-model="issue.LatestDate"
              ></b-form-datepicker> </b-form-group
          ></b-col>
          <b-col cols="6">
            <b-form-group label="*歷程資料上傳">
              <b-form-file
                v-model="issue.HistoryData"
                :state="Boolean(issue.HistoryData)"
                placeholder="請上傳word檔或pdf檔"
                accept=".doc,.docx,.pdf"
              ></b-form-file></b-form-group
          ></b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="其他資料上傳">
              <b-form-file
                v-model="issue.AnotherData"
                :state="Boolean(issue.AnotherData)"
                placeholder="請上傳圖片檔"
                accept=".jpg, .jpeg, .png, .gif"
              ></b-form-file></b-form-group
          ></b-col>
        </b-row>
      </b-modal>
      <b-modal
        content-class="shadow"
        size="lg"
        id="layer-modal"
        title="圖層關聯"
        ok-only
        ok-title="確定"
        ok-variant="primary"
        no-stacking
        scrollable
        centered
        @ok="FunctionLayerModalSave"
        @close="FunctionResetModal"
      >
        <div v-for="(item, index) in layerListOptions" :key="index">
          <p class="bold">{{ item.title }}</p>
          <b-form-group>
            <b-form-checkbox-group
              v-model="layerListSelected"
              :options="item.options"
            >
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </b-modal>
    <AlertModal :alertModalObj="alertModalObj" @getData="FunctionInit" />
    <DeleteModal :deleteModalObj="deleteModalObj" @getData="FunctionInit" />
  </div>
</template>
<script>
import AlertModal from "../../components/AlertModal.vue";
import DeleteModal from "../../components/DeleteModal.vue";

export default {
  props: {
    PF_ID: {
      type: String,
      required: true,
    },
    forestName: {
      type: String,
      required: true,
    },
    latestMap: {
      type: String,
    },
    issueItems: {
      type: Array,
    },
    EditPermission: {
      type: Boolean,
    },
  },
  components: {
    AlertModal,
    DeleteModal,
  },
  data() {
    return {
      fields: [
        {
          key: "PR_STARTDATE",
          label: "起始日期",
        },
        {
          key: "PR_UNIT",
          label: "陳情人/單位",
        },
        {
          key: "PR_UNIT",
          label: "陳情人/單位",
        },
        {
          key: "PR_NOTE",
          label: "簡述",
        },
        {
          key: "PR_SITUATION",
          label: "最新處理情形",
        },
        {
          key: "PR_SITUATIONDATE",
          label: "最新處理日期",
        },
        {
          key: "PR_MLID",
          label: "瀏覽圖台",
        },
        {
          key: "PR_COURSEFILE",
          label: "歷程資料",
        },
        {
          key: "PR_OTHERFILE",
          label: "其他資料",
        },
      ],
      list: [],
      issue: {
        Title: null,
        OKTitle: null,
        PR_ID: null,
        itemSelected: "陳情",
        itemOptions: ["陳情", "議題"],
        StartDate: null,
        Content: null,
        unitSelected: "民眾",
        unitOptions: ["民眾", "民意代表", "機關團體", "其他"],
        unitDetail: null,
        LatestHandle: null,
        LatestDate: null,
        HistoryData: null,
        HistoryDataID: null,
        AnotherData: null,
        AnotherDataID: null,
      },
      uploadType: "",
      formData: new FormData(),
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
        refresh: false,
      },
      deleteModalObj: {
        VD_ID: null,
        PR_ID: null,
        content: "確定要刪除此筆資料嗎?",
      },
      layerListOptions: [],
      layerListSelected: [],
      layerListDataItem: null,
      Permission: null,
      FilesDownloadPermission: [
        "AG5gQhyQhSskmiZbfS9LaXYw",
        "AG6gBawva3jUGitcLTzo14jA",
        "AG8G8Rx1WvTUSqU5qOXLDQFQ",
      ],
      showlatestMap: false,
      // ========================== api ==============================
      apiInsertPFReception: (data) =>
        this.userRequest.post("API/Default/InsertPFReception", data),
      apiUpdatePFReception: (data) =>
        this.userRequest.post("API/Default/UpdatePFReception", data),
      apiUploadMediaCabinet: (data) =>
        this.userRequest.post("API/Default/UploadMediaCabinet", data),
      apiGetRRDMapLayerList: (data) =>
        this.userRequest.post("API/Default/GetRRDMapLayerList", data),
      apiSaveRRDMapLayerList: (data) =>
        this.userRequest.post("API/Default/SaveRRDMapLayerList", data),
      apiGetMyGroupPermission: (data) =>
        this.userRequest.post("API/Default/GetMyGroupPermission", data),
    }
  },
  created() {
    this.FunctionToken(this.GetMyGroupPermission, {});
  },
  methods: {
    //轉換時間
    dateFormat(item) {
      if (item) {
        let date = item.split("T")[0].split("-");
        let substr = date[0] + "年" + date[1] + "月" + date[2] + "日";
        return substr;
      } else {
        return "";
      }
    },
    FunctionInit() {
      this.$emit("getData");
    },
    FunctionResetModal() {
      this.formData = new FormData();
      this.issue = {
        Title: null,
        OKTitle: null,
        PR_ID: null,
        itemSelected: "陳情",
        itemOptions: ["陳情", "議題"],
        StartDate: null,
        Content: null,
        unitSelected: "民眾",
        unitOptions: ["民眾", "民意代表", "機關團體", "其他"],
        unitDetail: null,
        LatestHandle: null,
        LatestDate: null,
        HistoryData: null,
        HistoryDataID: null,
        AnotherData: null,
        AnotherDataID: null,
      };
      this.deleteModalObj = {
        VD_ID: null,
        PR_ID: null,
        content: "確定要刪除此筆資料嗎?",
      };
      this.layerListDataItem = null;
    },
    FunctionCheckIssue() {
      let msg = "";
      if (!this.issue.StartDate) {
        msg += "請選擇起始日期 \r\n";
      }
      if (!this.issue.Content) {
        msg += "請輸入簡述陳情/議題 \r\n";
      }
      if (!msg) {
        let json = {
          PR_PFID: this.PF_ID,
          PR_TYPE: this.issue.itemSelected,
          PR_STARTDATE: this.issue.StartDate,
          PR_UNIT: this.issue.unitSelected,
          PR_UNITOTHER: this.issue.unitDetail,
          PR_NOTE: this.issue.Content,
          PR_SITUATION: this.issue.LatestHandle,
          PR_SITUATIONDATE: this.issue.LatestDate,
          PR_COURSEFILE: this.issue.HistoryDataID,
          PR_OTHERFILE: this.issue.AnotherDataID,
        };
        if (this.issue.PR_ID) {
          json.PR_ID = this.issue.PR_ID;
          this.FunctionToken(this.UpdatePFReception, json);
        } else {
          this.FunctionToken(this.InsertPFReception, json);
        }
      } else {
        this.alertModalObj.show = true;
        this.alertModalObj.content = msg;
        this.alertModalObj.refresh = false;
      }
    },
    FunctionLayerModalOpen(data) {
      this.layerListDataItem = data;
      this.layerListOptions = [];
      this.FunctionToken(this.GetRRDMapLayerList, { PR_ID: data.PR_ID });
    },
    FunctionLayerModalSave() {
      this.FunctionToken(this.SaveRRDMapLayerList, {
        PR_ID: this.layerListDataItem.PR_ID,
        ML_ID: this.layerListSelected,
      });
    },
    FunctionDownload(value) {
      window.open(value);
    },
    //上傳檔案
    UploadMediaCabinet(data) {
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      this.apiUploadMediaCabinet(this.formData).then((res) => {
        let json = JSON.parse(res.data);
        if (json.Status) {
          switch (this.uploadType) {
            case "HistoryData":
              this.issue.HistoryDataID = json.Data;
              break;
            case "AnotherData":
              this.issue.AnotherDataID = json.Data;
              break;
          }
        } else {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "上傳失敗";
          this.alertModalObj.refresh = false;
        }
      });
      this.formData = new FormData();
    },
    //新增保安林_陳情議題
    InsertPFReception(data) {
      this.apiInsertPFReception(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "新增成功";
            this.alertModalObj.refresh = true;
            this.$emit("getData");
          } else {
            console.log(json);
            this.alertModalObj.show = true;
            this.alertModalObj.content = "新增失敗";
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //編輯保安林_檢訂成果
    UpdatePFReception(data) {
      this.apiUpdatePFReception(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新成功";
            this.alertModalObj.refresh = true;
            this.$emit("getData");
          } else {
            console.log(json);
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新失敗";
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetRRDMapLayerList(data) {
      this.apiGetRRDMapLayerList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            jsonData.HeaderList.forEach((x) => {
              const itemList = jsonData.DataList.filter((z) => z.ML_TYPE == x);
              var dataList = [];
              if (itemList.length > 0) {
                itemList.forEach((y) => {
                  dataList.push({
                    text: y.ML_NAME.toString(),
                    value: y.ML_ID.toString(),
                  });
                });
              }
              this.layerListOptions.push({
                title: x,
                options: dataList,
              });
            });
            this.layerListSelected = JSON.parse(jsonData.ML_ID);
            this.$root.$emit("bv::show::modal", "layer-modal", "#btnShow");
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch(() => {
          console.log("error");
        });
    },
    SaveRRDMapLayerList(data) {
      this.apiSaveRRDMapLayerList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新成功";
            this.alertModalObj.refresh = true;
          } else {
            console.log(json);
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetMyGroupPermission(data) {
      this.apiGetMyGroupPermission(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.Permission = jsonData;
            console.log(jsonData);
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    EditPermission() {
      this.fields.push({
        key: "operate",
        label: "功能",
        thClass: "append",
        tdClass: "append",
      });
    },
    issueItems() {
      if (this.issueItems) {
        this.list = this.issueItems;
      }
    },
    latestMap() {
      if (this.latestMap) {
        this.showlatestMap = true;
      }
    },
    "issue.HistoryData"() {
      if (this.issue.HistoryData != null) {
        this.formData.append(
          this.issue.HistoryData.name,
          this.issue.HistoryData
        );
        this.uploadType = "HistoryData";
        this.formData.append("fileCategory", "pdf,word");
        this.FunctionToken(this.UploadMediaCabinet, {});
      }
    },
    "issue.AnotherData"() {
      if (this.issue.AnotherData != null) {
        this.formData.append(
          this.issue.AnotherData.name,
          this.issue.AnotherData
        );
        this.uploadType = "AnotherData";
        this.formData.append("fileCategory", "image");
        this.FunctionToken(this.UploadMediaCabinet, {});
      }
    },
  },
};
</script>
