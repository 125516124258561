<template>
  <div>
    <b-modal
      content-class="shadow"
      id="delete-info"
      title="刪除確認"
      ok-title="確定"
      ok-variant="danger"
      cancel-title="取消"
      no-close-on-backdrop
      hide-backdrop
      centered
      @ok="FunctionDeleteConfirm"
    >
      <p class="mb-0">{{ deleteModalObj.content }}</p>
    </b-modal>
    <b-modal
      content-class="shadow"
      id="alert-model"
      :title="alertModalObj.title"
      ok-only
      ok-title="確定"
      ok-variant="danger"
      cancel-title="取消"
      no-close-on-backdrop
      hide-backdrop
      centered
      @ok="FunctionOK"
      v-model="alertModalObj.show"
    >
      <p class="mb-0">{{ alertModalObj.content }}</p>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
        refresh: false,
      },
      //刪除帳號
      apiDeleteAccount: (data) =>
        this.userRequest.post("API/Default/DeleteAccount", data),
      //刪除群組權限
      apiDeleteACCOUNT_GROUP: (data) =>
        this.userRequest.post("API/Default/DeleteACCOUNT_GROUP", data),
      //刪除圖資
      apiDeleteMapLayer: (data) =>
        this.userRequest.post("API/Default/DeleteMapLayer", data),
      //刪除保安林_歷年檢訂資料除圖資
      apiDeletePFVerificationData: (data) =>
        this.userRequest.post("API/Default/DeletePFVerificationData", data),
      //刪除保安林_歷年檢訂資料
      apiDeletePFReception: (data) =>
        this.userRequest.post("API/Default/DeletePFReception", data),
      // WMS_API: (data) => this.userRequest.post("API/Default/WMS_API", data),
      // POINT_API: (data) => this.userRequest.post("API/Default/POINT_API", data),
    };
  },
  props: ["deleteModalObj"],
  methods: {
    FunctionOK() {
      if (this.alertModalObj.refresh == true) {
        this.deleteModalObj.AC_ID = null;
        this.deleteModalObj.AG_ID = null;
        this.deleteModalObj.WM_ID = null;
        this.deleteModalObj.PO_ID = null;
        this.$emit("getData");
      }
    },
    FunctionDeleteConfirm() {
      console.log(this.deleteModalObj);
      if (this.deleteModalObj.AC_ID != null) {
        this.FunctionToken(this.DeleteAccount, {
          AC_ID: this.deleteModalObj.AC_ID,
        });
      } else if (this.deleteModalObj.AG_ID != null) {
        this.FunctionToken(this.DeleteACCOUNT_GROUP, {
          AG_ID: this.deleteModalObj.AG_ID,
        });
      } else if (this.deleteModalObj.ML_ID != null) {
        this.FunctionToken(this.DeleteMapLayer, {
          ML_ID: this.deleteModalObj.ML_ID,
        });
      } else if (this.deleteModalObj.PR_ID != null) {
        this.FunctionToken(this.DeletePFReception, {
          PR_ID: this.deleteModalObj.PR_ID,
        });
      } else if (this.deleteModalObj.VD_ID != null) {
        this.FunctionToken(this.DeletePFVerificationData, {
          VD_ID: this.deleteModalObj.VD_ID,
        });
      }
    },
    //刪除帳號
    DeleteAccount(data) {
      this.apiDeleteAccount(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "刪除成功";
            this.alertModalObj.refresh = true;
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //刪除群組權限
    DeleteACCOUNT_GROUP(data) {
      this.apiDeleteACCOUNT_GROUP(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "刪除成功";
            this.alertModalObj.refresh = true;
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //刪除圖資
    DeleteMapLayer(data) {
      this.apiDeleteMapLayer(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "刪除成功";
            this.alertModalObj.refresh = true;
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //刪除保安林_歷年檢訂資料
    DeletePFVerificationData(data) {
      this.apiDeletePFVerificationData(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "刪除成功";
            this.alertModalObj.refresh = true;
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //刪除保安林_歷年檢訂資料
    DeletePFReception(data) {
      this.apiDeletePFReception(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "刪除成功";
            this.alertModalObj.refresh = true;
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = json.Data;
            this.alertModalObj.refresh = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // //刪除WMS
    // async DeleteWMSList(WMS_SELECT) {
    //   var PostData = await this.FunctionTokenAsync({ TYPE: "DELETE", WMS_SELECT });
    //   console.log(PostData)
    //   this.WMS_API(PostData)
    //     .then((res) => {
    //       let json = JSON.parse(res.data);
    //       if (json.Status) {
    //         this.alertModalObj.show = true;
    //         this.alertModalObj.content = "刪除成功";
    //         this.alertModalObj.refresh = true;
    //       } else {
    //         this.alertModalObj.show = true;
    //         this.alertModalObj.content = json.Data;
    //         this.alertModalObj.refresh = false;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // //刪除POINT
    // async DeletePOINTList(POINT_SELECT) {
    //   var PostData = await this.FunctionTokenAsync({ TYPE: "DELETE", POINT_SELECT });
    //   console.log(PostData)
    //   this.POINT_API(PostData)
    //     .then((res) => {
    //       let json = JSON.parse(res.data);
    //       if (json.Status) {
    //         this.alertModalObj.show = true;
    //         this.alertModalObj.content = "刪除成功";
    //         this.alertModalObj.refresh = true;
    //       } else {
    //         this.alertModalObj.show = true;
    //         this.alertModalObj.content = json.Data;
    //         this.alertModalObj.refresh = false;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>