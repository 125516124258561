<template>
  <div class="Login">
    <div class="login-box">
      <div class="signin_box">
        <h2 class="main_title">
          林地資訊管理
          <div class="sub_title">
            <span class="bg-info sub_title_text">忘記密碼</span>
          </div>
        </h2>
        <p class="text-danger">
          備註：需要英文大小寫字母與數字及特殊符號組成八碼以上的密碼
        </p>
        <b-form-group label="新密碼">
          <b-form-input
            type="password"
            size="lg"
            placeholder="請輸入新密碼"
            v-model="newPassword"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="請再次輸入新密碼">
          <b-form-input
            type="password"
            size="lg"
            placeholder="請再次輸入新密碼"
            v-model="newPasswordChk"
          ></b-form-input>
        </b-form-group>
        <b-button variant="dark" class="btn btn-block" @click="Send"
          >送出</b-button
        >
      </div>
    </div>
    <div class="logo-box">
      <div class="main-logo">
        <b-img
          :src="require('@/assets/imgs/FANCA_MOA_(Taiwan)_logo.svg.png')"
          fluid
          alt="林務局logo"
        ></b-img>
        <h2>林業及自然保育署臺東分署</h2>
      </div>
    </div>
    <AlertModal :alertModalObj="alertModalObj" />
  </div>
</template>

<script>
import AlertModal from "../components/AlertModal.vue";
export default {
  components: {
    AlertModal,
  },
  name: "login",
  data() {
    return {
      newPassword: null,
      newPasswordChk: null,
      alertModalObj: {
        title: "警告",
        content: null,
        show: false,
      },
      apiResetPassword: (data) =>
        this.userRequest.post("API/Default/ResetPassword", data),
    };
  },
  mounted() {
    console.log(this.$route.query.Token);
    console.log(this.$route.query.Mode);
  },
  methods: {
    Send() {
      if (this.newPassword != this.newPasswordChk) {
        this.alertModalObj.content = "兩次密碼不同";
        this.alertModalObj.show = true;
        this.newPassword = null;
        this.newPasswordChk = null;
      } else {
        let Token = this.$route.query.Token;
        let Mode = this.$route.query.Mode;
        console.log(this.$route.query);
        let json = {};
        json.PassToken = Token;
        json.DecryptionPass = this.newPassword;
        json.Mode = Mode;
        // console.log(json);
        this.FunctionToken(this.ResetPassword, json);
      }
    },
    //重製密碼
    ResetPassword(data) {
      this.apiResetPassword(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          console.log(json);
          if (json.Status) {
            this.alertModalObj.title = "提示";

            this.alertModalObj.show = true;
            if (data.Mode == "J/gtPbHq+dk4PQHEQVN5bA==") {
              sessionStorage.setItem("TokenID", json.Data);
              sessionStorage.removeItem("LoginError");
              this.alertModalObj.url = "/MyAccount";
              this.alertModalObj.content = "更新成功";
            } else {
              this.alertModalObj.url = "/login";
              this.alertModalObj.content = json.Data;
            }
          } else {
            this.alertModalObj.title = "提示";
            this.alertModalObj.content = json.Message + json.Data;
            this.alertModalObj.show = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
