<template>
  <div class="page">
    <div class="page-content">
      <b-breadcrumb class="mb-0" :items="breadcrumb"></b-breadcrumb>
      <!-- <AlertModal :alertModalObj="alertModalObj" @getData="init" />
      <DeleteModal :deleteModalObj="deleteModalObj" @getData="init" /> -->
    </div>
  </div>
</template>
<script>
// import AlertModal from "../../components/AlertModal.vue";
// import DeleteModal from "../../components/DeleteModal.vue";
export default {
  components: {
    // AlertModal,
    // DeleteModal,
  },
  data() {
    return {
      search: null,
      page: 'TestResult',
      breadcrumb: [
        {
          text: "資料管理",
          active: true,
        },
        {
          text: "水資源及設施管理",
        },
      ],
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() { }
  },
};
</script>
