<template>
  <div class="wrapper-map">
    <div id="OLmap" class="map"></div>
    <img
      v-if="img != null"
      :src="img"
      alt="Responsive image"
      style="position: fixed; top: 56px; left: 0"
      crossorigin="anonymous"
    />
    <!-- <div id="zoom-level">Zoom Level:</div> -->
  </div>
</template>

<script>
import jquery from "jquery";
// import WMSCapabilities from "ol/format/WMSCapabilities";
import "ol/ol.css";
import Map from "ol/Map";
//import { OSM, ImageStatic as Static, TileWMS , VectorSource } from "ol/source";
import { OSM, Vector as VectorSource, XYZ } from "ol/source";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import { ScaleLine, defaults as defaultControls } from "ol/control";
//import ImageWMS from "ol/source/ImageWMS";
//import { Projection } from "ol/proj";
import { Vector as VectorLayer } from "ol/layer";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import { LineString, Polygon, Point } from "ol/geom";
import Feature from "ol/Feature";
import Overlay from "ol/Overlay";
import { unByKey } from "ol/Observable";
import { getArea, getLength } from "ol/sphere";
import MousePosition from "ol/control/MousePosition";
import { createStringXY } from "ol/coordinate";
import proj4 from "proj4";
// import { register } from "ol/proj/proj4";
// import { get as getProjection } from "ol/proj";
import { Draw } from "ol/interaction";
import html2Canvas from "html2canvas";
//import Projection from "ol/proj/Projection";

export default {
  name: "OLmapV1",
  props: {
    all: {
      type: [Object],
    },
    val: {
      type: [String],
    },
    formatSelected: {
      type: [String],
    },
  },
  data() {
    return {
      // ========== 測量功能 ==========
      measuringDistance: false,
      MeasureTypeSelect: "LineString",
      MeasureStyle: null,
      MeasureLabelStyle: null,
      MeasureTipStyle: null,
      MeasureModifyStyle: null,
      MeasureModify: null,
      MeasureDraw: null,
      MeasureSegmentStyle: null,
      MeasureSegmentStyles: [],
      MeasureTipPoint: null,
      Proj4: {
        th1: "",
        type: "",
        set1: [
          [
            "EPSG:4326",
            "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees",
          ],
          [
            "EPSG:3826",
            "+title=TWD97 TM2+proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=GRS80 +units=公尺 +no_defs",
          ],
          [
            "EPSG:3828",
            "+proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=aust_SA",
          ],
        ],
        set: {
          "EPSG:4326":
            "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees",
          "EPSG:3826":
            "+title=TWD97 TM2+proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=GRS80 +units=公尺 +no_defs",
          "EPSG:3828":
            "+proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=aust_SA",
        },
      },
      google: "",
      map: null,
      MapLayers: {
        layersIndex: 0,
        layersMane: ["OSM"],
        layers: [
          new TileLayer({
            visible: true,
            source: new OSM({ wrapX: false }),
          }),
        ],
        vectorSource: null,
      },
      view: null,
      helpTooltipElement: null,
      helpTooltip: null,
      measureTooltipElement: null,
      measureTooltip: null,
      draw: null,
      content: "",
      mousePositionControl: null,
      typeSelected: "None",
      Capabilities: null,
      baseMapArr: [
        {
          Name: "臺灣通用電子地圖",
          Layer: new TileLayer({
            source: new XYZ({
              url: "https://wmts.nlsc.gov.tw/wmts/EMAP5/default/EPSG:3857/{z}/{y}/{x}",
              crossOrigin: "*",
            }),
            visible: true,
          }),
        },
        {
          Name: "正射影像圖(2018~2021年)",
          Layer: new TileLayer({
            source: new XYZ({
              url: "https://wmts.nlsc.gov.tw/wmts/PHOTO2/default/EPSG:3857/{z}/{y}/{x}",
              crossOrigin: "*",
            }),
            visible: false,
          }),
        },
      ],
      source_mesure: new VectorSource(),
      pointVectorSource: null,
      iconFeature: [],
      dims: {
        a0: [1189, 841],
        a1: [841, 594],
        a2: [594, 420],
        a3: [420, 297],
        a4: [297, 210],
        a5: [210, 148],
      },
      exportOptions: {
        useCORS: true,
        allowTaint: true,
        foreignObjectRendering: true,
        ignoreElements: function (element) {
          const className = element.className || "";
          return (
            className.toString().includes("ol-control") &&
            !className.toString().includes("ol-scale") &&
            (!className.toString().includes("ol-attribution") ||
              !className.toString().includes("ol-uncollapsible"))
          );
        },
      },
      scaleLine: null,
      compass: false,
      img: null,
    };
  },
  mounted() {
    this.initParameter();
    this.mousePositionControlInit();
    this.init();
    this.measureLayerMap();
    // this.GetCapabilities(
    //   this.geoServerUrl
    // );
  },
  methods: {
    // GetCapabilities(url) {
    //   fetch(url)
    //     .then((response) => {
    //       return response.text();
    //     })
    //     .then((response) => {
    //       var parser = new WMSCapabilities();
    //       var parserResponse = parser.read(response);
    //       console.log(parserResponse.Capability.Layer.Layer[9].BoundingBox[0].extent);
    //       //console.log(parser.read(response));
    //       //var xmlDoc = new DOMParser().parseFromString(response, "text/xml");
    //       // this.Capabilities = xmlDoc;
    //       // console.log("GetCapabilities");
    //       // console.log(xmlDoc);
    //       // console.log(xmlDoc.getElementsByTagName("Capability"));

    //       var convert = require("xml-js");
    //       var result1 = convert.xml2json(response, {
    //         compact: true,
    //         spaces: 4,
    //       });

    //       this.Capabilities = JSON.parse(result1);
    //       // console.log("result1");
    //       // console.log(
    //       //   this.Capabilities.WMT_MS_Capabilities.Capability.Layer.Layer
    //       // );
    //       // console.log(
    //       //   this.Capabilities.WMT_MS_Capabilities.Capability.Layer.Layer[27]
    //       //     .Name._text
    //       // );
    //       //Layer
    //     });
    // },
    WMSZoomTo(Name) {
      var extent =
        this.Capabilities.WMT_MS_Capabilities.Capability.Layer.Layer.find(
          (l) => l.Name._text === Name
        );

      if (extent != undefined) {
        const minX = parseFloat(extent.BoundingBox._attributes.minx);
        const minY = parseFloat(extent.BoundingBox._attributes.miny);
        const maxX = parseFloat(extent.BoundingBox._attributes.maxx);
        const maxY = parseFloat(extent.BoundingBox._attributes.maxy);
        var EX_extent = [minX, minY, maxX, maxY];

        if (extent.BoundingBox._attributes.SRS != "EPSG:4326") {
          var Point1 = [minX, minY];
          var Point2 = [maxX, maxY];

          Point1 = this.Pro4Transform(
            extent.BoundingBox._attributes.SRS,
            "EPSG:4326",
            Point1
          );
          Point2 = this.Pro4Transform(
            extent.BoundingBox._attributes.SRS,
            "EPSG:4326",
            Point2
          );
          EX_extent = [Point1[0], Point1[1], Point2[0], Point2[1]];
        }
        this.view.fit(EX_extent);
      }
    },
    ExtentTo(extent) {
      this.view.fit(extent);
    },
    initParameter() {
      //參數初始化
      if (this.all.center == undefined) {
        //定位台灣中心點
        this.all.center = [121.15270265845061, 22.751375694439577];
      }

      if (this.all.zoom == undefined) {
        this.all.zoom = 12;
      }

      if (this.all.projection == undefined) {
        this.all.projection = "EPSG:4326";
      }
    },
    init() {
      this.view = new View({
        center: this.all.center,
        zoom: this.all.zoom,
        projection: this.all.projection,
      });

      //初始化地圖
      var Options = {
        layers: this.all.layers,
        target: "OLmap",
        view: this.view,
        controls: defaultControls({
          attributionOptions: { collapsible: false },
        }),
      };

      if (this.mousePositionControl != null) {
        Options.controls = defaultControls().extend([
          this.mousePositionControl,
        ]);
      }

      this.map = new Map(Options);

      this.baseMapArr.forEach((X) => this.map.addLayer(X.Layer));
      // console.log('MapLayers')
      // console.log(this.MapLayers)
      //比例尺
      this.scaleLine = new ScaleLine({
        bar: true,
        text: true,
        minWidth: 125,
      });
      // const scaleLine = new ScaleLine({ minWidth: 125 });
      this.map.addControl(this.scaleLine);

      // // 獲取顯示縮放級別的 HTML 元素
      // const zoomLevelElement = document.getElementById("zoom-level");
      // const _this = this;
      // this.map.on("moveend", function (event) {
      //   console.log(event);
      //   const zoomLevel = _this.map.getView().getZoom(); // 獲取當前縮放級別
      //   zoomLevelElement.textContent = "Zoom Level: " + zoomLevel;
      // });
    },
    //座標轉換
    Pro4Transform(from, to, point) {
      return proj4(this.Proj4.set[from], this.Proj4.set[to], point);
    },
    //切換中心點
    fitTo(point, zoom) {
      //point 格式 [120.9738819, 23.97565]
      //zoom 格式 1~23正整數
      //父頁面直接呼叫 $refs.map.fitTo([120.9738819, 23.97565],17) zoom 可不給
      this.view.setCenter(point);
      if (zoom != undefined) this.view.setZoom(zoom);
    },
    /// <summary>
    /// 生成圖標
    /// </summary>
    CreateIconFeature(coordinate, iconType = "openlayer/img09.png") {
      if (this.pointVectorSource != null) {
        this.pointVectorSource.clear();
      } else {
        this.pointVectorSource = new VectorSource({
          features: this.iconFeature,
          wrapX: false,
          zIndex: 5,
        });
        let pointVectorLayer = new VectorLayer({
          source: this.pointVectorSource,
          visible: true,
        });
        this.map.addLayer(pointVectorLayer);
      }

      const geom = new Point(coordinate);
      const feature = new Feature(geom);
      feature.setStyle(
        new Style({
          image: new Icon({
            anchor: [0.5, 1],
            scale: 0.2,
            src: iconType,
          }),
        })
      ); // 'openlayer/img06.png'
      this.iconFeature.push(feature);
      this.pointVectorSource.addFeatures(this.iconFeature);
    },
    /// <summary>
    /// 請除所有圖標
    /// </summary>
    ClearAllIconFeature() {
      if (this.pointVectorSource != null) {
        this.pointVectorSource.clear();
        this.iconFeature = [];
      }
    },
    //底圖切換
    ChangeBasemap(Name) {
      console.log(Name);
      const found = this.baseMapArr.find((element) => element.Name == Name);

      if (found != undefined) {
        this.baseMapArr.forEach((X) => X.Layer.setVisible(false));
        found.Layer.setVisible(true);
      }
    },
    mousePositionControlInit() {
      //滑鼠座標控制
      //使用方式
      //在父頁面命名一個 物件即可使用 <div style="width: 150px; height: 90px" id="mouse-position"></div>
      //在父頁面呼叫  $refs.map.mousePositionControl.setProjection('EPSG:4326') 可改變輸出座標
      this.mousePositionControl = new MousePosition({
        coordinateFormat: createStringXY(4),
        projection: "EPSG:4326",
        className: "gpsStyle",
        target: document.getElementById("mouse-position"),
      });
    },
    addInteraction() {
      var type = this.typeSelected;
      if (type !== "None") {
        this.draw = new Draw({
          source: this.source_mesure,
          type: type,
          style: new Style({
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
            stroke: new Stroke({
              color: "rgba(0, 0, 0, 0.5)",
              lineDash: [10, 10],
              width: 2,
            }),
            image: new CircleStyle({
              radius: 5,
              stroke: new Stroke({
                color: "rgba(0, 0, 0, 0.7)",
              }),
              fill: new Fill({
                color: "rgba(255, 255, 255, 0.2)",
              }),
            }),
          }),
        });
        this.map.addInteraction(this.draw);
        var listener;

        this.draw.on("drawstart", (evt) => {
          this.sketch = evt.feature;

          var tooltipCoord = evt.coordinate;

          listener = this.sketch.getGeometry().on("change", (evt) => {
            var geom = evt.target;
            var output;
            if (geom instanceof Polygon) {
              output = this.formatArea(geom);
              tooltipCoord = geom.getInteriorPoint().getCoordinates();
            } else if (geom instanceof LineString) {
              output = this.formatLength(geom);
              tooltipCoord = geom.getLastCoordinate();
            }
            this.measureTooltipElement.innerHTML = output;
            this.measureTooltip.setPosition(tooltipCoord);
          });
        });

        this.draw.on("drawend", () => {
          this.measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
          this.measureTooltip.setOffset([0, -7]);
          this.sketch = null;
          this.measureTooltipElement = null;
          this.createMeasureTooltip();
          unByKey(listener);
        });
      }
      this.createMeasureTooltip();
      this.createHelpTooltip();
    },
    // 新增計算區塊
    createMeasureTooltip() {
      if (this.measureTooltipElement) {
        this.measureTooltipElement.parentNode.removeChild(
          this.measureTooltipElement
        );
      }
      this.measureTooltipElement = document.createElement("div");
      this.measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [0, -15],
        positioning: "bottom-center",
      });
      this.map.addOverlay(this.measureTooltip);
    },
    // 新增提示區塊
    createHelpTooltip() {
      if (this.helpTooltipElement) {
        this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
      }
      this.helpTooltipElement = document.createElement("div");
      this.helpTooltipElement.className = "ol-tooltip hidden";
      this.helpTooltip = new Overlay({
        element: this.helpTooltipElement,
        offset: [15, 0],
        positioning: "center-left",
      });
      this.map.addOverlay(this.helpTooltip);
    },
    setVisible(index, bool) {
      this.all.layers[index].setVisible(bool);
    },
    // 生成計算層
    measureLayerMap() {
      // 畫完路徑顯示的圖層
      var vector_mesure = new VectorLayer({
        source: this.source_mesure,
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
          stroke: new Stroke({
            color: "#ffcc33",
            width: 2,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: "#ffcc33",
            }),
          }),
        }),
      });

      var continuePolygonMsg = "請繼續點選區塊";
      var continueLineMsg = "請繼續點選線條";
      var pointerMoveHandler = (evt) => {
        if (evt.dragging) {
          return;
        }

        // const pixel = this.map.getEventPixel(evt.originalEvent);
        // const hit = this.map.hasFeatureAtPixel(pixel);
        // document.getElementById("map").style.cursor = hit ? "pointer" : "";

        var helpMsg = "點選即開始描圖";

        if (this.sketch) {
          var geom = this.sketch.getGeometry();
          if (geom instanceof Polygon) {
            helpMsg = continuePolygonMsg;
          } else if (geom instanceof LineString) {
            helpMsg = continueLineMsg;
          }
        }

        this.helpTooltipElement.innerHTML = helpMsg;
        this.helpTooltip.setPosition(evt.coordinate);
        if (this.typeSelected !== "None") {
          this.helpTooltipElement.classList.remove("hidden");
        } else {
          this.helpTooltipElement.classList.add("hidden");
        }
      };

      // 將圖層加入map
      this.map.addLayer(vector_mesure);
      this.map.on("pointermove", pointerMoveHandler);

      // 移出範圍隱藏提示
      this.map.getViewport().addEventListener("mouseout", () => {
        this.helpTooltipElement.classList.add("hidden");
      });

      this.addInteraction();
    },
    typeSelectOnchange(value) {
      this.typeSelected = value;
      // 如果更換模式
      if (this.typeSelected === "None") {
        this.clearMeasure();
      }

      this.map.removeInteraction(this.draw);

      this.addInteraction();
    },
    // 清除計算圖層
    clearMeasure() {
      this.source_mesure.clear();
      jquery(".ol-tooltip-static").remove();
    },
    // 計算距離
    formatLength(line) {
      var length = getLength(line, this.view.getProjection()) * 100;
      var output;
      if (length > 1) {
        output = Math.round(length * 100000) / 100000 + " km";
      } else {
        output = Math.round(length * 100000) / 100 + " m";
      }
      return output;
    },
    // 計算面積
    formatArea(polygon) {
      var area = getArea(polygon) * 10000;
      var output;
      if (area > 1) {
        output =
          Math.round(area * 100) / 100 +
          " km\xB2 \r\n " +
          Math.round(area * 100) +
          "公頃";
      } else {
        output =
          Math.round(area * 100000000) / 100 +
          " m\xB2  \r\n " +
          Math.round(area * 100000000) +
          "公頃";
      }
      return output;
    },
    FunctionPrintPdf(compass) {
      const map = this.map;
      const target = this;
      this.compass = compass;
      target.exportOptions.width = document.getElementById("OLmap").offsetWidth;
      target.exportOptions.height =
        document.getElementById("OLmap").offsetHeight;
      target.exportOptions.y =
        document.getElementById("headerBar").offsetHeight * -1;
      target.exportOptions.backgroundColor = null;
      html2Canvas(map.getViewport(), target.exportOptions).then(function (
        canvas
      ) {
        target.img = canvas.toDataURL("image/jpeg");
      });
      // const dim = this.dims[format];
      // const width = Math.round((dim[0] * resolution) / 25.4);
      // const height = Math.round((dim[1] * resolution) / 25.4);
      // target.exportOptions.width = width;
      // target.exportOptions.height = height;
      // target.exportOptions.y = 0;
    },
  },
  watch: {
    img() {
      if (this.img != null) {
        const format = this.formatSelected;
        const dim = this.dims[format];
        this.getPdf("截圖", this.exportOptions, dim, this.compass);
        this.img = null;
      }
    },
  },
};
</script>

<style scoped>
.wrapper-map {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
#OLmap,
.map {
  height: 100%;
  width: 100%;
}
</style>

<style lang="scss">
.color-ramp {
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  .card {
    max-width: 120px;
    .sub-title {
      font-size: 0.9rem;
      text-align: center;
      border-bottom: 1px transparentize(#000000, 0.5) dotted;
    }
    .card-title {
      font-size: 1rem;
    }
    .text span {
      display: block;
      font-size: 0.5rem;
      line-height: 1.35;
    }
    .color {
      padding-top: 0.5rem;
      padding-left: 0.5rem;
      span {
        height: 1rem;
        display: block;
        &:nth-child(1) {
          background-color: #c700f9;
        }
        &:nth-child(2) {
          background-color: #ffffff;
        }
        &:nth-child(3) {
          background-color: #0000f9;
        }
        &:nth-child(4) {
          background-color: #940000;
        }
        &:nth-child(5) {
          background-color: #b90000;
        }
        &:nth-child(6) {
          background-color: #df6057;
        }
        &:nth-child(7) {
          background-color: #ced06b;
        }
        &:nth-child(8) {
          background-color: #fefe78;
        }
        &:nth-child(9) {
          background-color: #68ba2a;
        }
        &:nth-child(10) {
          background-color: #8ffb95;
        }
        &:nth-child(11) {
          background-color: #b46e6e;
        }
        &:nth-child(12) {
          background-color: #d68a8c;
        }
        &:nth-child(13) {
          background-color: #eaa8a9;
        }
        &:nth-child(14) {
          background-color: #757575;
        }
        &:nth-child(15) {
          background-color: #9a9a9a;
        }
        &:nth-child(16) {
          background-color: #bc9ad7;
        }
        &:nth-child(17) {
          background-color: #a564d8;
        }
      }
    }
  }
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;

  &.hidden {
    display: none;
  }
}

.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;

  &:after,
  &:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;

  &:after {
    content: "✖";
  }
}

.position-div {
  position: absolute;
}
</style>

<style>
#zoom-level {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 5px;
}
</style>
