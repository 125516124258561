<template>
  <div>
    <b-row no-gutters align-h="between" align-v="center" class="py-4">
      <b-col cols="auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="RevisionResult">保安林檢訂成果</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ forestName }}
            </li>
          </ol>
        </nav>
      </b-col>
      <b-col cols="auto">
        <b-button
          v-if="EditPermission"
          class="mr-3 mt-1"
          variant="outline-dark"
          v-b-modal.result-modal
          @click="
            result.Title = '新增檢訂成果';
            result.OKTitle = '新增';
          "
        >
          <b-icon icon="plus"></b-icon> 新增</b-button
        >
      </b-col>
    </b-row>
    <b-col>
      <div class="table-responsive content-box">
        <b-table
          hover
          :items="list"
          :fields="fields"
          class="table table-bordered"
          show-empty
          empty-text="查無資料"
          tbody-tr-class="text-nowrap"
        >
          <template #cell(VD_MLID)="data">
            <b-button
              variant="warning"
              :to="'/Map2D?ML_ID=' + data.item.VD_MLID"
              v-if="data.item.VD_MLID != null"
            >
              <b-icon icon="map"></b-icon
            ></b-button>
          </template>
          <template #cell(VD_REGISTER)="data">
            <b-button
              variant="light"
              v-if="data.item.VD_REGISTER != null"
              @click="FunctionDownload(data.item.VD_REGISTER)"
            >
              <b-icon
                icon="file-earmark-word"
                v-if="
                  data.item.VD_REGISTER.includes('.docx') ||
                  data.item.VD_REGISTER.includes('.doc')
                "
              ></b-icon
              ><b-icon
                icon="file-earmark-excel"
                v-else-if="
                  data.item.VD_REGISTER.includes('.xlsx') ||
                  data.item.VD_REGISTER.includes('.xls')
                "
              ></b-icon
              ><b-icon icon="file-earmark-text" v-else></b-icon
            ></b-button>
            <b-button
              variant="light"
              v-if="data.item.VD_REGISTER_PDF != null"
              @click="FunctionDownload(data.item.VD_REGISTER_PDF)"
            >
              <b-icon icon="file-earmark-text"></b-icon
            ></b-button>
          </template>
          <template #cell(VD_DETAILS)="data">
            <b-button
              variant="light"
              v-if="data.item.VD_DETAILS != null"
              @click="FunctionDownload(data.item.VD_DETAILS)"
            >
              <b-icon
                icon="file-earmark-word"
                v-if="
                  data.item.VD_DETAILS.includes('.docx') ||
                  data.item.VD_DETAILS.includes('.doc')
                "
              ></b-icon
              ><b-icon
                icon="file-earmark-excel"
                v-else-if="
                  data.item.VD_DETAILS.includes('.xlsx') ||
                  data.item.VD_DETAILS.includes('.xls')
                "
              ></b-icon
              ><b-icon icon="file-earmark-text" v-else></b-icon
            ></b-button>
            <b-button
              variant="light"
              v-if="data.item.VD_DETAILS_PDF != null"
              @click="FunctionDownload(data.item.VD_DETAILS_PDF)"
            >
              <b-icon icon="file-earmark-text"></b-icon
            ></b-button>
          </template>
          <template #cell(VD_INVESTIGATION)="data">
            <b-button
              variant="light"
              title="加密檔案下載"
              v-if="data.item.VD_INVESTIGATION != null"
              @click="FunctionZipDownload(data.item.VD_INVESTIGATION)"
            >
              <b-icon icon="file-earmark-zip"></b-icon
            ></b-button>
          </template>
          <template #cell(VD_REPORT)="data">
            <b-button
              variant="light"
              title="加密檔案下載"
              v-if="data.item.VD_REPORT != null"
              @click="FunctionZipDownload(data.item.VD_REPORT)"
            >
              <b-icon icon="file-earmark-zip"></b-icon
            ></b-button>
          </template>
          <template #cell(operate)="data">
            <b-button-group>
              <b-button
                variant="success"
                v-b-modal.result-modal
                @click="
                  result.VD_ID = data.item.VD_ID;
                  result.itemSelected = data.item.VD_ITEM;
                  result.itemDetail = data.item.VD_INDEX;
                  result.Year = data.item.VD_YEAR;
                  result.CheckedArea = data.item.VD_CHECKEDAREA;
                  result.ForestArea = data.item.VD_FORESTAREA;
                  result.DocumentNo = data.item.VD_DOCUMENTNO;
                  result.TreeType = data.item.VD_TREETYPE;
                  result.Note = data.item.VD_NOTE;
                  result.Title = '編輯檢訂成果';
                  result.OKTitle = '確定';
                "
              >
                <b-icon icon="pencil"></b-icon> 編輯</b-button
              >
              <b-button
                variant="light"
                v-b-modal.delete-info
                @click="deleteModalObj.VD_ID = data.item.VD_ID"
              >
                <b-icon icon="trash"></b-icon> 刪除</b-button
              >
            </b-button-group>
          </template>
        </b-table>
      </div>
    </b-col>
    <div class="text-center">
      <b-button variant="dark" to="RevisionResult">返回</b-button>
    </div>

    <b-modal
      content-class="shadow"
      size="lg"
      id="result-modal"
      :title="result.Title"
      ok-only
      :ok-title="result.OKTitle"
      ok-variant="primary"
      no-stacking
      scrollable
      centered
      @ok="FunctionCheckResult"
      @close="FunctionResetModal"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group label="保安林編號">
            <b-form-input
              type="text"
              v-model="forestName"
              disabled
            ></b-form-input> </b-form-group
        ></b-col>
        <b-col cols="6">
          <b-form-group label="*項目">
            <b-form-select
              v-model="result.itemSelected"
              :options="result.itemOptions"
            ></b-form-select>
            <b-form-input
              class="mt-3"
              v-if="result.itemSelected == '檢訂'"
              type="number"
              v-model="result.itemDetail"
              placeholder="請輸入第幾次"
            ></b-form-input> </b-form-group
        ></b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="*年度">
            <b-form-input
              type="number"
              v-model="result.Year"
              placeholder="請輸入項目年度"
            ></b-form-input> </b-form-group
        ></b-col>
        <b-col cols="6">
          <b-form-group label="*檢訂後面積(公頃)">
            <b-form-input
              type="number"
              v-model="result.CheckedArea"
              placeholder="請輸入面積"
            ></b-form-input> </b-form-group
        ></b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="*核定公告文號">
            <b-form-input
              type="text"
              v-model="result.DocumentNo"
              placeholder="請輸入公告文號"
            ></b-form-input> </b-form-group
        ></b-col>
        <b-col cols="6">
          <b-form-group label="樹種">
            <b-form-input
              type="text"
              v-model="result.TreeType"
              placeholder="請輸入樹種"
            ></b-form-input> </b-form-group
        ></b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="造林面積(公頃)">
            <b-form-input
              type="number"
              v-model="result.ForestArea"
              placeholder="請輸入面積"
            ></b-form-input> </b-form-group
        ></b-col>
        <b-col cols="6"
          ><b-form-group label="備註">
            <b-form-input
              type="text"
              v-model="result.Note"
              placeholder="請輸入備註(ex.解編原因)"
            ></b-form-input> </b-form-group
        ></b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="圖資上傳(座標系統TWD97)">
            <b-form-file
              v-model="result.FileMap"
              :state="Boolean(result.FileMap)"
              placeholder="請上傳zip檔,應包含.shp、.shx、.dbf"
              accept=".zip"
            ></b-form-file></b-form-group
        ></b-col>
        <b-col cols="6">
          <b-form-group label="登記簿上傳">
            <b-form-file
              v-model="result.FileRegister"
              :state="Boolean(result.FileRegister)"
              placeholder="請上傳word或excel或pdf"
              accept=".doc,.docx,.xls,.xlsx,.pdf"
            ></b-form-file></b-form-group
        ></b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="檢訂後造林樹種明細表/解除區域明細表上傳"
            ><b-form-file
              v-model="result.FileDetail"
              :state="Boolean(result.FileDetail)"
              placeholder="請上傳word或excel或pdf"
              accept=".doc,.docx,.xls,.xlsx,.pdf"
            ></b-form-file> </b-form-group
        ></b-col>
        <b-col cols="6">
          <b-form-group label="檢訂/解除調查書上傳">
            <b-form-file
              v-model="result.FileInvestigation"
              :state="Boolean(result.FileInvestigation)"
              placeholder="請上傳word或excel或pdf"
              accept=".doc,.docx,.xls,.xlsx,.pdf"
            ></b-form-file></b-form-group
        ></b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="檢訂報告書上傳">
            <b-form-file
              v-model="result.FileReport"
              :state="Boolean(result.FileReport)"
              placeholder="請上傳word或pdf"
              accept=".doc,.docx,.pdf"
            ></b-form-file></b-form-group
        ></b-col>
      </b-row>
    </b-modal>
    <AlertModal :alertModalObj="alertModalObj" @getData="FunctionInit" />
    <DeleteModal :deleteModalObj="deleteModalObj" @getData="FunctionInit" />
  </div>
</template>

<script>
import AlertModal from "../../components/AlertModal.vue";
import DeleteModal from "../../components/DeleteModal.vue";

export default {
  props: {
    PF_ID: {
      type: String,
      required: true,
    },
    forestName: {
      type: String,
      required: true,
    },
    resultItems: {
      type: Array,
    },
    EditPermission: {
      type: Boolean,
    },
  },
  components: {
    AlertModal,
    DeleteModal,
  },
  data() {
    return {
      fields: [
        {
          key: "VD_YEAR",
          label: "年度",
        },
        {
          key: "VD_ITEM",
          label: "項目",
        },
        {
          key: "VD_CHECKEDAREA",
          label: "檢訂後面積(公頃)",
        },
        {
          key: "VD_DOCUMENTNO",
          label: "核訂公告文號",
        },
        {
          key: "VD_TREETYPE",
          label: "樹種",
        },
        {
          key: "VD_FORESTAREA",
          label: "造林面積(公頃)",
        },
        {
          key: "VD_NOTE",
          label: "備註",
        },
        {
          key: "VD_MLID",
          label: "瀏覽圖台",
        },
        {
          key: "VD_REGISTER",
          label: "登記簿",
        },
        {
          key: "VD_DETAILS",
          label: "檢訂後造林樹種/解除區域明細表",
        },
        {
          key: "VD_INVESTIGATION",
          label: "檢訂/解除調查書",
        },
        {
          key: "VD_REPORT",
          label: "檢訂報告書",
        },
      ],
      list: [],
      result: {
        Title: null,
        OKTitle: null,
        VD_ID: null,
        itemSelected: "編入",
        itemOptions: ["編入", "檢訂", "解編"],
        itemDetail: null,
        Year: null,
        CheckedArea: null,
        ForestArea: null,
        DocumentNo: null,
        TreeType: null,
        Note: null,
        FileMap: null,
        FileMapID: null,
        FileDetail: null,
        FileDetailID: null,
        FileRegister: null,
        FileRegisterID: null,
        FileReport: null,
        FileReportID: null,
        FileInvestigation: null,
        FileInvestigationID: null,
      },
      uploadType: "",
      formData: new FormData(),
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
        refresh: false,
      },
      deleteModalObj: {
        VD_ID: null,
        PR_ID: null,
        content: "確定要刪除此筆資料嗎?",
      },
      // ========================== api ==============================
      apiInsertPFVerificationData: (data) =>
        this.userRequest.post("API/Default/InsertPFVerificationData", data),
      apiUpdatePFVerificationData: (data) =>
        this.userRequest.post("API/Default/UpdatePFVerificationData", data),
      apiInsertMapLayer: (data) =>
        this.userRequest.post("API/Default/InsertMapLayer", data),
      apiUploadMediaCabinet: (data) =>
        this.userRequest.post("API/Default/UploadMediaCabinet", data),
      apiZipDownload: (data) =>
        this.userRequest.post("API/Default/ZipDownload", data),
    };
  },
  mounted() {},
  methods: {
    FunctionInit() {
      this.$emit("getData");
    },
    FunctionResetModal() {
      this.formData = new FormData();
      this.result = {
        Title: null,
        OKTitle: null,
        VD_ID: null,
        itemSelected: "編入",
        itemOptions: ["編入", "檢訂", "解編"],
        itemDetail: null,
        Year: null,
        CheckedArea: null,
        ForestArea: null,
        DocumentNo: null,
        TreeType: null,
        Note: null,
        FileMap: null,
        FileMapID: null,
        FileDetail: null,
        FileDetailID: null,
        FileRegister: null,
        FileRegisterID: null,
        FileReport: null,
        FileReportID: null,
        FileInvestigation: null,
        FileInvestigationID: null,
      };
      this.deleteModalObj = {
        VD_ID: null,
        PR_ID: null,
        content: "確定要刪除此筆資料嗎?",
      };
    },
    FunctionCheckResult() {
      let msg = "";
      if (!this.result.CheckedArea) {
        msg += "請輸入面積 \r\n";
      }
      if (!this.result.DocumentNo) {
        msg += "請輸入公告文號 \r\n";
      }
      if (!this.result.Year) {
        msg += "請輸入項目年度 \r\n";
      }
      if (this.result.itemSelected == "檢訂" && !this.result.itemDetail) {
        msg += "請輸入第幾次檢訂 \r\n";
      }
      if (!msg) {
        let json = {
          VD_PFID: this.PF_ID,
          VD_ITEM: this.result.itemSelected,
          VD_INDEX: this.result.itemDetail,
          VD_YEAR: this.result.Year,
          VD_CHECKEDAREA: this.result.CheckedArea,
          VD_DOCUMENTNO: this.result.DocumentNo,
          VD_TREETYPE: this.result.TreeType,
          VD_FORESTAREA: this.result.ForestArea,
          VD_NOTE: this.result.Note,
          VD_REGISTER: this.result.FileRegisterID,
          VD_DETAILS: this.result.FileDetailID,
          VD_INVESTIGATION: this.result.FileInvestigationID,
          VD_MLID: this.result.FileMapID,
          VD_REPORT: this.result.FileReportID,
        };
        if (this.result.VD_ID) {
          json.VD_ID = this.result.VD_ID;
          this.FunctionToken(this.UpdatePFVerificationData, json);
        } else {
          this.FunctionToken(this.InsertPFVerificationData, json);
        }
      } else {
        this.alertModalObj.show = true;
        this.alertModalObj.content = msg;
        this.alertModalObj.refresh = false;
      }
    },
    FunctionDownload(value) {
      window.open(value);
    },
    FunctionZipDownload(item) {
      this.FunctionToken(this.ZipDownload, {
        PF_ID: item,
      });
    },
    // ========================== api ==============================
    //新增保安林_檢訂成果
    InsertPFVerificationData(data) {
      this.apiInsertPFVerificationData(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "新增成功";
            this.alertModalObj.refresh = true;
            this.$emit("getData");
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "新增失敗";
            this.alertModalObj.refresh = false;
          }
        })
        .catch(() => {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "新增失敗";
          this.alertModalObj.refresh = false;
        });
    },
    //編輯保安林_檢訂成果
    UpdatePFVerificationData(data) {
      this.apiUpdatePFVerificationData(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新成功";
            this.alertModalObj.refresh = true;
            this.$emit("getData");
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "更新失敗";
            this.alertModalObj.refresh = false;
          }
        })
        .catch(() => {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "更新失敗";
          this.alertModalObj.refresh = false;
        });
    },
    // 新增圖層
    InsertMapLayer(data) {
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      this.apiInsertMapLayer(this.formData)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.result.FileMapID = jsonData.ML_ID;
            this.formData = new FormData();
          } else {
            this.alertModalObj.show = true;
            this.alertModalObj.content = "上傳失敗";
            this.alertModalObj.refresh = false;
            this.formData = new FormData();
          }
        })
        .catch(() => {
          console.log("上傳失敗");
          this.formData = new FormData();
        });
    },
    //上傳檔案
    UploadMediaCabinet(data) {
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      this.apiUploadMediaCabinet(this.formData).then((res) => {
        let json = JSON.parse(res.data);
        if (json.Status) {
          switch (this.uploadType) {
            case "Map":
              this.result.FileMapID = json.Data;
              break;
            case "Register":
              this.result.FileRegisterID = json.Data;
              break;
            case "Detail":
              this.result.FileDetailID = json.Data;
              break;
            case "Investigation":
              this.result.FileInvestigationID = json.Data;
              break;
            case "FileReport":
              this.result.FileReportID = json.Data;
              break;
          }
          this.formData = new FormData();
        } else {
          this.alertModalObj.show = true;
          this.alertModalObj.content = "上傳失敗";
          this.alertModalObj.refresh = false;
        }
      });
    },
    ZipDownload(data) {
      this.apiZipDownload(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.$bvModal
              .msgBoxOk("您下載的檔案因含有個資機敏資料，需要輸入密碼得以開啟檔案查閱(密碼已寄送至信箱)，請妥善使用並遵守相關法規。", {
                okTitle: "確定",
              })
              .then((value) => {
                if (value) {
                  console.log(jsonData);
                  const link = document.createElement("a");
                  link.href = jsonData.url;
                  link.setAttribute("download", jsonData.name);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              })
              .catch(() => {
                console.log("fail");
              });
          } else {
            console.log("fail");
          }
        })
        .catch(() => {
          console.log("fail");
        });
    },
  },
  watch: {
    EditPermission() {
      this.fields.push({
        key: "operate",
        label: "功能",
        thClass: "append",
        tdClass: "append",
      });
    },
    resultItems() {
      if (this.resultItems) {
        this.list = this.resultItems;
      }
    },
    "result.FileMap"() {
      if (this.result.FileMap != null) {
        this.formData.append("ML_TYPE", "保安林檢訂");
        this.formData.append("ML_CATEGORY", "SHP");
        this.formData.append("ML_NAME", this.result.Year + this.forestName);
        this.formData.append("ML_LAYERMODE", 3);
        this.formData.append(this.result.FileMap.name, this.result.FileMap);
        this.uploadType = "Map";
        this.FunctionToken(this.InsertMapLayer, {});
      }
    },
    "result.FileRegister"() {
      if (this.result.FileRegister != null) {
        this.formData.append(
          this.result.FileRegister.name,
          this.result.FileRegister
        );
        this.uploadType = "Register";
        this.formData.append("fileCategory", "pdf,word,excel");
        this.FunctionToken(this.UploadMediaCabinet, {});
      }
    },
    "result.FileDetail"() {
      if (this.result.FileDetail != null) {
        this.formData.append(
          this.result.FileDetail.name,
          this.result.FileDetail
        );
        this.uploadType = "Detail";
        this.formData.append("fileCategory", "pdf,word,excel");
        this.FunctionToken(this.UploadMediaCabinet, {});
      }
    },
    "result.FileInvestigation"() {
      if (this.result.FileInvestigation != null) {
        this.formData.append(
          this.result.FileInvestigation.name,
          this.result.FileInvestigation
        );
        this.uploadType = "Investigation";
        this.formData.append("fileCategory", "pdf,word,excel");
        this.FunctionToken(this.UploadMediaCabinet, {});
      }
    },
    "result.FileReport"() {
      if (this.result.FileReport != null) {
        this.formData.append(
          this.result.FileReport.name,
          this.result.FileReport
        );
        this.uploadType = "FileReport";
        this.formData.append("fileCategory", "pdf,word");
        this.FunctionToken(this.UploadMediaCabinet, {});
      }
    },
  },
};
</script>
