<template>
  <div class="page" style="height: 100%">
    <div class="page-content" style="height: 100%">
      <div>
        <b-row no-gutters align-h="between" align-v="center">
          <b-col cols="auto">
            <b-breadcrumb class="mb-0" :items="breadcrumb"></b-breadcrumb
          ></b-col>
          <b-col cols="auto"> </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col md="5" style="height: 720px">
          <div class="legendBar" style="right: 15px !important">
            <b-row>
              <b-col>
                <p class="info">圖例</p>
                <div>
                  <b-row>
                    <b-col cols="2" class="text-center">
                      <span
                        class="color"
                        style="
                          width: 24px;
                          height: 24px;
                          background-color: #0bd454;
                          color: #0bd454;
                        "
                      >
                        <b-icon icon="circle-fill" aria-hidden="true"></b-icon>
                      </span>
                    </b-col>
                    <b-col cols="10">
                      <p>保安林</p>
                    </b-col>
                  </b-row>
                  <b-row
                    v-show="
                      typeSelected == '總表' || typeSelected == '淹水潛勢'
                    "
                  >
                    <b-col cols="2" class="text-center">
                      <span
                        class="color"
                        style="
                          width: 24px;
                          height: 24px;
                          background-color: #00aeff;
                          color: #00aeff;
                        "
                      >
                        <b-icon icon="circle-fill" aria-hidden="true"></b-icon>
                      </span>
                    </b-col>
                    <b-col cols="10">
                      <p>淹水潛勢</p>
                    </b-col>
                  </b-row>
                  <b-row
                    v-show="
                      typeSelected == '總表' || typeSelected == '海岸侵蝕'
                    "
                  >
                    <b-col cols="2" class="text-center">
                      <span
                        class="color"
                        style="
                          width: 24px;
                          height: 24px;
                          background-color: #ffb300;
                          color: #ffb300;
                        "
                      >
                        <b-icon icon="circle-fill" aria-hidden="true"></b-icon>
                      </span>
                    </b-col>
                    <b-col cols="10">
                      <p>海岸侵蝕</p>
                    </b-col>
                  </b-row>
                  <b-row
                    v-show="
                      typeSelected == '總表' ||
                      typeSelected == '林地災害(歷年火災)'
                    "
                  >
                    <b-col cols="2" class="text-center">
                      <span
                        class="color"
                        style="
                          width: 24px;
                          height: 24px;
                          background-color: #ff2f00;
                          color: #ff2f00;
                        "
                      >
                        <b-icon icon="circle-fill" aria-hidden="true"></b-icon>
                      </span>
                    </b-col>
                    <b-col cols="10">
                      <p>林地災害</p>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>
          <Openlayers ref="map" :all="map"></Openlayers>
        </b-col>
        <b-col md="7">
          <b-row no-gutters align-h="between" align-v="center" class="py-4">
            <b-col cols="auto">
              <div class="px-3">
                <b-form inline>
                  <label class="mr-sm-2" for="inline-form-custom-select-pref"
                    >保安林編號</label
                  >
                  <b-form-select
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="NameSelected"
                    :options="NameOptions"
                  >
                    <template #first>
                      <b-form-select-option :value="''"
                        >-- 請選擇 --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <b-button variant="dark" @click="FunctionSearch"
                    >查詢</b-button
                  >
                </b-form>
              </div>
            </b-col>
          </b-row>
          <b-tabs card fill>
            <b-tab title="總表" active @click="ChangeTab('總表')">
              <div class="tableArea">
                <b-table
                  :fields="allFields"
                  :items="allItems"
                  :per-page="allPerPage"
                  :current-page="allCurrentPage"
                  bordered
                  hover
                  responsive="sm"
                  show-empty
                  empty-text="查無資料"
                  tbody-tr-class="text-nowrap"
                >
                  <template v-slot:cell(edit)="row">
                    <b-button-group>
                      <b-button variant="success" @click="FunctionPos(row.item)"
                        >定位</b-button
                      >
                    </b-button-group>
                  </template>
                </b-table>
              </div>
              <b-pagination
                v-model="allCurrentPage"
                :total-rows="allRows"
                :per-page="allPerPage"
                class="pt-3"
                aria-controls="my-table"
                align="center"
                first-number
                last-number
                limit="10"
              ></b-pagination>
            </b-tab>
            <b-tab title="淹水潛勢" @click="ChangeTab('淹水潛勢')">
              <div class="tableArea">
                <b-table
                  :fields="waterFields"
                  :items="waterItems"
                  :per-page="waterPerPage"
                  :current-page="waterCurrentPage"
                  bordered
                  hover
                  responsive="sm"
                  show-empty
                  empty-text="查無資料"
                  tbody-tr-class="text-nowrap"
                >
                  <template v-slot:cell(SA_PFLOCATION)="row">
                    <span :id="'popover-water-' + row.index"
                      >{{ row.item.SA_PFLOCATION.substring(0, 6)
                      }}{{
                        row.item.SA_PFLOCATION.length > 6 ? "..." : ""
                      }}</span
                    >
                    <b-popover
                      :target="'popover-water-' + row.index"
                      triggers="hover"
                      placement="top"
                    >
                      {{ row.item.SA_PFLOCATION }}
                    </b-popover>
                  </template>
                  <template v-slot:cell(edit)="row">
                    <b-button-group>
                      <b-button variant="success" @click="FunctionPos(row.item)"
                        >定位</b-button
                      >
                    </b-button-group>
                  </template>
                </b-table>
              </div>
              <b-pagination
                v-model="waterCurrentPage"
                :total-rows="waterRows"
                :per-page="waterPerPage"
                class="pt-3"
                aria-controls="my-table"
                align="center"
                first-number
                last-number
                limit="10"
              ></b-pagination>
            </b-tab>
            <b-tab title="海岸侵蝕" @click="ChangeTab('海岸侵蝕')">
              <div class="tableArea">
                <b-table
                  :fields="seaFields"
                  :items="seaItems"
                  :per-page="seaPerPage"
                  :current-page="seaCurrentPage"
                  bordered
                  hover
                  responsive="sm"
                  show-empty
                  empty-text="查無資料"
                  tbody-tr-class="text-nowrap"
                >
                  <template v-slot:cell(SA_PFLOCATION)="row">
                    <span :id="'popover-sea-' + row.index"
                      >{{ row.item.SA_PFLOCATION.substring(0, 6)
                      }}{{
                        row.item.SA_PFLOCATION.length > 6 ? "..." : ""
                      }}</span
                    >
                    <b-popover
                      :target="'popover-sea-' + row.index"
                      triggers="hover"
                      placement="top"
                    >
                      {{ row.item.SA_PFLOCATION }}
                    </b-popover>
                  </template>
                  <template v-slot:cell(edit)="row">
                    <b-button-group>
                      <b-button variant="success" @click="FunctionPos(row.item)"
                        >定位</b-button
                      >
                    </b-button-group>
                  </template>
                </b-table>
              </div>
              <b-pagination
                v-model="seaCurrentPage"
                :total-rows="seaRows"
                :per-page="seaPerPage"
                class="pt-3"
                aria-controls="my-table"
                align="center"
                first-number
                last-number
                limit="10"
              ></b-pagination>
            </b-tab>
            <b-tab
              title="林地災害(歷年火災)"
              @click="ChangeTab('林地災害(歷年火災)')"
            >
              <div class="tableArea">
                <b-table
                  :fields="fireFields"
                  :items="fireItems"
                  :per-page="firePerPage"
                  :current-page="fireCurrentPage"
                  bordered
                  hover
                  responsive="sm"
                  show-empty
                  empty-text="查無資料"
                  tbody-tr-class="text-nowrap"
                >
                  <template v-slot:cell(edit)="row">
                    <b-button-group>
                      <b-button variant="success" @click="FunctionPos(row.item)"
                        >定位</b-button
                      >
                    </b-button-group>
                  </template>
                  <template v-slot:cell(SA_PFLOCATION)="row">
                    <span :id="'popover-fire-' + row.index"
                      >{{ row.item.SA_PFLOCATION.substring(0, 6)
                      }}{{
                        row.item.SA_PFLOCATION.length > 6 ? "..." : ""
                      }}</span
                    >
                    <b-popover
                      :target="'popover-fire-' + row.index"
                      triggers="hover"
                      placement="top"
                    >
                      {{ row.item.SA_PFLOCATION }}
                    </b-popover>
                  </template>
                </b-table>
              </div>
              <b-pagination
                v-model="fireCurrentPage"
                :total-rows="fireRows"
                :per-page="firePerPage"
                class="pt-3"
                aria-controls="my-table"
                align="center"
                first-number
                last-number
                limit="10"
              ></b-pagination>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

      <AlertModal :alertModalObj="alertModalObj" />
    </div>
  </div>
</template>
<script>
import AlertModal from "../components/AlertModal.vue";
import Openlayers from "../components/Openlayers.vue";
import VectorSource from "ol/source/Vector";
import { Vector as VectorLayer } from "ol/layer";
import GeoJSON from "ol/format/GeoJSON";
import { Style, Fill, Stroke } from "ol/style";
import CircleStyle from "ol/style/Circle";
export default {
  components: {
    AlertModal,
    Openlayers,
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "空間分析",
          active: true,
        },
      ],
      alertModalObj: {
        title: "提示",
        content: null,
        show: false,
        refresh: false,
      },
      map: { layers: [] },
      NameOptions: [],
      NameSelected: "",
      typeSelected: "總表",
      // 總表
      allFields: [
        {
          key: "edit",
          label: "定位",
        },
        {
          key: "SA_PFNAME",
          label: "保安林編號",
        },
        {
          key: "isWater",
          label: "淹水潛勢是否位於範圍",
        },
        {
          key: "waterArea",
          label: "受影響面積(公頃)",
        },
        {
          key: "isSea",
          label: "海岸侵蝕是否位於範圍",
        },
        {
          key: "seaArea",
          label: "受影響面積(公頃)",
        },
        {
          key: "isFire",
          label: "林地災害(歷年火災)是否位於範圍",
        },
        {
          key: "fireArea",
          label: "受影響面積(公頃)",
        },
      ],
      allItems: [],
      allPerPage: 5,
      allCurrentPage: 1,
      allRows: 0,
      // 淹水潛勢
      waterFields: [
        {
          key: "edit",
          label: "定位",
        },
        {
          key: "SA_PFNAME",
          label: "保安林編號",
        },
        {
          key: "SA_PFTYPE",
          label: "保安林種類",
        },
        {
          key: "SA_PFLOCATION",
          label: "所在地",
        },
        {
          key: "SA_PFAREA",
          label: "總面積",
        },
        {
          key: "SA_LEVEL",
          label: "累計雨量24H_650mm淹水潛勢等級",
        },
        {
          key: "SA_AREA",
          label: "受影響面積(公頃)",
        },
      ],
      waterItems: [],
      waterPerPage: 5,
      waterCurrentPage: 1,
      waterRows: 0,
      // 海岸侵蝕
      seaFields: [
        {
          key: "edit",
          label: "定位",
        },
        {
          key: "SA_PFNAME",
          label: "保安林編號",
        },
        {
          key: "SA_PFTYPE",
          label: "保安林種類",
        },
        {
          key: "SA_PFLOCATION",
          label: "所在地",
        },
        {
          key: "SA_PFAREA",
          label: "總面積",
        },
        {
          key: "SA_LEVEL",
          label: "海岸侵蝕等級",
        },
        {
          key: "SA_AREA",
          label: "受影響面積(公頃)",
        },
      ],
      seaItems: [],
      seaPerPage: 5,
      seaCurrentPage: 1,
      seaRows: 0,
      // 林地災害(歷年火災)
      fireFields: [
        {
          key: "edit",
          label: "定位",
        },
        {
          key: "SA_PFNAME",
          label: "保安林編號",
        },
        {
          key: "SA_PFTYPE",
          label: "保安林種類",
        },
        {
          key: "SA_PFLOCATION",
          label: "所在地",
        },
        {
          key: "SA_PFAREA",
          label: "總面積",
        },
        {
          key: "SA_TYPE",
          label: "災害類別",
        },
        {
          key: "SA_NO",
          label: "災害編號",
        },
        {
          key: "SA_DATE",
          label: "發現日期",
        },
        {
          key: "SA_AREA",
          label: "災害面積(公頃)",
        },
        {
          key: "SA_X",
          label: "地點X座標(TWD97)",
        },
        {
          key: "SA_Y",
          label: "地點Y座標(TWD97)",
        },
      ],
      fireItems: [],
      firePerPage: 5,
      fireCurrentPage: 1,
      fireRows: 0,
      // 圖
      MapLayer: [],
      items: [],
      Fields: [
        {
          key: "SA_PFNAME",
          label: "保安林編號",
        },
        {
          key: "PF_TYPE",
          label: "保安林種類",
        },
        {
          key: "PF_LOCATION",
          label: "所在地",
        },
        {
          key: "SA_AREA",
          label: "受災面積",
        },
      ],
      perPage: 4,
      currentPage: 1,
      rows: 0,
      apiGetDashboardList: (data) =>
        this.userRequest.post("API/Default/GetDashboardList", data),
    };
  },
  created() {},
  mounted() {
    this.FunctionToken(this.GetDashboardList, {});
  },
  methods: {
    FunctionSearch() {
      this.FunctionToken(this.SearchDashboardList, {
        keyword: this.NameSelected,
      });
    },
    FunctionInitMap(pathList) {
      var mapLayer = [];
      pathList.forEach((x) => {
        let color = "#0bd454";
        if (x.category == "林地災害潛勢評估_淹水潛勢圖") color = "#00aeff";
        else if (x.category == "林地災害潛勢評估_臺東縣海岸侵蝕風險")
          color = "#ffb300";
        else if (x.category == "林地災害潛勢評估") color = "#ff2f00";

        let newStyle = new Style({
          fill: new Fill({
            color: color,
          }),
          stroke: new Stroke({
            color: "black",
          }),
        });
        if (x.category == "林地災害潛勢評估") {
          newStyle = new Style({
            image: new CircleStyle({
              radius: 10,
              fill: new Fill({ color: color }),
              stroke: new Stroke({ color: "black", width: 1 }),
            }),
          });
        }
        const newLayer = new VectorLayer({
          source: new VectorSource({
            url: x.path,
            format: new GeoJSON(),
            visible: x.category == "保安林檢訂成果",
            overlaps: true,
          }),
          style: newStyle,
          opacity: 0.6,
        });
        this.$refs.map.map.addLayer(newLayer);
        mapLayer.push({
          path: x.path,
          name: x.name,
          category: x.category,
          layer: newLayer,
        });
      });
      this.MapLayer = mapLayer;
      console.log(this.MapLayer);
      // for (let index = 0; index < pathList.length; index++) {
      //   const path = pathList[index];
      //   const newLayer = new VectorLayer({
      //     source: new VectorSource({
      //       url: path,
      //       format: new GeoJSON(),
      //       visible: true,
      //       overlaps: true,
      //     }),
      //     style: newStyle,
      //     opacity: 0.6,
      //   });
      //   this.$refs.map.map.addLayer(newLayer);
      // }
    },
    ChangeTab(value) {
      this.typeSelected = value;
      var find = this.MapLayer.filter((x) => x.category != "保安林檢訂成果");
      find.forEach((x) => {
        let isShow =
          value == "總表" ||
          (value == "淹水潛勢" &&
            x.category == "林地災害潛勢評估_淹水潛勢圖") ||
          (value == "海岸侵蝕" &&
            x.category == "林地災害潛勢評估_臺東縣海岸侵蝕風險") ||
          (value == "林地災害(歷年火災)" && x.category == "林地災害潛勢評估");
        x.layer.setVisible(isShow);
      });
    },
    FunctionPos(value) {
      console.log(value);
      console.log(this.MapLayer);
      var find = this.MapLayer.filter((x) => x.name == value.SA_PFNAME);
      console.log(find);
      if (find != undefined && find != null && find.length > 0) {
        const extent = find[0].layer.getSource();
        this.$refs.map.view.fit(extent.getExtent());
      }
    },
    GetDashboardList(data) {
      this.apiGetDashboardList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            console.log(jsonData);
            // this.items = jsonData.SA_LIST;
            // this.rows = jsonData.SA_LIST.length;
            this.NameOptions = jsonData.PF_NAME_LIST;
            this.FunctionInitMap(jsonData.PF_LAYER_LIST);
            this.allItems = jsonData.allItems;
            this.allRows = jsonData.allItems.length;

            this.waterItems = jsonData.waterItems;
            this.waterRows = jsonData.waterItems.length;

            this.seaItems = jsonData.seaItems;
            this.seaRows = jsonData.seaItems.length;

            this.fireItems = jsonData.fireItems;
            this.fireRows = jsonData.fireItems.length;
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SearchDashboardList(data) {
      this.apiGetDashboardList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.allItems = jsonData.allItems;
            this.allRows = jsonData.allItems.length;

            this.waterItems = jsonData.waterItems;
            this.waterRows = jsonData.waterItems.length;

            this.seaItems = jsonData.seaItems;
            this.seaRows = jsonData.seaItems.length;

            this.fireItems = jsonData.fireItems;
            this.fireRows = jsonData.fireItems.length;
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.legendBar {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  z-index: 999;

  .info {
    white-space: nowrap;
    border-bottom: 1px solid;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
  }
  .legend {
    padding-left: 20px;
  }
  span.color {
    display: block;
  }
}
</style>
