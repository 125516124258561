<template>
  <div class="page">
    <div class="page-content">
      <b-breadcrumb class="mb-0" :items="breadcrumb"></b-breadcrumb>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="森災系統" active @click="ChangeTab('森災系統')">
            <b-row no-gutters align-h="between" align-v="center" class="py-4">
              <b-col cols="auto">
                <div class="px-3">
                  <b-form inline>
                    <b-form-select
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="disasterTypeSelected"
                      :options="disasterTypeOptions"
                      v-if="disasterTypeOptions.length > 0"
                    >
                      <template #first>
                        <b-form-select-option :value="''"
                          >-- 請選擇 --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <b-form-input
                      id="input-1"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="search"
                      placeholder="請輸入關鍵字"
                      required
                      @keyup.enter="FunctionSearch"
                    ></b-form-input>
                    <b-button variant="dark" @click="FunctionSearch"
                      >查詢</b-button
                    >
                  </b-form>
                </div>
              </b-col>
              <b-col cols="auto">
                <b-button
                  class="mr-3 mt-1"
                  variant="outline-dark"
                  v-if="pagePermission.WLDEvaluate_Editor"
                  @click="FunctionOpenImport"
                  ref="btnShow"
                >
                  <b-icon icon="plus"></b-icon> 匯入</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="auto"
                style="
                  font-size: 0.9rem;
                  font-weight: bold;
                  color: rgb(0, 0, 0);
                "
              >
                <p>
                  2023/8/1前管理機關名稱為林務局，2023/8/1後管理機關名稱為林業及自然保育署。
                </p>
              </b-col>
            </b-row>
            <div class="tableArea" v-if="FDTableItems.length > 0">
              <b-table
                :fields="FDTableFields"
                :items="FDTableItems"
                :per-page="FDPerPage"
                :current-page="FDCurrentPage"
                bordered
                hover
                responsive="sm"
                show-empty
                empty-text="查無資料"
                tbody-tr-class="text-nowrap"
              >
                <template #cell(FD_DATE_OF_DISCOVERY)="data">
                  {{ FunctionDateFormat(data.item.FD_DATE_OF_DISCOVERY) }}
                </template>
                <template #cell(FD_CREATE_TIME)="data">
                  {{ FunctionDateFormat(data.item.FD_CREATE_TIME) }}
                </template>
                <template #cell(FD_UPDATE_TIME)="data">
                  {{ FunctionDateFormat(data.item.FD_UPDATE_TIME) }}
                </template>
              </b-table>
            </div>
            <b-pagination
              v-model="FDCurrentPage"
              :total-rows="FDRows"
              :per-page="FDPerPage"
              class="pt-3"
              aria-controls="my-table"
              align="center"
              first-number
              last-number
              limit="10"
            ></b-pagination>
          </b-tab>
          <b-tab title="海洋廢棄物" @click="ChangeTab('海洋廢棄物')">
            <b-row no-gutters align-h="between" align-v="center" class="py-4">
              <b-col cols="auto">
                <div class="px-3">
                  <b-form inline>
                    <span>起始日期：</span>
                    <b-form-input
                      type="date"
                      v-model="MDQueryStartDate"
                    ></b-form-input>
                    <span class="ml-3">結束日期：</span>
                    <b-form-input
                      type="date"
                      v-model="MDQueryEndDate"
                    ></b-form-input>
                    <span class="ml-3">關鍵字：</span>
                    <b-form-input
                      id="input-1"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="search"
                      placeholder="請輸入關鍵字"
                      required
                      @keyup.enter="FunctionSearch"
                    ></b-form-input>
                    <b-button variant="dark" @click="FunctionSearch"
                      >查詢</b-button
                    >
                  </b-form>
                </div>
              </b-col>
              <b-col cols="auto">
                <b-button
                  class="mr-3 mt-1"
                  variant="outline-dark"
                  v-if="pagePermission.WLDEvaluate_Editor"
                  @click="FunctionOpenImport"
                  ref="btnShow"
                >
                  <b-icon icon="plus"></b-icon> 匯入</b-button
                >
              </b-col>
            </b-row>
            <div class="tableArea" v-if="MDTableItems.length > 0">
              <b-table
                :fields="MDTableFields"
                :items="MDTableItems"
                :per-page="MDPerPage"
                :current-page="MDCurrentPage"
                bordered
                hover
                responsive="sm"
                show-empty
                empty-text="查無資料"
                tbody-tr-class="text-nowrap"
              >
                <template #cell(MD_FILL_IN_THE_DATE)="data">
                  {{ FunctionDateFormat(data.item.MD_FILL_IN_THE_DATE) }}
                </template>
                <template #cell(MD_CREATE_TIME)="data">
                  {{ FunctionDateFormat(data.item.MD_CREATE_TIME) }}
                </template>
                <template #cell(MD_UPDATE_TIME)="data">
                  {{ FunctionDateFormat(data.item.MD_UPDATE_TIME) }}
                </template>
              </b-table>
            </div>
            <b-pagination
              v-model="MDCurrentPage"
              :total-rows="MDRows"
              :per-page="MDPerPage"
              class="pt-3"
              aria-controls="my-table"
              align="center"
              first-number
              last-number
              limit="10"
            ></b-pagination>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-modal
        id="OOC-modal"
        content-class="shadow"
        size="md"
        title="資料上傳"
        ok-only
        ok-title="上傳"
        ok-variant="primary"
        no-stacking
        scrollable
        centered
        @ok="FunctionUpload"
        @close="FunctionCloseModel"
      >
        <b-form-group label="災害類型" v-if="tabItem === '森災系統'">
          <b-form-select
            v-model="townSelected"
            :options="townOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="年度" v-if="tabItem === '海洋廢棄物'">
          <b-form-input
            v-model="yearSelected"
            placeholder="請輸入清理年分(西元年)"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="檔案">
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="請上傳檔案"
            accept=".xlsx,.xls"
          ></b-form-file>
        </b-form-group>
        <b-button block variant="success" @click="FunctionDemoFileDownload"
          >範例檔案下載</b-button
        >
      </b-modal>
      <!-- <AlertModal :alertModalObj="alertModalObj" @getData="init" />
      <DeleteModal :deleteModalObj="deleteModalObj" @getData="init" /> -->
    </div>
    <div
      class="text-center"
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
      "
      align-v="center"
      v-if="isLoad"
    >
      <b-row style="position: relative; top: 45vh">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner
          ><span
            style="
              font-size: 20px;
              color: #ffffff;
              font-weight: bold;
              padding-left: 20px;
            "
            >載入中，請稍後...</span
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
// import AlertModal from "../../components/AlertModal.vue";
// import DeleteModal from "../../components/DeleteModal.vue";
export default {
  components: {
    // AlertModal,
    // DeleteModal,
  },
  data() {
    return {
      search: null,
      page: "TestResult",
      breadcrumb: [
        {
          text: "資料管理",
          active: true,
        },
        {
          text: "林地災害潛勢評估",
        },
      ],
      isLoad: false,
      tabItem: "森災系統",
      FDTableFields: [
        {
          key: "FD_DATA_TYPE",
          label: "資料類型",
        },
        {
          key: "FD_DISASTER_NUMBER",
          label: "災害編號",
        },
        {
          key: "FD_DATE_OF_DISCOVERY",
          label: "發現日期",
        },
        {
          key: "FD_DISASTER_AREA",
          label: "災害面積",
        },
        {
          key: "FD_DISASTER_CATEGORY",
          label: "災害種類",
        },
        {
          key: "FD_MANAGEMENT_AGENCY",
          label: "管理機關",
        },
        {
          key: "FD_LOCATION_LOT_NUMBER",
          label: "地點(地段號/其他)",
        },
        {
          key: "FD_LOCATION_BUSINESS_AREA",
          label: "地點(事業區/林班)",
        },
        {
          key: "FD_LOCATION_TWD97_X",
          label: "地點X座標(TWD97)",
        },
        {
          key: "FD_LOCATION_TWD97_Y",
          label: "地點Y座標(TWD97)",
        },
        {
          key: "FD_PAULING_BONUS",
          label: "保林獎金",
        },
        {
          key: "FD_CASE_SOLVING_BONUS",
          label: "破案獎金",
        },
        {
          key: "FD_TREE_TYPE",
          label: "樹種",
        },
        {
          key: "FD_VICTIM_VALUE",
          label: "被害價值",
        },
        {
          key: "FD_LOST_VALUE",
          label: "損失價值",
        },
        {
          key: "FD_UNIT",
          label: "單位",
        },
        {
          key: "FD_NUMBER_OF_VICTIMS",
          label: "被害數量",
        },
        {
          key: "FD_NUMBER_OF_LOSSES",
          label: "損失數量",
        },
        {
          key: "FD_AMOUUNT_OF_BYPRODUCTS_KILLED",
          label: "被害副產物數量",
        },
        {
          key: "FD_AMOUUNT_OF_LOST_BYPRODUCTS",
          label: "損失副產物數量",
        },
        {
          key: "FD_SEIZE_THE_VOLUME_OF_STOLEN_WOOD",
          label: "查扣贓木材積",
        },
        {
          key: "FD_NECESSARY_STORE_STOLEN_WOOD",
          label: "贓木有無存證必要",
        },
        {
          key: "FD_IS_PROTECTIONFOREST",
          label: "是否為保安林",
        },
        {
          key: "FD_CRIMINAL_IS_ABORIGINAL",
          label: "人犯是否為原住民",
        },
        {
          key: "FD_CASE_SEIZED_METHOD",
          label: "案件查獲方式",
        },
        // {
        //   key: "FD_PERPETRATOR_NAME",
        //   label: "行為人姓名",
        // },
        {
          key: "FD_NUMBER_OF_PRISONERS_SEIZED",
          label: "查獲人犯數",
        },
        {
          key: "FD_SUBMISSION_STATUS",
          label: "送審狀態",
        },
        {
          key: "FD_CREATE_TIME",
          label: "資料建置時間",
        },
        {
          key: "FD_UPDATE_TIME",
          label: "資料最後更新時間",
        },
      ],
      FDTableItems: [],
      FDPerPage: 10,
      FDCurrentPage: 1,
      FDRows: 0,
      MDTableFields: [
        {
          key: "MD_CLEAN_LOCATION",
          label: "清理地點",
        },
        {
          key: "MD_CLEAN_DATE",
          label: "清理日期",
        },
        // {
        //   key: "MD_CLEAN_UNIT",
        //   label: "清理單位",
        // },
        {
          key: "MD_TOTAL_WEIGHT_OF_GARBAGE",
          label: "清理垃圾總重(噸ton)",
        },
        {
          key: "MD_RECYCLE_WEIGHT",
          label: "資源回收重量(噸ton)",
        },
        {
          key: "MD_NON_RECYCLE_WEIGHT",
          label: "非資源回收重量(噸ton)",
        },
        {
          key: "MD_DRIFTWOOD_WEIGHT",
          label: "漂流木重量(噸ton)",
        },
        {
          key: "MD_RIVER_INTERCEPTION_WEIGHT",
          label: "河川攔除重量(噸ton)",
        },
        {
          key: "MD_MOBILIZE_MANPOWER",
          label: "動員人力",
        },
        {
          key: "MD_CLEAN_LENGTH",
          label: "清理長度(km)",
        },
        // {
        //   key: "MD_AFFILIATED_MINISTRY",
        //   label: "所屬部會",
        // },
        // {
        //   key: "MD_AUTHORITY",
        //   label: "權管單位",
        // },
        // {
        //   key: "MD_UNIT_NAME",
        //   label: "單位名稱",
        // },
        // {
        //   key: "MD_COUNTY",
        //   label: "縣市別",
        // },
        // {
        //   key: "MD_CATEGORY",
        //   label: "類別",
        // },

        {
          key: "MD_USE_IMPLEMENTS",
          label: "是否使用機具",
        },
        {
          key: "MD_EXCAVATOR",
          label: "挖掘機",
        },
        {
          key: "MD_BEACH_BUGGY",
          label: "淨灘車",
        },
        {
          key: "MD_SHOVEL",
          label: "鏟土機",
        },
        {
          key: "MD_OTHER_EQUIPMENT",
          label: "其他機具",
        },
        {
          key: "MD_CLEAN_REPLY_DESCRIPTION",
          label: "清理狀況回覆說明",
        },
        {
          key: "MD_NATURAL_CLEAN_NOTICE",
          label: "是否為天災清理通報",
        },
        {
          key: "MD_POS_LONGITUDE_1",
          label: "座標經度1",
        },
        {
          key: "MD_POS_LATITUDE_1",
          label: "座標緯度1",
        },
        {
          key: "MD_POS_LONGITUDE_2",
          label: "座標經度2",
        },
        {
          key: "MD_POS_LATITUDE_2",
          label: "座標緯度2",
        },
        {
          key: "MD_POS_LONGITUDE_3",
          label: "座標經度3",
        },
        {
          key: "MD_POS_LATITUDE_3",
          label: "座標緯度3",
        },
        {
          key: "MD_POS_LONGITUDE_4",
          label: "座標經度4",
        },
        {
          key: "MD_POS_LATITUDE_4",
          label: "座標緯度4",
        },
        {
          key: "MD_CREATE_TIME",
          label: "資料建置時間",
        },
        {
          key: "MD_UPDATE_TIME",
          label: "資料最後更新時間",
        },
        {
          key: "MD_FILL_IN_THE_DATE",
          label: "填報日期",
        },
        {
          key: "MD_CLEAR_NUMBER",
          label: "清理編號",
        },
        {
          key: "MD_CLEAR_YEAR",
          label: "清理年分",
        },
      ],
      MDQueryStartDate: null,
      MDQueryEndDate: null,
      MDTableItems: [],
      MDPerPage: 10,
      MDCurrentPage: 1,
      MDRows: 0,
      townSelected: "人為災害",
      townOptions: ["人為災害", "非人為災害"],
      yearSelected: "",
      file: null,
      formData: new FormData(),
      disasterTypeSelected: "",
      disasterTypeOptions: [],
      pagePermission: {
        AG_ID: "",
        Account: false,
        Map2D: false,
        MapManager: false,
        MapManager_Viewer: false,
        MapManager_Editor: false,
        Dashboard: false,
        RevisionResult: false,
        RevisionResult_Viewer: false,
        RevisionResult_Editor: false,
        PlanResult: false,
        PlanResult_Viewer: false,
        PlanResult_Editor: false,
        WLManage: false,
        WLManage_Viewer: false,
        WLManage_Editor: false,
        WFManage: false,
        WFManage_Viewer: false,
        WFManage_Editor: false,
        WLDEvaluate: false,
        WLDEvaluate_Viewer: false,
        WLDEvaluate_Editor: false,
        DataManager: false,
      },
      apiImportFDData: (data) =>
        this.userRequest.post("API/Default/ImportFDData", data),
      apiGetFDDataList: (data) =>
        this.userRequest.post("API/Default/GetFDDataList", data),
      apiImportMDData: (data) =>
        this.userRequest.post("API/Default/ImportMDData", data),
      apiGetMDDataList: (data) =>
        this.userRequest.post("API/Default/GetMDDataList", data),
      apiGetMyGroupPermission: (data) =>
        this.userRequest.post("API/Default/GetMyGroupPermission", data),
    };
  },
  created() {
    this.isLoad = true;
    this.FunctionToken(this.GetMyGroupPermission, {});
    this.FunctionToken(this.GetFDDataList, {});
  },
  methods: {
    ChangeTab(value) {
      this.tabItem = value;
      if (this.MDTableItems.length === 0) {
        this.isLoad = true;
        this.FunctionToken(this.GetMDDataList, {});
      }
    },
    FunctionSearch() {
      this.isLoad = true;
      if (this.tabItem === "森災系統") {
        this.FunctionToken(this.GetFDDataList, {
          keyword: this.search,
          disasterType: this.disasterTypeSelected,
        });
      } else if (this.tabItem === "海洋廢棄物") {
        this.FunctionToken(this.GetMDDataList, {
          keyword: this.search,
          StartDate: this.MDQueryStartDate,
          EndDate: this.MDQueryEndDate,
        });
      }
    },
    FunctionUpload() {
      this.isLoad = true;
      this.formData.append(this.file.name, this.file);
      if (this.tabItem === "森災系統") {
        this.formData.append("DataType", this.townSelected);
        this.FunctionToken(this.ImportFDData, {});
      } else if (this.tabItem === "海洋廢棄物") {
        if (
          this.yearSelected !== undefined &&
          this.yearSelected !== null &&
          this.yearSelected !== ""
        ) {
          this.formData.append("CleanYear", this.yearSelected);
          this.FunctionToken(this.ImportMDData, {});
        }
      }
    },
    FunctionCloseModel() {
      this.file = null;
      this.formData = new FormData();
      this.isLoad = false;
    },
    FunctionDateFormat(value) {
      if (value !== undefined && value !== null && value != "") {
        const date = new Date(value);
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1).toString() +
          "-" +
          date.getDate()
        );
      } else {
        return "";
      }
    },
    FunctionOpenImport() {
      this.FunctionCloseModel();
      this.$root.$emit("bv::show::modal", "OOC-modal", "#btnShow");
    },
    FunctionDemoFileDownload() {
      if (this.tabItem === "森災系統") {
        this.$axios({
          url: "Upload/森災系統.xlsx",
          method: "GET",
          responseType: "blob",
        }).then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          docUrl.setAttribute("download", "森災系統.xlsx");
          document.body.appendChild(docUrl);
          docUrl.click();
        });
      } else if (this.tabItem === "海洋廢棄物") {
        this.$axios({
          url: "Upload/主動清理填報成果統計(明細資料匯出).xls",
          method: "GET",
          responseType: "blob",
        }).then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          docUrl.setAttribute(
            "download",
            "主動清理填報成果統計(明細資料匯出).xls"
          );
          document.body.appendChild(docUrl);
          docUrl.click();
        });
      }
    },
    // ============================== api ==============================
    ImportFDData(data) {
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      this.apiImportFDData(this.formData)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.FDTableItems = jsonData;
            this.FDRows = this.FDTableItems.length;
            this.FunctionCloseModel();
          } else {
            alert(json.Message + "：" + json.Data);
            this.FunctionCloseModel();
          }
        })
        .catch((err) => {
          console.log(err);
          this.FunctionCloseModel();
        });
    },
    GetFDDataList(data) {
      this.apiGetFDDataList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.FDTableItems = jsonData;
            this.FDRows = this.FDTableItems.length;
            if (this.disasterTypeOptions.length == 0) {
              jsonData.forEach((x) => {
                if (
                  this.disasterTypeOptions.indexOf(x.FD_DISASTER_CATEGORY) ===
                  -1
                ) {
                  this.disasterTypeOptions.push(x.FD_DISASTER_CATEGORY);
                }
              });
            }
            this.isLoad = false;
          } else {
            alert(json.Message + "：" + json.Data);
            this.isLoad = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoad = false;
        });
    },
    ImportMDData(data) {
      this.formData.append("Token", data.Token);
      this.formData.append("TokenID", data.TokenID);
      this.apiImportMDData(this.formData)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.MDTableItems = jsonData;
            this.MDRows = this.MDTableItems.length;
            this.FunctionCloseModel();
          } else {
            alert(json.Message + "：" + json.Data);
            this.FunctionCloseModel();
          }
        })
        .catch((err) => {
          console.log(err);
          this.FunctionCloseModel();
        });
    },
    GetMDDataList(data) {
      this.apiGetMDDataList(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.MDTableItems = jsonData;
            this.MDRows = this.MDTableItems.length;
            this.isLoad = false;
          } else {
            alert(json.Message + "：" + json.Data);
            this.isLoad = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoad = false;
        });
    },
    GetMyGroupPermission(data) {
      this.apiGetMyGroupPermission(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            const jsonData = JSON.parse(json.Data);
            this.pagePermission = jsonData;
          } else {
            alert(json.Message + "：" + json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
